import { Typography } from "@material-tailwind/react";
import React from "react";
import DriverDetailsTable from "./DriverDetailsTable";
import {
  useDriversQuery,
  useScheduledRidesQuery,
} from "../../../../redux/api/admin/adminApi";

const DriverDetails = () => {
  const token = localStorage.getItem("token");
  const { data: driversData } = useDriversQuery(token);
  const { data: registeredRides } = useScheduledRidesQuery(token);

  const revenueData = [
    {
      name: "Total Drivers",
      revenue: driversData?.driver?.length,
    },
    {
      name: "Ride Registered",
      revenue: registeredRides?.bookedRides?.length,
    },
    {
      name: "Revenue",
      revenue: "$1.2M",
    },
  ];

  return (
    <div className="min-h-screen py-16 px-12">
      <Typography className="text-left text-5xl mb-12 font-bold font-playfair text-primaryLight-50">
        Driver Details
      </Typography>

      <div className="flex w-full lg:flex-row flex-col justify-between gap-6 mb-6">
        {revenueData.map((item, index) => {
          return (
            <div
              key={index}
              className="flex flex-col items-center rounded-[20px] border-[1px] py-4 justify-between border-primaryLight-300 bg-primaryLight-600 w-full bg-opacity-[34%]">
              <Typography className="text-left text-2xl mb-6 font-bold font-playfair text-primaryLight-200">
                {item?.name}
              </Typography>
              <div className="border-b-[1px] border-primaryLight-300 flex w-[90%] mb-4"></div>
              <Typography className="text-left text-6xl mb-6 font-normal font-playfair text-primaryLight-200">
                {item?.revenue}
              </Typography>
            </div>
          );
        })}
      </div>
      <div className="mb-8">
        <DriverDetailsTable payload={token} />
      </div>
    </div>
  );
};

export default DriverDetails;
