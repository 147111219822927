import { Button, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import banner from "../../../../assets/images/blogbanner.png";
import BlogCard1 from "../../../../common/components/BlogCard1";
import BlogCard2 from "../../../../common/components/BlogCard1/BlogCard2";
import { useGetBlogsQuery } from "../../../../redux/api/admin/adminApi";
import ReadBlog from "./ReadBlog";
import "./style.css";

const Blogs = () => {
  const { data: response, refetch } = useGetBlogsQuery();
  const [readBlogId, setReadBlogId] = useState(null);
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [readBlog, setReadBlog] = useState(false);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const dynamicBlog = response?.blogs?.map((blog) => ({
    id: blog?._id,
    blogImg: blog?.images[0],
    blogTitle: blog?.title,
    date: `Pranit Sharma ${new Date(blog?.createdAt).toLocaleDateString()}`,
    description: blog?.description,
  }));

  useEffect(() => {
    const handleResize = () => {
      setSlidesPerView(window.innerWidth < 1024 ? 1 : 3);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const combinedBlogs = dynamicBlog ? dynamicBlog : [];

  const handleBlogClick = (id) => {
    setReadBlogId(id);
    setReadBlog(true);
  };

  return (
    <div className="min-h-screen lg:mx-16 md:mx-12 mx-4 flex flex-col items-center">
      {/* Banner Section */}
      <div
        className="flex flex-col items-center justify-end h-[193px] rounded-3xl w-full bg-cover p-4 mb-14"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundRepeat: "no-repeat",
        }}>
        <Typography className="text-5xl font-[800] font-playfair text-primaryLight-50 shadow-xl mb-2">
          Blog
        </Typography>
        <Typography className="text-base font-medium font-montserrat text-primaryLight-50 shadow-xl">
          Read our latest posts to stay up to date with new trends
        </Typography>
      </div>
      {readBlog ? (
        <ReadBlog blogId={readBlogId} setReadBlog={setReadBlog} />
      ) : (
        <div className="flex flex-col w-full mb-14">
          <Typography className="text-3xl font-[800] font-playfair text-white shadow-xl mb-6">
            Freshly Published
          </Typography>
          <div className="w-full flex justify-between">
            <div className="hidden lg:flex w-[42%]">
              {combinedBlogs[0] && (
                <BlogCard1
                  item={combinedBlogs[0]}
                  setReadBlog={() => handleBlogClick(combinedBlogs[0].id)}
                />
              )}
            </div>
            <div className="lg:w-[56%] w-full flex flex-col gap-6 justify-between">
              <div className="lg:hidden flex">
                {combinedBlogs[0] && (
                  <BlogCard2
                    item={combinedBlogs[0]}
                    setReadBlog={() => handleBlogClick(combinedBlogs[0].id)}
                  />
                )}
              </div>
              {combinedBlogs.slice(1).map((blog, index) => (
                <BlogCard2
                  key={index}
                  item={blog}
                  setReadBlog={() => handleBlogClick(blog.id)}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="w-full flex flex-col justify-center gap-6 bg-[#37302e] items-center h-full lg:px-14 md:px-8 px-6 pb-14 pt-6 mb-8 rounded-xl">
        <div className="w-full h-full items-center gap-6 flex flex-col">
          {readBlog ? (
            <Typography className="text-center text-5xl items-center font-bold font-playfair text-primaryLight-50">
              Recommended Blogs
            </Typography>
          ) : (
            <Typography className="text-center text-5xl items-center font-bold font-playfair text-primaryLight-50">
              All Blogs
            </Typography>
          )}
          <Typography className="text-center text-sm font-montserrat max-w-[440px] text-primaryLight-50">
            Read our new blogs every Friday
          </Typography>
        </div>
        <div className="flex flex-col w-full h-full items-start justify-between">
          <div className="w-full gap-4">
            <Swiper
              slidesPerView={slidesPerView}
              slidesPerGroup={1}
              spaceBetween={30}
              pagination={{ el: ".swiper-custom-pagination" }}
              navigation={{
                prevEl: ".custom-prev",
                nextEl: ".custom-next",
              }}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper">
              {combinedBlogs.map((slide, index) => (
                <SwiperSlide key={index} className="relative group">
                  <div className="w-full h-full flex justify-center items-center">
                    <div className="flex items-end justify-center w-full h-full">
                      <div className="w-full max-w-[95vw] md:max-w-[450px] overflow-hidden rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
                        <BlogCard1
                          item={slide}
                          setReadBlog={() => handleBlogClick(slide.id)}
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-custom-pagination" />
            <div className="w-full flex justify-center items-center">
              <Button
                variant="filled"
                className="bg-[#FFC978] !w-[124px] text-black hover:bg-[#ffcc33] shadow-none normal-case rounded-[4px] mt-10">
                Read More
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
