import { Typography } from "@material-tailwind/react";
import { ArrowCircleLeft2, ArrowCircleRight2 } from "iconsax-react";
import React, { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./style.css";

const CurrentlyOperationalSwipper = () => {
  const [swiperRef, setSwiperRef] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(3); // Default is 3 for larger screens

  const slides = [
    { image: "https://i.imgur.com/jzY4N82.png", label: "New York" },
    { image: "https://i.imgur.com/TDBPb2k.png", label: "Hamptons" },
    { image: "https://i.imgur.com/dRxnJnX.png", label: "Boston" },
    { image: "https://i.imgur.com/2nfB9eX.png", label: "Chicago" },
    { image: "https://i.imgur.com/fc1MDje.png", label: "San Francisco" },
  ];

  // Set the number of slides based on the window width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setSlidesPerView(1); // 1 slide for screens smaller than 'lg'
      } else {
        setSlidesPerView(3); // 3 slides for larger screens
      }
    };

    handleResize(); // Set the initial value
    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getSlideHeight = (index) => {
    return index === activeIndex ? "250px" : "210px";
  };

  const getSlideWidth = (index) => {
    return index === activeIndex ? "400px" : "337px";
  };

  return (
    <div className="h-full bg-contain lg:mx-16 md:mx-8 mx-4 lg:p-8 p-4">
      <Typography className="text-left text-5xl font-bold mb-4 font-playfair text-primaryLight-50">
        Currently Operating in
      </Typography>
      <Typography className="text-left text-sm max-w-[440px] font-normal mb-2 font-montserrat text-primaryLight-50">
        Our services now extend their reach across two states, bringing our
        offerings closer to you wherever you may be. Join us as we broaden our
        horizons and enhance accessibility for all.
      </Typography>
      <div className="flex lg:flex-row flex-col h-full items-start justify-between">
        <div className="lg:w-[12%] w-full lg:flex justify-evenly">
          {/* Custom navigation */}
          <div className="flex w-full lg:min-h-[200px] flex-col items-center justify-center space-y-2 custom-navigation">
            {/* Custom pagination */}
            <div className="!hidden lg:!flex items-center space-x-2 custom-pagination">
              <span className="text-[64px] text-[#B59278] font-playfair">1</span>
              <span className="text-[64px] text-[#B59278] font-playfair">/</span>
              <span className="text-[64px] text-[#B59278] font-playfair">5</span>
            </div>

            <div className="flex flex-row w-full lg:justify-between justify-end gap-4">
              <div className="custom-prev cursor-pointer text-white">
                <ArrowCircleLeft2 color="#d1cbc7" size="54" />
              </div>

              <div className="custom-next cursor-pointer text-white">
                <ArrowCircleRight2 color="#d1cbc7" size="54" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-[85%]">
          <Swiper
            onSwiper={setSwiperRef}
            slidesPerView={slidesPerView} // Dynamic slides per view
            slidesPerGroup={1}
            spaceBetween={30}
            pagination={{
              type: "fraction",
              el: ".custom-pagination",
            }}
            navigation={{
              prevEl: ".custom-prev",
              nextEl: ".custom-next",
            }}
            onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index} className="relative group">
                <div className="flex items-end justify-center lg:max-w-[410px] max-w-full w-full h-[260px] relative">
                  <div
                    className="overflow-hidden rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300 relative"
                    style={{
                      height: getSlideHeight(index),
                      maxWidth: getSlideWidth(index),
                      width: "100%",
                    }}
                  >
                    <img
                      src={slide.image}
                      alt={slide.label}
                      className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                    />
                    {/* Adjusted Text Overlay */}
                    <div className="absolute bottom-[0px] top-2 left-2 w-[150px] h-[30px] bg-white flex items-center justify-center p-2 text-lg rounded-lg">
                      <Typography className="font-montserrat text-black text-center truncate font-medium">
                        {slide.label}
                      </Typography>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default CurrentlyOperationalSwipper;
