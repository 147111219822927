import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const urls = [
  "https://lcs-reboot-go97.onrender.com/api/payment",
  "http://localhost:6969/api/payment",
];
// const urls = "http://localhost:6969/api/auth"
export const paymentAPI = createApi({
  reducerPath: "paymentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: urls[0],
  }),
  endpoints: (builder) => ({
    paymentIntent: builder.mutation({
      query: ({ body, token }) => ({
        url: "/payment-intent",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    makePayment: builder.mutation({
      query: ({ body, token }) => ({
        url: "/",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const { useMakePaymentMutation, usePaymentIntentMutation } = paymentAPI;
