import { Button, Typography } from "@material-tailwind/react";
import { Profile2User, ShoppingBag } from "iconsax-react";
import React from "react";
import { useSelectVehicleMutation } from "../../../redux/api/userApi";

const FleetSelectionCard = ({ token, requestId, items, handleNext, setData }) => {
  console.log("hii", requestId, "token", token);

  const [selectVehicle] = useSelectVehicleMutation();
  const selectVehicleHandler = async (items) => {
    const vehicle = {
      rideType: items.type,
      carImg: items.carImg,
      luggage: items.lugage,
      seats: items.seats,
    };
    const price = items.fare;
    // console.log("price", price);
    

    try {
      const { data } = await selectVehicle({
        body: { requestId, vehicle, price },
        token,
      });
      if (data.success) {
        setData(items)
        handleNext();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex lg:flex-row flex-col w-full py-8 gap-6">
      <div className="lg:w-1/3 w-full flex items-center justify-center">
        <div
        className="bg-contain w-full h-full min-h-[20vh]"
        style={{
          backgroundImage: `url(${items.carImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition:"center"
        }}></div>
      </div>
      <div className="lg:w-2/3 w-full flex flex-col justify-between">
        <div className="flex w-full flex-row mb-2">
          <Typography className="text-left w-full text-xl font-semibold font-playfair text-primaryLight-200">
            {items.name}
          </Typography>
          <Typography className="text-right w-full text-xl font-semibold font-playfair text-primaryLight-200">
            ${items.fare}
          </Typography>
        </div>
        <div className="flex w-full flex-row">
          <div className="flex w-full flex-col gap-2">
            <div className="flex flex-row gap-2">
              <Typography className="text-center w-full max-w-[50px] rounded-md text-sm flex flex-row items-center justify-center font-semibold font-montserrat text-primaryLight-800 p-2 bg-[#d1cbc7]">
                <Profile2User size="16" className="mr-2" /> {items.seats}
              </Typography>
              <Typography className="text-center w-full max-w-[50px] rounded-md text-sm flex flex-row items-center justify-center font-semibold font-montserrat text-primaryLight-50 p-2 bg-[#d1cbc7] bg-opacity-30">
                <ShoppingBag size="16" className="mr-2" /> {items.luggage}
              </Typography>
            </div>
            <Typography className="text-left w-full text-xs font-semibold font-montserrat text-primaryLight-200">
              <span className="pl-[3px] font-normal">{items.wifi}</span>
            </Typography>
            <Typography className="text-left w-full text-xs font-semibold font-montserrat text-primaryLight-200">
              <span className="pl-[3px] font-normal">{items.compl}</span>
            </Typography>
            <Typography className="text-left w-full text-xs font-semibold font-montserrat text-primaryLight-200">
              <span className="pl-[3px] font-normal">{items.type}</span>
            </Typography>
          </div>
          <div className="flex flex-col w-full justify-end items-end">
            <Button
              variant="filled"
              className="bg-[#FFC978] !w-[124px] text-black hover:bg-[#ffcc33] shadow-none normal-case rounded-[4px]"
              onClick={() => selectVehicleHandler(items)}>
              Reserve Now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FleetSelectionCard;
