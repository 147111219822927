import mapboxSDK from "@mapbox/mapbox-sdk/services/geocoding";
import { Input } from "@material-tailwind/react";
import { useState } from "react";

const geocodingClient = mapboxSDK({
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
});

const LocationInput = ({ onSetLocation }) => {
  const [pickup, setPickup] = useState("");
  const [drop, setDrop] = useState("");
  const [pickupSuggestions, setPickupSuggestions] = useState([]);
  const [dropSuggestions, setDropSuggestions] = useState([]);

  const fetchSuggesstions = (query, setSuggestions) => {
    geocodingClient
      .forwardGeocode({
        query,
        autocomplete: true,
        limit: 5,
      })
      .send()
      .then((response) => {
        const match = response.body;
        setSuggestions(match.features);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  return (
    <div className="flex flex-col h-full justify-evenly">
      {/* Pickup location input and suggestions */}
      <div className="relative w-full">
        <Input
          label="Pickup location"
          placeholder="Pickup location"
          value={pickup}
          onChange={(e) => {
            setPickup(e.target.value);
            fetchSuggesstions(e.target.value, setPickupSuggestions);
          }}
          className="border-none focus:ring-0 focus:border-none outline-none pl-2 bg-transparent border-transparent !text-[#fedac5] focus:bg-transparent focus:text-[#fedac5] peer
            placeholder:opacity-50 placeholder:text-[#fedac5]"
          labelProps={{ className: "hidden" }}
        />
        {pickupSuggestions.length > 0 && (
          <ul className="absolute bg-[#302721] mt-1 w-full rounded-md shadow-lg z-10 max-h-40 overflow-auto">
            {pickupSuggestions.map((suggestion) => (
              <li
                key={suggestion.id}
                className="p-2 cursor-pointer hover:bg-[#4d3c26]"
                onClick={() => {
                  setPickup(suggestion.place_name);
                  onSetLocation("pickup", {
                    lat: suggestion.center[1],
                    lng: suggestion.center[0],
                    address: suggestion.place_name,
                  });
                  setPickupSuggestions([]);
                }}
              >
                {suggestion.place_name}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="w-full border-t-4 border-dotted mb-2 border-[#6b564a]"></div>

      {/* Drop location input and suggestions */}
      <div className="relative w-full">
        <Input
          label="Drop location"
          placeholder="Drop location"
          value={drop}
          onChange={(e) => {
            setDrop(e.target.value);
            fetchSuggesstions(e.target.value, setDropSuggestions);
          }}
          className="border-none focus:ring-0 focus:border-none outline-none pl-2 bg-transparent ring-transparent border-transparent !text-[#fedac5] focus:bg-transparent focus:text-[#fedac5] peer placeholder:opacity-50 placeholder:text-[#fedac5]"
          labelProps={{ className: "hidden" }}
        />
        {dropSuggestions.length > 0 && (
          <ul className="absolute bg-[#302721] mt-1 w-full rounded-md shadow-lg z-10 max-h-40 overflow-auto">
            {dropSuggestions.map((suggestion) => (
              <li
                key={suggestion.id}
                className="p-2 cursor-pointer hover:bg-[#4d3c26]"
                onClick={() => {
                  setDrop(suggestion.place_name);
                  onSetLocation("drop", {
                    lat: suggestion.center[1],
                    lng: suggestion.center[0],
                    address: suggestion.place_name,
                  });
                  setDropSuggestions([]);
                }}
              >
                {suggestion.place_name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default LocationInput;
