import { Typography } from "@material-tailwind/react";
import ScheduledCard from "../../../../common/components/ScheduledCard";
import BookingHeatMap from "./BookingHeatMap";
import PendingRequests from "./PendingRequestTable";
import SplineAreaChart from "./SplineChart";
import VisitorLineChart from "./VisitorLineChart";
import {
  useCancelBookingMutation,
  useScheduledRidesQuery,
} from "../../../../redux/api/admin/adminApi";
import { useState } from "react";

const LandingPage = () => {
  const token = localStorage.getItem("token");
  const [error, setError] = useState("");
  const { data: scheduledData, refetch } = useScheduledRidesQuery(token);
  const [cancelBooking] = useCancelBookingMutation();

  const revenueData = [
    { name: "Daily", revenue: "2000" },
    { name: "Monthly", revenue: "12000" },
    { name: "Yearly", revenue: "1.2M" },
  ];

  const scheduledRides = scheduledData?.bookedRides?.map((ride) => ({
    id: ride?._id,
    carType: ride?.request?.vehicle?.rideType,
    ride: ride?.request?.rideType,
    pickUp: ride?.request?.pickup?.address,
    dropOff: ride?.request?.dropof?.address,
    date: ride?.request?.fromDate?.split("T")[0],
    fare: ride?.request?.price,
    carImg: ride?.request?.vehicle?.carImg,
  }));

  const handleCancelRide = async (rideId) => {
    console.log("Canceling ride with ID:", rideId);
    const res = await cancelBooking({ token, bookingId: rideId });
    console.log(res);

    if (res.error) {
      return setError(res.error.data.message);
    }
    if (res.data.success) {
      return refetch();
    }
    // Here, you would typically call an API to cancel the ride.
  };

  return (
    <div className="min-h-screen py-16 px-12">
      <Typography className="text-left text-5xl mb-6 font-bold font-playfair text-primaryLight-50">
        Hey Pranit!
      </Typography>
      <Typography className="text-left text-sm mb-6 font-normal font-playfair text-primaryLight-200">
        Welcome to your LCS Admin Dashboard!!
      </Typography>
      <div className="flex w-full lg:flex-row flex-col justify-between gap-6 mb-6">
        {revenueData.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center rounded-[20px] border-[1px] py-4 justify-between border-primaryLight-300 bg-primaryLight-600 w-full bg-opacity-[34%]">
            <Typography className="text-left text-2xl mb-6 font-bold font-playfair text-primaryLight-200">
              {item.name} Revenue
            </Typography>
            <div className="border-b-[1px] border-primaryLight-300 flex w-[90%] mb-4"></div>
            <Typography className="text-left text-6xl mb-6 font-normal font-playfair text-primaryLight-200">
              ${item.revenue}
            </Typography>
          </div>
        ))}
      </div>
      <div className="flex w-full gap-6 mb-6">
        <div className="flex flex-col items-start rounded-[20px] border-[1px] p-6 border-primaryLight-300 bg-primaryLight-600 w-1/3">
          <Typography className="text-left text-2xl mb-6 font-bold font-playfair text-primaryLight-200">
            Upload Blogs
          </Typography>
          <div className="border-b-[1px] border-primaryLight-300 flex w-[90%] mb-4"></div>
        </div>
        <div className="flex flex-col items-center rounded-[20px] border-[1px] p-6 justify-between border-primaryLight-300 w-2/3">
          <div className="w-full flex items-start">
            <Typography className="text-left text-2xl mb-2 font-bold font-playfair text-primaryLight-200">
              Scheduled Rides
            </Typography>
          </div>
          {error ? (
            <p className="text-red-500 font-bold text-xl ml-2 m-2">{error}</p>
          ) : (
            ""
          )}
          <div className="w-full h-full overflow-y-auto max-h-[420px]">
            {scheduledRides?.map((items) => (
              <div
                key={items.id}
                className="border-t-[1px] border-primaryLight-300 flex w-[90%] mt-4">
                <ScheduledCard items={items} onCancel={handleCancelRide} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mb-8">
        <PendingRequests />
      </div>
      <div className="mb-16">
        <Typography className="text-primaryLight-50 text-5xl font-bold font-playfair mb-8">
          Analytics
        </Typography>
        <SplineAreaChart />
      </div>
      <div className="flex flex-row gap-6 mb-16 w-full">
        <div className="w-2/3">
          <VisitorLineChart />
        </div>
        <div className="w-1/3">
          <BookingHeatMap />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
