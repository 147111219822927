import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const urls = [
  "https://lcs-reboot-go97.onrender.com/api/admin",
  "http://localhost:6969/api/admin",
];
export const adminAPI = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: urls[0],
  }),
  endpoints: (builder) => ({
    scheduledRides: builder.query({
      query: (token) => ({
        url: "/scheduled-rides",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    pendingRequests: builder.query({
      query: ({ page, token }) => ({
        url: `/pending-request?page=${page}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    assignDriver: builder.mutation({
      query: ({ token, params }) => ({
        url: "/assign-driver",
        method: "POST",
        body: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    drivers: builder.query({
      query: (token) => ({
        url: "/drivers",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    // driver details page
    getAllDrivers: builder.query({
      query: ({ token, page }) => ({
        url: `/get-drivers?page=${page}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    addDriver: builder.mutation({
      query: ({ body, token }) => ({
        url: "/add-driver",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    // ride details
    getRideDetails: builder.query({
      query: (token) => ({
        url: `/ride-details-count`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getRides: builder.query({
      query: ({ page, token }) => ({
        url: `/ride-details?page=${page}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    cancelRide: builder.mutation({
      query: ({ token, rideId }) => ({
        url: "/cancel-request",
        method: "POST",
        body: rideId,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    cancelBooking: builder.mutation({
      query: ({ token, bookingId }) => ({
        url: "/cancel-booking",
        method: "POST",
        body: {bookingId},
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    // users details
    getUsers: builder.query({
      query: ({ token, page }) => ({
        url: `/users?page=${page}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getUser: builder.query({
      query: ({ token, userId }) => ({
        url: `/user?userId=${userId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    deleteUser: builder.mutation({
      query: ({ token, userId }) => ({
        url: `/delete-user?userId=${userId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    createBlog: builder.mutation({
      query: ({ token, body }) => ({
        url: "/create-blog",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getBlogs: builder.query({
      query: () => ({
        url: "/blogs",
        method: "GET",
      }),
    }),
    getBlogById: builder.query({
      query: (blogId) => ({
        url: `/blog?blogId=${blogId}`,
        method: "GET",
      }),
    }),
    saveAsDraft: builder.mutation({
      query: ({ token, body, blogId }) => {
        const queryString = blogId ? `?blogId=${blogId}` : "";

        return {
          url: `/save-as-draft${queryString}`,
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getAllDrafts: builder.query({
      query: (token) => ({
        url: "/drafts",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getDraft: builder.query({
      query: ({ token, blogId }) => ({
        url: `/draft?blogId=${blogId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const {
  useScheduledRidesQuery,
  usePendingRequestsQuery,
  useAssignDriverMutation,
  useCancelRequestMutation,
  useDriversQuery,
  useSearchDriverQuery,
  useGetAllDriversQuery,
  useAddDriverMutation,
  useGetRideDetailsQuery,
  useGetRidesQuery,
  useGetUsersQuery,
  useGetUserQuery,
  useDeleteUserMutation,
  useCreateBlogMutation,
  useGetBlogsQuery,
  useSaveAsDraftMutation,
  useGetAllDraftsQuery,
  useGetBlogByIdQuery,
  useGetDraftQuery,
  useCancelBookingMutation,
} = adminAPI;
