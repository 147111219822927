import { Card, CardBody, CardHeader, Typography } from "@material-tailwind/react";
import React from "react";

// Function to extract text from HTML
const extractText = (html) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  return tempDiv.innerText || tempDiv.textContent;
};

const BlogCard2 = ({ item, setReadBlog }) => {
  const textContent = extractText(item?.description);
  
  return (
    <Card className="w-full h-[151px] flex flex-row rounded-2xl overflow-hidden shadow-lg cursor-pointer" onClick={() => setReadBlog(true)}>
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="m-0 rounded-none w-[35%]"
      >
        <img
          src={item?.blogImg}
          alt="blog cover"
          className="object-cover w-full h-full"
        />
      </CardHeader>
      <CardBody className="p-2 w-[65%]">
        <Typography className="font-playfair text-2xl font-bold mb-4 text-[#302721]">
          {item?.blogTitle}
        </Typography>
        <Typography className="font-montserrat text-xs font-medium text-[#6B564A] text-opacity-60 mb-1">
          Published By {item?.date}
        </Typography>
        <Typography className="font-montserrat text-sm text-[#6B564A] text-opacity-60">
          {textContent.length > 60
            ? textContent.substring(0, 50) + "... "
            : textContent}
          {textContent.length > 60 && (
            <span className="text-blue-500 cursor-pointer" onClick={() => setReadBlog(true)}>
              Read more.
            </span>
          )}
        </Typography>
      </CardBody>
    </Card>
  );
};

export default BlogCard2;
