import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const urls = ["https://lcs-reboot-go97.onrender.com/api/auth", "http://localhost:6969/api/auth"];
// const urls = "http://localhost:6969/api/auth"
export const authAPI = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: urls[0],
  }),
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (user) => ({
        url: "/signup",
        method: "POST",
        body: user,
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: "/register",
        method: "POST",
        body: data,
      }),
    }),
    login: builder.mutation({
      query: (user) => ({
        url: "/login",
        method: "POST",
        body: user,
      }),
    }),
    resendOTP: builder.mutation({
      query: (email) => ({
        url: "/resend-otp",
        method: "POST",
        body: email,
      }),
    }),
    loginWithGoogle: builder.mutation({
      query: (user) => ({
        url: "/sign-in-google",
        method: "POST",
        body: user,
      }),
    }),
  }),
});

export const {
  useSignupMutation,
  useRegisterMutation,
  useLoginMutation,
  useResendOTPMutation,
  useLoginWithGoogleMutation,
} = authAPI;
