import { Button } from "@material-tailwind/react";

const Contact = () => {
  return (
    <div className="max-h-40 flex flex-col justify-center items-center">
      <h1
        className="text-5xl font-bold text-center text-white gap-5"
        style={{ fontFamily: "Playfair Display, serif" }}>
        Need a Luxury Ride ?
      </h1>
      <p className="text-center text-[#D1CBC7] mt-5">
        We are here to assit you.
      </p>
      <Button
        variant="filled"
        className="bg-[#FFC978] !w-[112px] !h-[33px] text-black hover:bg-[#ffcc33] shadow-none normal-case rounded-[4px] font-montserrat mt-5 p-2 ">
        Contact Us
      </Button>
    </div>
  );
};

export default Contact;
