import {
  Navigate,
  useLocation,
  useRoutes
} from "react-router-dom";
import LandingLayout from "../common/layout/LandingLayout";
import ForgotPassword from "../containers/Auth/ForgotPassword";
import NewPassword from "../containers/Auth/ForgotPassword/NewPassword";
import Login from "../containers/Auth/Login";
import Otp from "../containers/Auth/Otp";
import SignUp from "../containers/Auth/SignUp";
import Blogs from "../containers/Pages/Client/Blogs";
import DriverDetails from "../containers/Pages/Admin/DriverDetails";
import AddDriverForm from "../containers/Pages/Admin/DriverDetails/AddDriverForm";
import LandingPage from "../containers/Pages/Admin/LandingPage/LandingPage";
import RideDetails from "../containers/Pages/Admin/RideDetails";
import UserDetails from "../containers/Pages/Admin/UserDetails";
import BookingRide from "../containers/Pages/Client/Bookings";
import Careers from "../containers/Pages/Client/Career";
import Events from "../containers/Pages/Client/Events";
import Features from "../containers/Pages/Client/Features";
import ClientLandingPage from "../containers/Pages/Client/LandingPage/LandingPage";
import UserProfile from "../containers/Pages/Client/UserProfile";
import Legal from "../containers/Pages/Legal";
import Privacy from "../containers/Pages/Privacy";
import TermsAndConditions from "../containers/Pages/TermsAndConditions";
import { useMyProfileQuery } from "../redux/api/userApi";
import NotFoundPage from "../containers/NotFound/NotFound";
import CreateBlog from "../containers/Pages/Admin/Blogs";
import UploadBlog from "../containers/Pages/Admin/Blogs/UploadBlog";

const Router = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  console.log("token", token);
  const { data: userData } = useMyProfileQuery(token);

  const loggedin = token ? true : false;
  console.log("check", loggedin);

  const isClient = userData?.userDetails?.role === "admin" ? true : false;

  return useRoutes([
    {
      path: "/",
      element: <LandingLayout />,
      children: [
        { path: "login", element: loggedin ? <LandingLayout /> : <Login /> },
        {
          path: "register",
          element: loggedin ? <LandingLayout /> : <SignUp />,
        },
        { path: "otp", element: loggedin ? <LandingLayout /> : <Otp /> },
        {
          path: "forgot-password",
          element: loggedin ? <LandingLayout /> : <ForgotPassword />,
        },
        {
          path: "new-password",
          element: loggedin ? <LandingLayout /> : <NewPassword />,
        },
        {
          path: "",
          element:
            loggedin && isClient ? <LandingPage /> : <ClientLandingPage />,
        },
        { path: "ride-details", element: <RideDetails /> },
        { path: "driver-details", element: <DriverDetails /> },
        {
          path: "ride-booking",
          element: loggedin ? (
            <BookingRide />
          ) : (
            <Navigate to="/login" state={{ from: location }} />
          ),
        },
        { path: "upload-blog", element: <CreateBlog /> },
        { path: "draft-blog", element: <UploadBlog /> },
        { path: "blogs", element: <Blogs /> },
        {
          path: "user-profile",
          element: loggedin ? (
            <UserProfile />
          ) : (
            <Navigate to="/login" state={{ from: location }} />
          ),
        },
        { path: "legal", element: <Legal /> },
        { path: "events", element: <Events /> },
        { path: "privacy", element: <Privacy /> },
        { path: "career", element: <Careers /> },
        { path: "features", element: <Features /> },
        { path: "terms-and-conditions", element: <TermsAndConditions /> },
        {
          path: "user-details",
          children: [
            { path: "", element: <UserDetails /> },
            { path: "add-driver", element: <AddDriverForm /> },
          ],
        },
        {
          path: "*",
          element: <NotFoundPage />
        }
      ],
    },
  ]);
};

export default Router;
