import {
  Button,
  Dialog,
  DialogBody,
  Tab,
  Tabs,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import carLocationImg from "../../../../assets/images/carLocationImg.svg";
import { useGetRidesQuery } from "../../../../redux/api/admin/adminApi";

const RIdeDetailsTable = () => {
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("All");
  const [dialogData, setDialogData] = useState({});
  const { data, refetch } = useGetRidesQuery({ page, token });
  console.log("Data", data);
  useEffect(() => {
    refetch();
  }, [refetch]);
  const tabData = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Completed",
      value: "completed",
    },
    {
      label: "Scheduled",
      value: "booked",
    },
    {
      label: "Cancelled",
      value: "canceled",
    },
  ];

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDialog = (items) => {
    if (items) {
      setDialogData(items);
      console.log("Items", items);
      handleOpen();
    }
  };

  // Updated TABLE_HEAD and TABLE_ROWS based on screenshot
  const TABLE_HEAD = ["Date", "Driver Assigned", "Ride", "Amount", "Status"];

  const rideDetails = data?.rideDetails?.map((ride) => ({
    id: ride?._id,
    date: ride?.createdAt.split("T")[0],
    driver: ride?.driver?.name,
    ride: ride?.request?.rideType,
    amount: ride?.request?.price,
    status: ride?.status,
    fleet: ride?.driver?.fleet,
    pickup: ride?.request?.pickup?.address,
    dropOf: ride?.request?.dropof?.address,
    driverId: ride?.driver?.uid,
    riderName: ride?.user?.name,
    carImg: ride?.request?.vehicle?.carImg,
    message: ride?.request?.message,
  }));

  // filter drivers
  const filteredRides =
    activeTab === "All"
      ? rideDetails
      : rideDetails?.filter((status) => status.status === activeTab);

  return (
    <div className="bg-transparent border-[2px] border-primaryLight-300 p-6 rounded-[20px] text-primaryLight-50">
      <div className="flex justify-between items-center mb-8">
        <Typography className="text-primaryLight-50 text-5xl font-bold font-playfair">
          Ride Details
        </Typography>
      </div>
      <div className="w-[83%] mb-6">
        <Tabs value={activeTab} className="bg-transparent">
          <TabsHeader
            className="bg-transparent"
            indicatorProps={{
              className:
                "bg-transparent border-b-[2px] rounded-none border-[#FFC978]",
            }}>
            {tabData.map(({ label, value }, index) => (
              <Tab
                key={value}
                value={value}
                className={`font-normal ${
                  activeTab === value
                    ? "!text-[#FFC978]"
                    : "!text-primaryLight-300"
                }`}
                onClick={() => setActiveTab(value)}>
                {label}
              </Tab>
            ))}
          </TabsHeader>
        </Tabs>
      </div>
      <div className="overflow-hidden">
        <div className="h-full w-full">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr className="rounded-lg">
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className={`bg-primaryLight-700 p-4 ${
                      head === "Date" && "rounded-tl-xl rounded-bl-xl"
                    } ${head === "Status" && "rounded-tr-xl rounded-br-xl"}`}>
                    <Typography className="font-semibold font-montserrat text-lg text-primaryLight-50">
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredRides?.length > 0 ? (
                filteredRides?.map((items, index) => (
                  <tr
                    key={index}
                    className="even:bg-primaryLight-800 odd:bg-[#111111] overflow-y-auto">
                    <td className="p-4 rounded-tl-xl rounded-bl-xl">
                      <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                        {items.date}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                        {items.driver}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                        {items.ride}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                        ${items.amount}
                      </Typography>
                    </td>
                    <td
                      className={`p-4 ${
                        items.status === "Cancelled"
                          ? "text-red-500"
                          : "text-primaryLight-50"
                      } rounded-tr-xl rounded-br-xl`}>
                      <Typography className="font-normal font-montserrat text-lg">
                        {items.status}
                      </Typography>
                    </td>
                    <td className="p-4 bg-[#111111] text-center">
                      <Typography
                        className="font-normal font-montserrat text-lg text-textLight-500 cursor-pointer"
                        onClick={() => handleDialog(items)}>
                        View Details
                      </Typography>
                    </td>
                  </tr>
                ))
              ) : (
                <p className="mt-2 text-xl ml-2">No Rides Found</p>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <Button
          onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))}
          className="text-primaryLight-50"
          disabled={page === 1}
          variant="outlined"
          color="blue-gray">
          Previous
        </Button>
        <Typography className="text-primaryLight-50">
          Page {page} of {data?.totalPages}
        </Typography>
        <Button
          onClick={() => {
            if (page < data?.totalPages) {
              setPage((prevPage) => prevPage + 1);
            }
          }}
          disabled={page >= data?.totalPages}
          variant="outlined"
          color="blue-gray"
          className="text-primary-50">
          Next
        </Button>
      </div>

      {/* Dialog Component */}
      <Dialog
        open={open}
        handler={handleOpen}
        size="lg"
        className="bg-primaryLight-800 rounded-xl p-4">
        <div className="w-full flex flex-row justify-between">
          <Typography className="text-primaryLight-200 text-2xl font-bold font-playfair w-full text-center">
            Ride Details
          </Typography>
          <Typography
            className="text-primaryLight-200 text-xs font-bold font-playfair cursor-pointer"
            onClick={handleOpen}>
            x
          </Typography>
        </div>
        <DialogBody>
          <div className="flex flex-row justify-between items-start gap-6 h-full">
            {/* Map / Image */}
            <div className="rounded-xl w-[46%] h-full">
              <img src={carLocationImg} alt="Map" className="w-full h-full" />
            </div>
            {/* Ride Details */}
            <div className="w-[50%] flex flex-col h-full justify-between">
              <div className="w-full h-full">
                <img
                  src={dialogData.carImg}
                  alt="Car"
                  className="w-full h-full rounded-xl"
                />
              </div>
              <div className="w-full h-full">
                <div className="flex flex-row justify-between mt-2 mb-4">
                  <Typography className="text-xl font-semibold font-playfair text-primaryLight-200">
                    {dialogData.ride}
                  </Typography>
                  <Typography className="text-xl font-semibold font-playfair text-primaryLight-200">
                    ${dialogData.amount}
                  </Typography>
                </div>
                <div className="gap-4 flex flex-row justify-between h-full w-full">
                  <div>
                    <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                      Rider Name:
                      <span className="font-normal">
                        {dialogData.riderName}
                      </span>
                    </Typography>
                    <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                      Ride:
                      <span className="font-normal">{dialogData.fleet}</span>
                    </Typography>
                    <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                      Pickup:
                      <span className="font-normal">{dialogData.pickup}</span>
                    </Typography>
                    <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                      Drop Off:
                      <span className="font-normal">{dialogData?.dropOf}</span>
                    </Typography>
                    <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                      Date:
                      <span className="font-normal">{dialogData.date}</span>
                    </Typography>
                  </div>

                  <div className="border-[1px] border-primaryLight-400 p-4 items-center rounded-lg">
                    <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 w-full">
                      Driver Details
                    </Typography>
                    <div className="w-[95%] border-[1px] border-primaryLight-400 my-1"></div>
                    <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1 w-full">
                      Driver:{" "}
                      <span className="font-normal">{dialogData.driver}</span>
                    </Typography>
                    <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1 w-full">
                      Driver ID:{" "}
                      <span className="font-normal">{dialogData.driverId}</span>
                    </Typography>
                  </div>
                </div>

                <div className="mt-4">
                  <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                    Note for Driver:{" "}
                    <span className="font-normal">
                      {dialogData.message
                        ? dialogData.message
                        : "No message for driver"}
                    </span>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default RIdeDetailsTable;
