/* eslint-disable react/prop-types */
import { useState } from "react";
import image1 from "../../assets/Home_Fleet/SUV_Suburban.png";
import image2 from "../../assets/Home_Fleet/Sedan_Mercedes e class.png";
import image3 from "../../assets/Home_Fleet/First Class_Mercedes s class.png";
import image4 from "../../assets/Home_Fleet/SUV_Lincoln Navigator.png";
import image5 from "../../assets/Home_Fleet/Sedan_Cadillac CT6.png";
import image6 from "../../assets/Home_Fleet/First Class_genesis g90.png";
import image7 from "../../assets/Home_Fleet/SUV_Cadillac Escalade.png";
import image8 from "../../assets/Home_Fleet/Sedan_Cadillac CT6.png";
import image9 from "../../assets/Home_Fleet/Sedan_BMW 5 series.png";
import image10 from "../../assets/Home_Fleet/First Class_Bmw 7 series.png";
import { Profile2User, ShoppingBag } from "iconsax-react";

const fleets = [
  {
    id: 1,
    name: "Suburban",
    type: "Business SUV",
    image: image1,
  },
  {
    id: 2,
    name: "Mercedes E Class",
    type: "Business Sedan",
    image: image2,
  },
  {
    id: 3,
    name: "Mercedes S Class",
    type: "First Class",
    image: image3,
  },
  {
    id: 4,
    name: "Lincoln Navigator",
    type: "Business SUV",
    image: image4,
  },
  {
    id: 5,
    name: "Cadillac XT6",
    type: "Business Sedan",
    image: image5,
  },
  {
    id: 6,
    name: "Genesis G90",
    type: "First Class",
    image: image6,
  },
  {
    id: 7,
    name: "Cadillac Escalade",
    type: "Business SUV",
    image: image7,
  },
  {
    id: 8,
    name: "Cadillac CT6",
    type: "Business Sedan",
    image: image8,
  },
  {
    id: 9,
    name: "BMW 5 Series",
    type: "Business Sedan",
    image: image9,
  },
  {
    id: 10,
    name: "Bmw 7 Series",
    type: "First Class",
    image: image10,
  },
];

const FleetCard = ({ fleet }) => {
  return (
    <div className="bg-[#5d5046] rounded-3xl p-6 min-h-[10px] flex flex-col justify-between">
      <h3 className="text-start text-white text-lg mb-2">{fleet.name}</h3>
      <img
        src={fleet.image}
        alt={fleet.type}
        className="rounded-md mb-4 h-40 w-full object-contain"
      />

      <div className="bottom-4 left-4 flex items-center">
        <div className="flex space-x-4 flex-grow">
          <div className="flex items-center justify-center w-16 h-8 bg-white bg-opacity-5 rounded-2xl text-white">
            <Profile2User size="16" className="mr-2" /> 2
          </div>
          <div className="flex items-center justify-center w-16 h-8 bg-white bg-opacity-5 rounded-2xl text-white">
            <ShoppingBag size="16" className="mr-2" /> 2
          </div>
        </div>

        <div className="text-sm bg-[#D1CBC7] text-black px-3 py-1 rounded-full inline-block">
          {fleet.type}
        </div>
      </div>
    </div>
  );
};

const FleetSection = () => {
  const [selectedTab, setSelectedTab] = useState("All");

  const handleTabClick = (type) => {
    setSelectedTab(type);
  };

  // Filter the fleets based on the selected tab
  const filteredFleets =
    selectedTab === "All"
      ? fleets
      : fleets.filter((fleet) => fleet.type === selectedTab);

  return (
    <section
      className="bg-[#3c312b] text-white py-14 px-10 max-w-[1255px] items-center justify-center"
      style={{ margin: "50px auto", borderRadius: "50px" }}>
      <div className="max-w-7xl mx-auto text-center">
        <h2
          className="text-4xl font-bold mb-2"
          style={{ fontFamily: "Playfair Display, serif" }}>
          Our Fleets
        </h2>
        <p
          className="text-lg mb-6"
          style={{ fontFamily: "Playfair Display, serif" }}>
          Checkout our latest additions to luxury fleets, putting your comfort
          and safety first.
        </p>

        <div className="flex justify-center items-center space-x-4 mb-8">
          <button
            className={`${
              selectedTab === "All"
                ? "bg-[#56453B]"
                : "bg-transparent text-gray-400"
            } text-white px-4 py-2 rounded-lg`}
            onClick={() => handleTabClick("All")}>
            All
          </button>
          <span className="border-l border-gray-400 h-6 mx-2"></span>{" "}
          <button
            className={`${
              selectedTab === "Business Sedan"
                ? "bg-[#56453B]"
                : "bg-transparent text-gray-400"
            } text-white px-4 py-2 rounded-lg`}
            onClick={() => handleTabClick("Business Sedan")}>
            Business Sedan
          </button>
          <span className="border-l border-gray-400 h-6 mx-2"></span>{" "}
          <button
            className={`${
              selectedTab === "Business SUV"
                ? "bg-[#56453B]"
                : "bg-transparent text-gray-400"
            } text-white px-4 py-2 rounded-lg`}
            onClick={() => handleTabClick("Business SUV")}>
            Business SUV
          </button>
          <span className="border-l border-gray-400 h-6 mx-2"></span>{" "}
          <button
            className={`${
              selectedTab === "First Class"
                ? "bg-[#56453B]"
                : "bg-transparent text-gray-400"
            } text-white px-4 py-2 rounded-lg`}
            onClick={() => handleTabClick("First Class")}>
            First Class
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredFleets.map((fleet) => (
            <FleetCard key={fleet.id} fleet={fleet} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FleetSection;
