import React from "react";
import Footer from "../../common/components/Footer";
import Brands from "./Brands";
import Contact from "./Contact";
import FleetSection from "./FleetSection";
import Header from "./Header";
import Hero from "./Hero";
import Socials from "./Socials";
const Catelog = () => {
  return (
    <>
      <Header />
      <Hero />
      <Brands />
      <FleetSection />
      <Contact />
      <Socials />
      <Footer />
    </>
  );
};

export default Catelog;
