import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAJgYy_KCmicDuLVQi0hi-5UwCNaS2mrxA",
  authDomain: "lcs-reboot.firebaseapp.com",
  projectId: "lcs-reboot",
  storageBucket: "lcs-reboot.appspot.com",
  messagingSenderId: "28834068551",
  appId: "1:28834068551:web:dfb487ac8c777ba603d962",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
