import React, { useState } from "react";
import {
  Typography,
  Input,
  Button,
  Select,
  Option,
} from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { useAddDriverMutation } from "../../../../redux/api/admin/adminApi";
import { useNavigate } from "react-router";

const AddDriverForm = () => {
  const token = localStorage.getItem("token");
  const { register, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false); // State to manage loading
  const [addDriver] = useAddDriverMutation();
  const navigate = useNavigate();

  const uploadImageToCloudinary = async (file) => {
    const image = new FormData();
    image.append("file", file);
    image.append("upload_preset", "Driver");
    image.append("cloud_name", "da54j1nbe");
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/da54j1nbe/image/upload",
        {
          method: "POST",
          body: image,
        }
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error.message || "Image upload failed");
      }
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading image to Cloudinary:", error);
      throw error;
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const imageUpload = data.image[0]
        ? await uploadImageToCloudinary(data.image[0])
        : null;
      const frontSideUpload = data.frontSide[0]
        ? await uploadImageToCloudinary(data.frontSide[0])
        : null;
      const backSideUpload = data.backside[0]
        ? await uploadImageToCloudinary(data.backside[0])
        : null;

      const formData = {
        ...data,
        image: imageUpload,
        frontSide: frontSideUpload,
        backSide: backSideUpload,
      };

      console.log(formData);
      const res = await addDriver({ token, body: formData });
      if (res.data.success) {
        navigate("/driver-details");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen py-16 px-12 w-full">
      <Typography className="text-left text-5xl mb-12 font-bold font-playfair text-primaryLight-50">
        Add Driver
      </Typography>
      <div className="w-full flex flex-col">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row w-full gap-8">
            {/* Left Column */}
            <div className="space-y-6 w-1/2 flex justify-start flex-col">
              <Input
                label="Name"
                labelProps={{ className: "!text-primaryLight-300" }} // Updated label color
                className="!bg-[#302721] text-primaryLight-300 border-none"
                color="brown"
                variant="outlined"
                {...register("name")}
              />
              <Input
                label="Email"
                labelProps={{ className: "!text-primaryLight-300" }} // Updated label color
                className="!bg-[#302721] text-primaryLight-300 border-none"
                color="brown"
                variant="outlined"
                {...register("email")}
              />
              <Input
                label="Phone Number"
                labelProps={{ className: "!text-primaryLight-300" }} // Updated label color
                className="!bg-[#302721] text-primaryLight-300 border-none"
                color="brown"
                variant="outlined"
                {...register("phone")}
              />
              <Input
                label="Date of Joining"
                labelProps={{ className: "!text-primaryLight-300" }} // Updated label color
                className="!bg-[#302721] text-primaryLight-300 border-none"
                color="brown"
                variant="outlined"
                type="date"
                {...register("date")}
              />

              <Select
                label="Type of Fleet"
                labelProps={{ className: "!text-primaryLight-300" }}
                className="!bg-[#302721] text-primaryLight-300 border-none"
                color="brown"
                onChange={(e) => setValue("fleet", e)}>
                <Option value="SUV">SUV</Option>
                <Option value="SEDAN">SEDAN</Option>
                <Option value="First Class">First Class</Option>
              </Select>
              <Input
                label="Driving License Number"
                labelProps={{ className: "!text-primaryLight-300" }}
                className="!bg-[#302721] text-primaryLight-300 border-none"
                color="brown"
                variant="outlined"
                {...register("drivingLicenseNo")}
              />
            </div>

            {/* Right Column */}
            <div className="space-y-6 w-[30%] flex justify-start flex-col">
              {/* Profile Picture Upload */}
              <div className="!bg-[#302721] text-primaryLight-300 p-6 text-center rounded-lg">
                <span className="text-primaryLight-300">
                  Upload Profile Picture
                </span>
                <input
                  type="file"
                  accept="image/*"
                  className="mt-4 block w-full text-primaryLight-200 file:bg-primaryLight-50 file:text-black file:font-semibold file:px-4 file:py-2 file:rounded-lg file:cursor-pointer file:border-none"
                  {...register("image")}
                />
              </div>

              {/* Driving License (Front) Upload */}
              <div className="!bg-[#302721] text-primaryLight-300 p-6 text-center rounded-lg">
                <span className="text-primaryLight-300">
                  Driving License (Front)
                </span>
                <input
                  type="file"
                  accept="image/*"
                  className="mt-4 block w-full text-primaryLight-200 file:bg-primaryLight-50 file:text-black file:font-semibold file:px-4 file:py-2 file:rounded-lg file:cursor-pointer file:border-none"
                  {...register("frontSide")}
                />
              </div>

              {/* Driving License (Back) Upload */}
              <div className="!bg-[#302721] text-primaryLight-300 p-6 text-center rounded-lg">
                <span className="text-primaryLight-300">
                  Driving License (Back)
                </span>
                <input
                  type="file"
                  accept="image/*"
                  className="mt-4 block w-full text-primaryLight-200 file:bg-primaryLight-50 file:text-black file:font-semibold file:px-4 file:py-2 file:rounded-lg file:cursor-pointer file:border-none"
                  {...register("backside")}
                />
              </div>

              <Typography className="text-gray-400 text-sm text-left">
                *Maximum image size 100kb
              </Typography>
            </div>
          </div>

          {/* Buttons */}
          <div className="mt-8 flex flex-row justify-start gap-8 w-full">
            <Button
              variant="filled"
              className="bg-[#FFC978] text-black hover:bg-[#ffcc33] shadow-none normal-case"
              type="submit"
              disabled={loading}>
              {loading ? "Uploading..." : "Add Driver Profile"}
            </Button>
            <Button variant="outlined" color="red" className="normal-case">
              Delete Profile
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddDriverForm;
