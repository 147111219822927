import React from "react";
import { TiTick } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
const ConfirmationModal = ({ setShowModal }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    setShowModal(false);
    navigate("/user-profile");
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
      <div className="bg-[#302721] p-8 rounded-lg text-center max-w-lg w-full mx-4 shadow-lg">
        <h2 className="text-3xl font-bold text-white mb-4">Congratulations!</h2>
        <div className="mb-6">
          <div className="w-24 h-24 bg-[#6B564A] rounded-full flex items-center justify-center mx-auto">
            {/* <svg
              className="w-10 h-10 text-[#12100e]"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg> */}
            <TiTick size={70} style={{ color: "#302721" }} />
          </div>
        </div>
        <p className="text-white text-lg mb-4">
          Your ride has been reserved. You will receive further details once
          your driver is confirmed.
        </p>
        <button
          onClick={handleClick}
          className="bg-[#FFC978] text-black font-semibold py-2 px-4 rounded-md">
          Book More!
        </button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
