import React, { useState } from "react";
import {
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  Card,
} from "@material-tailwind/react";
import ApexCharts from "react-apexcharts";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { FaCircle } from "react-icons/fa";
import { CursorArrowRaysIcon } from "@heroicons/react/24/solid";

const SplineAreaChart = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const tabData = [
    {
      label: "SUV Sedan",
      value: "SUV Sedan",
    },
    {
      label: "First Class",
      value: "First Class",
    },
    {
      label: "Premium SUV",
      value: "Premium SUV",
    },
  ];
  const splineChartData = [
    {
      carType: "Premium SUV",
      number: 15,
    },
    {
      carType: "Premium Sedan",
      number: 145,
    },
    {
      carType: "First Class",
      number: 60,
    },
  ];
  //   const menuItems = [
  //     {
  //       title: "@material-tailwind/html",
  //       description:
  //         "Learn how to use @material-tailwind/html, packed with rich components and widgets.",
  //     },
  //     {
  //       title: "@material-tailwind/react",
  //       description:
  //         "Learn how to use @material-tailwind/react, packed with rich components for React.",
  //     },
  //     {
  //       title: "Material Tailwind PRO",
  //       description:
  //         "A complete set of UI Elements for building faster websites in less time.",
  //     },
  //   ];

  const options = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      background: "#6B564A", // Dark brown background
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#FFC978"], // Line color as in the screenshot
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          fontWeight: "500",
          colors: "#251e1a", // Light brown for x-axis labels
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          fontWeight: "500",
          colors: "#251e1a", // Light brown for y-axis labels
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      type: "gradient",
      colors: ["#FFC978"],
    },
    tooltip: {
      theme: "dark", // Dark tooltip theme
      x: {
        format: "MMM dd", // Change date format to match the screenshot
      },
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        colors: ["#FFC978"], // Tooltip text color matching the line color
      },
      marker: {
        show: true,
        fillColors: ["#FFC978"], // Tooltip marker matching the line
      },
      fillSeriesColor: false, // To prevent the tooltip background from inheriting the series color
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const dateSelected = w.config.series[0].data[dataPointIndex].x;

        const date = new Date(dateSelected);

        const formattedDate = date.toLocaleDateString("en-US", {
          weekday: "long",
          month: "short",
          day: "numeric",
        });

        const data = series[seriesIndex][dataPointIndex];
        return (
          '<div style="background-color: #d1cbc7; padding: 8px; border-radius: 5px; color: #000000;">' +
          "<strong>Rides:</strong> " +
          data +
          "<br/>" +
          formattedDate +
          "</div>"
        );
      },
    },
    grid: {
      show: true,
      borderColor: "rgba(215, 204, 200, 0.3)", // Light brown grid lines
      xaxis: {
        lines: {
          show: true, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: false, // Hide horizontal grid lines
        },
      },
    },
  };

  const series = [
    {
      name: "Rides",
      data: [
        { x: new Date("2024-09-15").getTime(), y: 30000 },
        { x: new Date("2024-09-17").getTime(), y: 40000 },
        { x: new Date("2024-09-19").getTime(), y: 35000 },
        { x: new Date("2024-09-21").getTime(), y: 50000 },
        { x: new Date("2024-09-23").getTime(), y: 49000 },
        { x: new Date("2024-09-25").getTime(), y: 60000 },
        { x: new Date("2024-09-27").getTime(), y: 70000 },
      ],
    },
  ];

  return (
    <div className="w-full bg-primaryLight-800 rounded-[20px]">
      <div className="w-full flex flex-row justify-between rounded-tl-[20px] rounded-tr-[20px] p-4 bg-primaryLight-800 border-b-[1px] border-[#f0eeed]">
        <div className="w-1/2">
          <Typography className="text-primaryLight-50 text-xl font-bold font-playfair">
            Total Rides
          </Typography>
        </div>
        <div className="w-1/2">
          <div className="flex flex-row justify-center gap-3 ">
            <div className="w-full">
              <Tabs value="SUV Sedan" className="bg-transparent">
                <TabsHeader
                  className="bg-transparent border-[1px] border-[#f0eeed]"
                  indicatorProps={{
                    className: "bg-[#6B564A]",
                  }}
                >
                  {tabData.map(({ label, value }, index) => (
                    <Tab
                      key={value}
                      value={value}
                      className={`!text-[#f0eeed] font-normal ${
                        index !== tabData.length - 1
                          ? "border-r border-white"
                          : ""
                      }`}
                    >
                      {label}
                    </Tab>
                  ))}
                </TabsHeader>
              </Tabs>
            </div>
            <div>
              <Menu open={openMenu} handler={setOpenMenu}>
                <MenuHandler>
                  <Button
                    variant="outlined"
                    color="white"
                    size="sm"
                    className="flex items-center gap-3 min-w-[175px] text-base font-normal capitalize tracking-normal"
                  >
                    Selected Period{" "}
                    <ChevronDownIcon
                      strokeWidth={2.5}
                      className={`h-3.5 w-3.5 transition-transform ${
                        openMenu ? "rotate-180" : ""
                      }`}
                    />
                  </Button>
                </MenuHandler>
                {/* <MenuList className="hidden w-[36rem] grid-cols-7 gap-3 overflow-visible lg:grid">
                  <Card
                    color="gray"
                    shadow={false}
                    className="col-span-3 flex h-full w-full items-center justify-center rounded-2xl p-4"
                  >
                    <CursorArrowRaysIcon
                      strokeWidth={1}
                      className="h-10 w-10"
                    />
                    <Typography className="mt-5 text-center" variant="h5">
                      Material Tailwind PRO
                    </Typography>
                  </Card>
                  <ul className="col-span-4 flex w-full flex-col gap-1">
                    {menuItems.map(({ title, description }) => (
                      <a href="#" key={title}>
                        <MenuItem>
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-1"
                          >
                            {title}
                          </Typography>
                          <Typography
                            variant="small"
                            color="gray"
                            className="font-normal"
                          >
                            {description}
                          </Typography>
                        </MenuItem>
                      </a>
                    ))}
                  </ul>
                </MenuList> */}
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full">
        <div className="flex flex-col justify-between min-w-[200px] w-[10%] bg-primaryLight-800 mb-4 pl-4 py-6 rounded-bl-xl">
          <div>
            {splineChartData.map((data, index) => (
              <div key={index} className="mb-2">
                <Typography className="font-medium text-sm font-montserrat text-white flex items-center gap-1">
                <FaCircle className="cursor-pointer text-sm text-[#B59278]" />{" "}{data.carType}
                </Typography>
                <Typography className="font-normal text-sm font-montserrat text-primaryLight-200">
                  {data.number}
                </Typography>
              </div>
            ))}
          </div>
          <div>
            <Typography className="font-medium font-montserrat text-white">
              Total Rides
            </Typography>
            <Typography className="font-normal font-montserrat text-primaryLight-200">
              220
            </Typography>
          </div>
        </div>
        <div className="w-[87%] rounded-br-xl">
          <ApexCharts
            options={options}
            series={series}
            type="area"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default SplineAreaChart;
