import { Outlet, useLocation } from "react-router";
import StickyNavbar from "../components/Navbar";
import Footer from "../components/Footer";

const LandingLayout = () => {
  const location = useLocation();

  return (
    <>
      <StickyNavbar />
      <Outlet />
      {location?.pathname === "/login" ||
      location?.pathname === "/otp" ||
      location?.pathname === "/forgot-password" ||
      location?.pathname === "/new-password" ||
      location?.pathname === "/register" ? null : (
        <Footer />
      )}
    </>
  );
};

export default LandingLayout;
