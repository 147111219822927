import { Sms } from "iconsax-react";
import { RiPhoneFill } from "react-icons/ri";

const Socials = () => {
  return (
    <div
      className="bg-[#3c312b] max-w-full md:w-[1355px] flex flex-col md:flex-row justify-between items-center p-6 rounded-lg mt-24"
      style={{ margin: "6rem auto" }}
    >
      <div className="flex flex-col md:flex-row justify-between items-center gap-8 md:gap-[20rem]">
        {/* Call Section */}
        <div className="flex items-center space-x-4 mb-6 md:mb-0 -ml-12 md:ml-0">
          <RiPhoneFill size="44" className="text-[#9A7C6C]" />
          <div className="flex flex-col">
            <p className="text-[#A97F6E] font-medium text-base md:text-lg">Call</p>
            <p className="text-white text-sm md:text-base">1-800-555-1212</p>
          </div>
        </div>

        {/* Email Section */}
        <div className="flex items-center space-x-4 mb-6 md:mb-0">
          <Sms size="44" className="text-[#9A7C6C]" />
          <div className="flex flex-col">
            <p className="text-[#A97F6E] font-medium text-base md:text-lg">Email</p>
            <p className="text-white text-sm md:text-base">mintlease@gmail.com</p>
          </div>
        </div>

        {/* X Section */}
        <div className="flex items-center space-x-4">
          <Sms size="44" className="text-[#9A7C6C]" />
          <div className="flex flex-col">
            <p className="text-[#A97F6E] font-medium text-base md:text-lg">X</p>
            <p className="text-white text-sm md:text-base">mintlease@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Socials;
