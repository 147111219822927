import { Button, Typography } from "@material-tailwind/react";
import { Edit, Logout } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Loader from "../../../../common/components/Loader/Loader";
import {
  useAddPhoneMutation,
  useGetUserRequestQuery,
  useMyProfileQuery,
} from "../../../../redux/api/userApi";
import PendingUserRequestTable from "./PendingUserRequestTable";
import UserRidesScheduled from "./UserRidesScheduled";

const UserProfile = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { data, isLoading, isError, error, refetch } = useMyProfileQuery(token);
  const { data: rideData } = useGetUserRequestQuery(token);
  console.log("data", rideData);

  const [editingPhone, setEditingPhone] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [addPhone] = useAddPhoneMutation();

  useEffect(() => {
    if (data?.userDetails?.phone) {
      setPhoneNumber(data.userDetails.phone);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    console.log(error);
    return <p>Something went wrong</p>;
  }

  const rides = rideData?.scheduledData?.map((ride) => ({
    id: ride?._id,
    carType: ride?.request?.rideType,
    wifi: "Free Wifi Onboard",
    compli: "Complimentary Beverages",
    name: ride?.request?.vehicle?.rideType,
    fare: ride?.request?.price,
    seats: ride?.request?.vehicle?.seats,
    luggage: ride?.request?.vehicle?.luggage,
    carImg: ride?.request?.vehicle?.carImg,
    status: ride?.status,
  }));

  const handleClick = () => {
    setEditingPhone(true);
  };

  const handleSave = async () => {
    const res = await addPhone({ token, body: { phoneNumber } });
    if (res.data.success) {
      setEditingPhone(false);
    }
  };

  const handleCancelRide = async (rideId) => {
    try {
      // Logic to cancel the ride (e.g., API call)
      console.log("Ride canceled:", rideId);
      // Optionally, refetch or update the rides list after cancellation
    } catch (error) {
      console.error("Failed to cancel ride:", error);
    }
  };

  return (
    <div className="min-h-screen py-14 lg:px-12 md:px-8 px-4">
      <Typography className="text-left w-[90%] flex flex-row capitalize items-center gap-4 text-5xl mb-6 font-bold font-playfair text-primaryLight-200">
        Hey {data.userDetails.name}!
      </Typography>
      <Typography className="text-left text-sm mb-6 font-normal font-montserrat text-primaryLight-200">
        Welcome to your LCS Chauffer Services Profile
      </Typography>

      <div className="flex w-full lg:flex-row flex-col gap-6 mb-6">
        <div className="flex flex-col items-start rounded-[20px] border-[1px] p-6 border-primaryLight-300 bg-primaryLight-600 lg:w-1/3 w-full">
          <Typography className="text-center flex flex-row items-center justify-center gap-4 w-full text-2xl mb-8 font-bold font-playfair text-primaryLight-200">
            User Details{" "}
            <Edit size="24" onClick={handleClick} className="cursor-pointer" />
          </Typography>
          <div className="h-full w-full mb-8">
            <img
              src="https://avatar.iran.liara.run/public/42"
              alt="profile"
              className="w-auto h-[40vh] m-auto"
            />
          </div>
          <div className="w-full flex flex-row h-full">
            <div className="flex w-1/3 gap-4 flex-col h-full justify-evenly">
              <Typography className="text-start flex flex-row items-center justify-start gap-4 w-full text-xl font-bold font-playfair text-primaryLight-200">
                Name*
              </Typography>
              <Typography className="text-start flex flex-row items-center justify-start gap-4 w-full text-xl font-bold font-playfair text-primaryLight-200">
                Email ID*
              </Typography>
              <Typography className="text-start flex flex-row items-center justify-start gap-4 w-full text-xl font-bold font-playfair text-primaryLight-200">
                Phone No.*
              </Typography>
              <Typography className="text-start flex flex-row items-center justify-start gap-4 w-full text-xl font-bold font-playfair text-primaryLight-200">
                Customer ID
              </Typography>
            </div>

            <div className="flex w-2/3 gap-4 flex-col h-full justify-evenly">
              <Typography className="truncate capitalize text-start flex flex-row items-center justify-start gap-4 w-full text-lg font-normal font-montserrat text-primaryLight-200">
                {data.userDetails.name}
              </Typography>
              <Typography className="truncate text-start flex flex-row items-center justify-start gap-4 w-full text-lg font-normal font-montserrat text-primaryLight-200">
                {data.userDetails.email}
              </Typography>
              {editingPhone ? (
                <div className="flex items-center">
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="border border-gray-300 rounded p-1 mr-2 w-1/2"
                  />
                  <Button onClick={handleSave}>Save</Button>
                </div>
              ) : (
                <Typography className="truncate text-start flex flex-row items-center justify-start gap-4 w-full text-lg font-normal font-montserrat text-primaryLight-200 cursor-pointer">
                  {phoneNumber || "----"}
                </Typography>
              )}
              <Typography className="truncate text-start flex flex-row items-center justify-start gap-4 w-full text-lg font-normal font-montserrat text-primaryLight-200">
                {data.userDetails.uid}
              </Typography>
            </div>
          </div>
          <Button
            className="flex w-full mt-10 items-center justify-center bg-red-900 hover:bg-brown-600"
            onClick={() => {
              localStorage.clear();
              navigate("/login");
            }}>
            <Logout />
            <span className="ml-3">Logout</span>
          </Button>
        </div>

        <div className="flex flex-col items-center rounded-[20px] border-[1px] p-6 justify-between border-primaryLight-300 lg:w-2/3 w-full">
          <div className="w-full flex items-start">
            <Typography className="text-left text-2xl mb-2 font-bold font-playfair text-primaryLight-200">
              Scheduled Rides
            </Typography>
          </div>
          <div className="w-full h-full overflow-y-auto max-h-[420px]">
            {rides?.length === 0 ? (
              <div className="text-center w-full h-full flex justify-center items-center text-primaryLight-300 mt-auto text-3xl">
                No rides scheduled.
              </div>
            ) : (
              rides?.map((items, index) => (
                <div
                  key={index}
                  className="border-t-[1px] border-primaryLight-300 flex w-[90%] mt-4">
                  <UserRidesScheduled
                    items={items}
                    onCancel={handleCancelRide}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      <div className="my-8">
        <PendingUserRequestTable />
      </div>

      <div className="w-full h-full mt-4">
        <img
          src="https://i.imgur.com/h9aDMhp.png"
          alt="chauffer"
          className="w-full min-h-[505px] h-full"
        />
      </div>
    </div>
  );
};

export default UserProfile;
