import { Button, Typography } from "@material-tailwind/react";
import { Link } from "iconsax-react";
import React from "react";
import { useForm } from "react-hook-form";
import { MdKey } from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import { useResetPasswordMutation } from "../../../redux/api/userApi";

const NewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit } = useForm();
  const { email } = location.state || {};
  const [resetPassword] = useResetPasswordMutation();

  const handleFormSubmit = async (data) => {
    try {
      const res = await resetPassword({
        email: email,
        password: data.password,
        confirmPass: data.confirmPass,
      });

      if (res.data.success) {
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center w-full bg-cover"
      style={{
        backgroundImage: `url("https://i.imgur.com/4GCorK6.png")`,
        backgroundRepeat: "no-repeat",
        height: `calc(100vh - 5rem)`,
      }}>
      <div className="h-[30%] flex flex-col items-center justify-center w-full">
        <Typography className="text-left text-5xl mb-4 font-bold font-playfair text-primaryLight-50">
          Welcome to LCS!
        </Typography>
        <Typography className="text-left text-lg font-semibold font-montserrat text-primaryLight-50">
          Login to your account to book your favourite fleet
        </Typography>
      </div>

      <div className="flex justify-center h-[70%] w-full items-center bg-black bg-opacity-90 backdrop-blur-0 !backdrop:filter-none">
        <div className="w-full h-full max-w-[454px] px-6 pt-5 rounded-t-xl text-primaryLight-50">
          <Typography className="text-center text-3xl mb-4 font-bold font-playfair text-primaryLight-50">
            Enter New Password
          </Typography>
          <Typography className="text-center text-xs font-semibold mb-6 font-montserrat text-primaryLight-50">
            At least 8 characters long, lower case letters, numbers, Uses
            <br /> special characters (e.g., !, @, #, $)
          </Typography>

          {/* OTP Input Fields */}
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="relative">
              <div className="bg-[#302721] rounded-lg flex flex-row justify-center items-center px-4 gap-4 mb-6">
                <div className="border-r-[2px] border-[#6b564a] pr-2">
                  <MdKey className="text-[#6b564a] text-2xl" />
                </div>
                <input
                  type="password"
                  placeholder="Enter Password"
                  className="w-full p-4 bg-primaryLight-800 text-[#6b564a] rounded-md focus:ring-2 focus:ring-primary-500 outline-none"
                  {...register("password")}
                />
              </div>
            </div>
            <div className="relative">
              <div className="bg-[#302721] rounded-lg flex flex-row justify-center items-center px-4 gap-4 mb-6">
                <div className="border-r-[2px] border-[#6b564a] pr-2">
                  <MdKey className="text-[#6b564a] text-2xl" />
                </div>
                <input
                  type="password"
                  placeholder="Re-Enter Password"
                  className="w-full p-4 bg-primaryLight-800 text-[#6b564a] rounded-md focus:ring-2 focus:ring-primary-500 outline-none"
                  {...register("confirmPass")}
                />
              </div>
            </div>

            <div className="text-right pb-4">
              <Link
                to="/login"
                // href="/forgot-password"
                className="text-[#6B564A] text-xs pb-1 border-b-2 border-[#6B564A] cursor-pointer">
                Already Have An Account ?
              </Link>
            </div>

            <Button
              type="submit"
              className="w-full py-3 text-center bg-[#ffc978] bg-opacity-60 text-[#251e1a] font-semibold rounded-lg hover:bg-yellow-700 transition">
              Continue
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
