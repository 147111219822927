import { Typography } from "@material-tailwind/react";
import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router";

const OurServices = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full flex lg:flex-row flex-col justify-center gap-6 items-end h-full lg:px-16 md:px-8 px-4 pb-16 pt-16">
      <div className="w-full justify-between h-full gap-4 flex flex-col">
        <div className="w-full h-full">
          <Typography className="text-left text-5xl font-bold mb-4 font-playfair text-primaryLight-50">
            Our Services
          </Typography>
          <Typography className="text-left text-sm mb-2 font-montserrat text-primaryLight-50">
            At LCS Chauffer Services, we provide a comprehensive range of
            premium chauffeur services designed to meet your every need.
          </Typography>
          <Typography className=" text-yellow-400 flex items-center cursor-pointer gap-2" onClick={()=>navigate("/events")}>
            Learn More <FaExternalLinkAlt />
          </Typography>
        </div>
        <div className="relative w-full h-full">
          <div className="absolute bottom-4 left-4 w-full flex justify-between">
            <Typography className="text-sm font-montserrat font-semibold px-4 py-2 rounded-lg bg-[#e9e6e4] text-[#251e1a] ">
              Airport Transfer
            </Typography>
          </div>
          <img
            src="https://i.imgur.com/iGNPTPf.png"
            alt="img1"
            className="w-full h-full"
          />
        </div>
      </div>

      <div className="w-full justify-between h-full gap-6 flex flex-col">
        <div className="relative w-full h-full">
          <div className="absolute bottom-4 left-4 w-full flex justify-between">
            <Typography className="text-sm font-montserrat font-semibold px-4 py-2 rounded-lg bg-[#e9e6e4] text-[#251e1a] ">
              Point to Point Transfer
            </Typography>
          </div>
          <img src="https://i.imgur.com/occKLTb.png" alt="img2"  className="w-full lg:h-full h-[40vw] object-cover rounded-[32px]"  />
        </div>
      </div>
      <div className=" w-full justify-between h-full gap-6 flex flex-col">
        <div className="relative w-full h-full">
          <div className="absolute bottom-4 left-4 w-full flex justify-between">
            <Typography className="text-sm font-montserrat font-semibold px-4 py-2 rounded-lg bg-[#e9e6e4] text-[#251e1a] ">
            Groups Transfer
            </Typography>
          </div>
          <img src="https://i.imgur.com/bloEpmD.png" alt="img3"/>
        </div>

        <div className="relative w-full h-full">
          <div className="absolute bottom-4 left-4 w-full flex justify-between">
            <Typography className="text-sm font-montserrat font-semibold px-4 py-2 rounded-lg bg-[#e9e6e4] text-[#251e1a] ">
              Private Transfer
            </Typography>
          </div>
          <img src="https://i.imgur.com/90T2kOD.png" alt="img4" />
        </div>
      </div>
    </div>
  );
};

export default OurServices;