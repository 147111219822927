import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import carLocationImg from "../../../../assets/images/carLocationImg.svg";
import { useGetUserRequestQuery } from "../../../../redux/api/userApi";

const PendingUserRequestTable = () => {
  const token = localStorage.getItem("token");
  const [open, setOpen] = useState(false);
  const [assignRotate, setAssignRotate] = useState(false);
  const [dialogData, setDialogData] = useState({
    date: "04.06.24",
    ride: "Premium SUV",
    pickup: "New York",
    dropoff: "Kansas City",
    amount: "$700",
    action: "Assign Driver",
  });

  const { data, isLoading, isError } = useGetUserRequestQuery(token);

  const rows = data?.pendingRequests?.map((request) => ({
    date: request.fromDate?.split("T")[0],
    ride: request?.vehicle?.rideType,
    pickup: request?.pickup?.address,
    dropoff: request?.dropof?.address,
    amount: request?.price,
  }));

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDialog = (items) => {
    if (items) {
      setDialogData(items);
      handleOpen();
    }
  };

  const TABLE_HEAD = ["Date", "Ride", "Pickup", "Drop Off", "Amount"];

  return (
    <div className="bg-transparent border-[2px] border-primaryLight-300 p-6 rounded-[20px] text-primaryLight-50">
      <div className="flex justify-between items-center mb-8">
        <Typography className="text-primaryLight-50 text-5xl font-bold font-playfair">
          Pending Requests
        </Typography>
      </div>
      <div className="overflow-hidden">
        <div className="h-full w-full">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr className="rounded-lg">
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className={`bg-primaryLight-700 p-4 ${
                      head === "Date" && "rounded-tl-xl rounded-bl-xl"
                    } ${head === "Amount" && "rounded-tr-xl rounded-br-xl"}`}>
                    <Typography className="font-semibold font-montserrat text-lg text-primaryLight-50">
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows?.length === 0 ? (
                <td className="p-4 rounded-tl-xl rounded-bl-xl">
                  <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                    No Pending Request
                  </Typography>
                </td>
              ) : (
                rows?.map((items, index) => (
                  <tr
                    key={index}
                    className="even:bg-primaryLight-800 odd:bg-[#111111] overflow-y-auto">
                    <td className="p-4 rounded-tl-xl rounded-bl-xl">
                      <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                        {items.date}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                        {items.ride}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography className="truncate font-normal font-montserrat text-lg text-primaryLight-50 w-64">
                        {items.pickup}
                      </Typography>
                    </td>

                    <td className="p-4">
                      <Typography className="truncate font-normal font-montserrat text-lg text-primaryLight-50 w-64">
                        {items.dropoff}
                      </Typography>
                    </td>
                    <td className="p-4 rounded-tr-xl rounded-br-xl">
                      <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                        $ {items.amount}
                      </Typography>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Dialog Component */}
      <Dialog
        open={open}
        handler={handleOpen}
        size="md"
        className="bg-primaryLight-800 rounded-xl p-4">
        <div className="w-full flex flex-row justify-between">
          <Typography className="text-primaryLight-200 text-2xl font-bold font-playfair w-full text-center">
            Ride Details
          </Typography>
          <Typography
            className="text-primaryLight-200 text-xs font-bold font-playfair cursor-pointer"
            onClick={handleOpen}>
            x
          </Typography>
        </div>
        <DialogBody>
          <div className="flex flex-row justify-between items-start gap-6">
            {/* Map / Image */}
            <div className="rounded-xl w-[52%] h-full">
              <img src={carLocationImg} alt="Map" className="w-full h-full" />
            </div>
            {/* Ride Details */}
            <div className="w-[48%] min-h-[310px]">
              <div className="rounded-xl w-full max-h-[163px]">
                <img
                  src="https://i.imgur.com/tmJaIui.png"
                  alt="Car"
                  className="w-full h-full"
                />
              </div>
              <div className="flex flex-row justify-between mt-2">
                <Typography className="text-xl font-semibold font-playfair text-primaryLight-200">
                  Business Sedan
                </Typography>
                <Typography className="text-xl font-semibold font-playfair text-primaryLight-200">
                  {dialogData.amount}
                </Typography>
              </div>
              <div className="text-xs font-bold font-montserrat mt-2">
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Rider Name:{" "}
                  <span className="font-normal">{dialogData.ride}</span>
                </Typography>
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Pickup:{" "}
                  <span className="font-normal">{dialogData.pickup}</span>
                </Typography>
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Drop Off:{" "}
                  <span className="font-normal">{dialogData.dropoff}</span>
                </Typography>
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Date: <span className="font-normal">{dialogData.date}</span>
                </Typography>
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Note for Driver:{" "}
                  <span className="font-normal">
                    Please call when you reach the location.
                  </span>
                </Typography>
              </div>
            </div>
          </div>
        </DialogBody>
        <DialogFooter className="w-full flex flex-row justify-center gap-6">
          <Button
            variant="filled"
            // color="yellow"
            size="sm"
            className="flex items-center justify-around gap-3 min-w-[175px] text-base rounded-md font-normal normal-case bg-[#FFC978] text-primaryLight-900 border-primaryLight-900 tracking-normal pl-6"
            onClick={() => {
              setAssignRotate(!assignRotate);
            }}>
            Assign Driver{" "}
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`h-3.5 w-3.5 transition-transform ${
                assignRotate ? "rotate-180" : ""
              }`}
            />
          </Button>
          <Button
            variant="text"
            color="red"
            size="lg"
            onClick={handleOpen}
            className="border border-red-500 text-red-500 font-normal px-4 py-2 rounded-md normal-case">
            Cancel Ride
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default PendingUserRequestTable;
