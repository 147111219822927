import { Button, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { GoLinkExternal } from "react-icons/go";
import { useNavigate } from "react-router";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import BlogCard1 from "../../../../common/components/BlogCard1";
import { useGetAllDraftsQuery } from "../../../../redux/api/admin/adminApi";
import ReadBlog from "./ReadBlog";
import "./style.css";

const UploadBlog = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { data, refetch } = useGetAllDraftsQuery(token);
  console.log("Data", data);

  useEffect(() => {
    refetch();
  }, [refetch]);

  // const [swiperRef, setSwiperRef] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [readBlog, setReadBlog] = useState(false);
  const [slidesPerView, setSlidesPerView] = useState(3); // Default is 3 for larger screens

  const blogData = data?.blogs?.map((blog) => ({
    blogImg: blog?.images[0],
    blogTitle: blog?.title,
    date: `Pranit Sharma ${blog?.createdAt.split("T")[0]}`,
    description: blog?.description,
  }));

  // const blogData = [
  //   {
  //     blogImg:
  //       "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
  //     blogTitle: "Top 5 Day Trips From New York City for Labor Day",
  //     date: "Pranit Sharma 18.08.24",
  //     description:
  //       "Labor Day is just around the corner, which means a well-deserved holiday! And what better way to spend it than by escaping the hustle and bustle of NYC? Whether you are carving for a beachside retreat, a scenic countryside adventure, or a cultural city experience, there are plenty of spots just a short drive away from NYC. But why drive yourself and deal with traffic or parking? Let Lryde Chauffeur Services (LCS) handle the driving while you relax in comfort and style. Our professional chauffeurs ensure that your getaway is smooth, allowing you to make the most of your day trip.",
  //   },
  //   {
  //     blogImg:
  //       "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
  //     blogTitle: "Why USA is Rejecting Tesla!",
  //     Author: "David Pinto",
  //     date: "20.03.24",
  //     description:
  //       "The prices of luxury cars have skyrocketed over the past couple of years...",
  //   },
  //   {
  //     blogImg:
  //       "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
  //     blogTitle: "The Last V8 Aspirated Mustang",
  //     Author: "David Pinto",
  //     date: "20.03.24",
  //     description:
  //       "The prices of luxury cars have skyrocketed over the past...",
  //   },
  //   {
  //     blogImg:
  //       "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
  //     blogTitle: "Which are the most VFM Luxury Cars in today's market?",
  //     Author: "David Pinto",
  //     date: "20.03.24",
  //     description:
  //       "The prices of luxury cars have skyrocketed over the past couple of years...",
  //   },
  // ];

  // Set the number of slides based on the window width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setSlidesPerView(1); // 1 slide for screens smaller than 'lg'
      } else {
        setSlidesPerView(3); // 3 slides for larger screens
      }
    };

    handleResize(); // Set the initial value
    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="min-h-screen lg:mx-16 md:mx-12 mx-4 flex flex-col items-center">
      {/* Banner Section */}
      {readBlog ? (
        <ReadBlog setReadBlog={setReadBlog} />
      ) : (
        <div className="flex flex-col w-full my-14">
          <div className="w-full flex lg:flex-row md:flex-row sm:flex-col flex-col justify-between items-center mb-10">
            <Typography className="text-4xl font-[800] font-playfair text-white mb-6">
              Blog Drafts
            </Typography>
            <div className="flex justify-between gap-4">
              <Button
                variant="filled"
                className="flex justify-center gap-2 items-center bg-[#FFC978] text-[#000000] normal-case text-xl font-medium ring-0 focus:ring-0"
                onClick={() => {
                  navigate("/blogs");
                }}>
                View All Blogs <GoLinkExternal />
              </Button>
              <Button
                variant="outlined"
                className="flex justify-center gap-2 items-center border-[#FFC978] text-[#FFC978] normal-case text-xl font-medium ring-0 focus:ring-0"
                onClick={() => {
                  navigate("/draft-blog");
                }}>
                View Drafts <GoLinkExternal />
              </Button>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-between">
            {blogData?.length > 0 ? (
              blogData?.map((slide, index) => {
                return (
                  <div key={index} className="w-full">
                    <BlogCard1 item={slide} setReadBlog={setReadBlog} />
                  </div>
                );
              })
            ) : (
              <p className="text-white text-2xl">No Drafts</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadBlog;
