import { configureStore } from "@reduxjs/toolkit";
import { adminAPI } from "./api/admin/adminApi";
import { authAPI } from "./api/authApi";
import { fleetAPI } from "./api/fleetApi";
import { paymentAPI } from "./api/paymentApi";
import { userAPI } from "./api/userApi";
export const store = configureStore({
  reducer: {
    [authAPI.reducerPath]: authAPI.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [fleetAPI.reducerPath]: fleetAPI.reducer,
    [paymentAPI.reducerPath]: paymentAPI.reducer,
    [adminAPI.reducerPath]: adminAPI.reducer,
  },
  middleware: (mid) => [
    ...mid(),
    authAPI.middleware,
    userAPI.middleware,
    fleetAPI.middleware,
    paymentAPI.middleware,
    adminAPI.middleware,
  ],
});
