import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Input,
  Popover,
  PopoverContent,
  PopoverHandler
} from "@material-tailwind/react";
import { format } from "date-fns";
import { ArrowDown, Calendar } from "iconsax-react";
import React from "react";
import { DayPicker } from "react-day-picker";
import 'react-day-picker/dist/style.css';

export default function DateTimeComponent({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  time,
   setTime
}) {

  return (
    <div>
      <Popover placement="bottom">
        <PopoverHandler>
          {/* Input field styled according to the design */}
          <div className="bg-[#302721] rounded-lg flex flex-row justify-center items-center py-2 px-4 gap-4">
            <div className="border-r-[2px] border-[#6b564a] pr-2">
            <Calendar size="24" className="text-[#fedac5]" />
            </div>
          <Input
            label="Select Date Range"
            onChange={() => null}
            value={
              startDate && endDate
                ? `${format(startDate, "PPP")} - ${format(endDate, "PPP")}, ${time.hour}:${time.minute} ${time.period}`
                : "Select Date Range"
            }
            className="!text-[#fedac5] bg-[#302721] border-none placeholder-[#6b564a] rounded-lg"  // Applying text and background colors
            containerProps={{
              className: "bg-[#302721] border-[#6b564a] rounded-lg",  // Border color
            }}
            // labelProps={{
            //   className: "peer-focus:text-[#302712] !text-[#302712] peer-focus:border-transparent",
            // }}
            labelProps={{
              className: "hidden",
            }}
          />
           <ArrowDown size="24" className="text-[#6b564a]" />
          </div>
        </PopoverHandler>
        <PopoverContent className="bg-[#302721] p-4">
          {/* Calendar customization for date range selection */}
          <DayPicker
  mode="range"
  selected={{ from: startDate, to: endDate }}
  onSelect={(range) => {
    setStartDate(range?.from || null);
    setEndDate(range?.to || null);
  }}
  showOutsideDays
  className="border-0"
  classNames={{
    caption: "flex justify-center py-2 mb-4 relative items-center text-[#6b564a]",  // Text color for month/year caption
    nav: "flex items-center justify-end absolute right-2 top-2",  // Aligning navigation buttons to the right
    nav_button:
      "h-6 w-6 bg-transparent hover:bg-[#6b564a] p-1 rounded-md transition-colors duration-300",  // Hover effect for navigation buttons
    nav_button_previous: "ml-2",
    nav_button_next: "",
    table: "w-full border-collapse",
    head_row: "flex font-medium text-[#6b564a]",  // Text color for weekday header
    head_cell: "m-0.5 w-9 font-normal text-sm",
    row: "flex w-full mt-2",
    cell: "rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative text-[#6b564a] bg-[#302721] hover:bg-[#6b564a] hover:text-[#6b564a]",  // Cell background and hover styles
    day: "h-9 w-9 p-0 font-normal",
    day_selected:
      "rounded-md bg-[#d9d9d9]/14 text-[#6b564a] hover:bg-[#d9d9d9]/14 hover:text-[#6b564a] focus:bg-[#d9d9d9]/14 focus:text-[#6b564a]",  // Selected day styles
    day_range_start: "rounded-md bg-[#d1cbc7]/10 text-white",  // Start date styles
    day_range_end: "rounded-md bg-[#d1cbc7]/10 text-white",  // End date styles
    day_today: "rounded-md bg-[#d1cbc7]/5 text-[#6b564a]",  // Today's date styles
    day_outside: "text-[#6b564a] opacity-50",  // Disabled days outside of the current month
    day_disabled: "text-[#6b564a] opacity-50",
    day_hidden: "invisible",
  }}
  components={{
    IconLeft: ({ ...props }) => (
      <ChevronLeftIcon {...props} className="h-4 w-4 stroke-[#6b564a]" />  // Left navigation icon color
    ),
    IconRight: ({ ...props }) => (
      <ChevronRightIcon {...props} className="h-4 w-4 stroke-[#6b564a]" />  // Right navigation icon color
    ),
  }}
/>


          {/* Time Picker Section */}
          <div className="mt-4">
            <div className="flex items-center justify-between">
              <span className="text-[#6b564a]">Time</span>
              <div className="flex space-x-2">
                <input
                  type="text"
                  value={time.hour}
                  onChange={(e) => setTime({ ...time, hour: e.target.value })}
                  className="w-8 bg-[#302721] text-center text-[#6b564a] border-none"
                />
                <span className="text-[#6b564a]">:</span>
                <input
                  type="text"
                  value={time.minute}
                  onChange={(e) => setTime({ ...time, minute: e.target.value })}
                  className="w-8 bg-[#302721] text-center text-[#6b564a] border-none"
                />
                <Button
                  size="sm"
                  className={`text-[#6b564a] bg-transparent px-2 rounded-lg ${
                    time.period === "AM" ? "bg-[#d1cbc7]" : ""
                  }`}
                  onClick={() => setTime({ ...time, period: "AM" })}
                >
                  AM
                </Button>
                <Button
                  size="sm"
                  className={`text-[#6b564a] bg-transparent px-2 rounded-lg ${
                    time.period === "PM" ? "bg-[#d1cbc7]" : ""
                  }`}
                  onClick={() => setTime({ ...time, period: "PM" })}
                >
                  PM
                </Button>
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
