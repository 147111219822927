// src/components/VisitorLineChart.js
import React, { useState } from 'react';
import ApexCharts from 'react-apexcharts';
import {  Typography,
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Button,
    Card } from '@material-tailwind/react'; // Importing Material-Tailwind Card
    import { ChevronDownIcon } from "@heroicons/react/24/outline";
    import { FaCircle } from "react-icons/fa";

const VisitorLineChart = () => {
    const [openMenu, setOpenMenu] = useState(false);

    const visitorData = [
        {
            name: 'New Visitors',
            number:"356,928"
          },
          {
            name: 'Unique Visitors',
            number:"125,736"
            
          },
          {
            name: 'Avg. visitors per day',
            number:"29,763"
            
          }
    ]

  const series = [
    {
      name: 'New Visitors',
      data: [10, 20, 15, 30, 25, 40, 50]
    },
    {
      name: 'Unique Visitors',
      data: [5, 15, 10, 25, 20, 35, 45]
    }
  ];

  const options = {
    chart: {
      type: 'line',
      height: 350,
      background: '#302721', // Dark brown background
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      colors: ['#FFC978', '#00BFFF'] // Line colors
    },
    xaxis: {
      type: 'datetime',
      categories: [
        new Date('2024-09-15').getTime(),
        new Date('2024-09-16').getTime(),
        new Date('2024-09-17').getTime(),
        new Date('2024-09-18').getTime(),
        new Date('2024-09-19').getTime(),
        new Date('2024-09-20').getTime(),
        new Date('2024-09-21').getTime()
      ],
      labels: {
        style: {
          fontWeight: "500",
          colors: '#CFCFCF', // Light brown for x-axis labels
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          fontWeight: "500",
          colors: '#CFCFCF', // Light brown for y-axis labels
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        gradientToColors: ['#00BFFF'], // Gradient colors for the lines
        opacityFrom: 0.5,
        opacityTo: 0.5,
      },
    },
    tooltip: {
      theme: 'dark', // Dark tooltip theme
      x: {
        format: 'MMM dd', // Date format
      },
      style: {
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        colors: ['#FFC978', '#E9E6E4'], // Tooltip text color matching the line colors
      },
    },
    grid: {
      show: true,
      borderColor: 'rgba(215, 204, 200, 0.3)', // Light brown grid lines
      xaxis: {
        lines: {
          show: false, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: true, // Hide horizontal grid lines
        },
      },
    },
  };

  return (
    <div className="w-full bg-primaryLight-800 rounded-[20px]">
         <div className="w-full flex flex-row justify-between rounded-tl-[20px] rounded-tr-[20px] p-4 bg-primaryLight-800 border-b-[1px] border-[#f0eeed]">
        <div className="w-full">
          <Typography className="text-primaryLight-50 text-xl font-bold font-playfair">
          Total Unique vs New Visitors
          </Typography>
        </div>
        <div className='min-w-[170px]'>
              <Menu open={openMenu} handler={setOpenMenu}>
                <MenuHandler>
                  <Button
                    variant="text"
                    color="white"
                    size="sm"
                    className="flex items-center gap-3 text-base font-normal capitalize tracking-normal"
                  >
                    Last 30 days{" "}
                    <ChevronDownIcon
                      strokeWidth={2.5}
                      className={`h-3.5 w-3.5 transition-transform ${
                        openMenu ? "rotate-180" : ""
                      }`}
                    />
                  </Button>
                </MenuHandler>
                {/* <MenuList className="hidden w-[36rem] grid-cols-7 gap-3 overflow-visible lg:grid">
                  <Card
                    color="gray"
                    shadow={false}
                    className="col-span-3 flex h-full w-full items-center justify-center rounded-2xl p-4"
                  >
                    <CursorArrowRaysIcon
                      strokeWidth={1}
                      className="h-10 w-10"
                    />
                    <Typography className="mt-5 text-center" variant="h5">
                      Material Tailwind PRO
                    </Typography>
                  </Card>
                  <ul className="col-span-4 flex w-full flex-col gap-1">
                    {menuItems.map(({ title, description }) => (
                      <a href="#" key={title}>
                        <MenuItem>
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-1"
                          >
                            {title}
                          </Typography>
                          <Typography
                            variant="small"
                            color="gray"
                            className="font-normal"
                          >
                            {description}
                          </Typography>
                        </MenuItem>
                      </a>
                    ))}
                  </ul>
                </MenuList> */}
              </Menu>
        </div>
      </div>
      <ApexCharts options={options} series={series} type="line" height={350} />
      <div className="w-full flex flex-row justify-between rounded-bl-[20px] rounded-br-[20px] p-4 bg-primaryLight-800 border-t-[1px] border-[#f0eeed]">
       {visitorData.map((items, index) => {
        return (
            <div className={`flex flex-col justify-between w-full items-center ${
                        index !== visitorData.length - 1
                          ? "border-r border-white"
                          : ""
                      }`}>
                <Typography className="flex items-center gap-2 font-medium font-montserrat text-white">{items.name == 'New Visitors' && <FaCircle className="cursor-pointer text-sm text-[#B59278]" />}{items.name == 'Unique Visitors' && <FaCircle className="cursor-pointer text-sm text-[#E9E6E4]" />}{" "}{items.name}</Typography>
                <Typography className="font-normal font-montserrat text-primaryLight-200">
              {items.number}
            </Typography>

            </div>
        )
       })}
      </div>
    </div>
  );
};

export default VisitorLineChart;
