import React from "react";
import { Card, CardHeader, CardBody, Typography } from "@material-tailwind/react";
import DOMPurify from 'dompurify';

// Function to extract text from HTML
const extractText = (html) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  return tempDiv.innerText || tempDiv.textContent;
};

const BlogCard1 = ({ item, setReadBlog }) => {
  const textContent = extractText(item?.description);

  return (
    <Card
      className="w-full h-full rounded-3xl overflow-hidden shadow-lg cursor-pointer"
      onClick={() => setReadBlog(true)}
    >
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="m-0 rounded-none"
      >
        <img
          src={item?.blogImg}
          alt="blog cover"
          className="object-cover w-full h-[250px]"
        />
      </CardHeader>
      <CardBody className="lg:p-6 md:p-4 p-4 min-h-[250px]">
        <Typography className="font-playfair text-2xl font-bold mb-4 text-[#302721]">
          {item?.blogTitle}
        </Typography>
        <Typography className="font-montserrat text-sm font-medium text-[#6B564A] text-opacity-60 mb-1">
          Published By {item?.Author}, {item?.date}
        </Typography>
        <Typography className="font-montserrat text-sm text-[#6B564A] text-opacity-60">
          {textContent.length > 100
            ? textContent.substring(0, 80) + "... "
            : textContent}
          {textContent.length > 100 && (
            <span className="text-blue-500 cursor-pointer" onClick={() => setReadBlog(true)}>
              Read more.
            </span>
          )}
        </Typography>
      </CardBody>
    </Card>
  );
};

export default BlogCard1;
