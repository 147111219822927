import { Button, Typography } from "@material-tailwind/react";
import { ArrowCircleLeft2, ArrowCircleRight2 } from "iconsax-react";
import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import fb5 from "../../../../assets/images/featureb5.svg";
import bgImg from "../../../../assets/images/featurebanner1.svg";
import swipperImg3 from "../../../../assets/images/vehicletype3.svg";
import ReviewBanner from "./ReviewBanner";
import "./style.css";

const Features = () => {
  const [swiperRef, setSwiperRef] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(3); // Default is 3 for larger screens

  // Set the number of slides based on the window width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setSlidesPerView(1); // 1 slide for screens smaller than 'lg'
      } else {
        setSlidesPerView(3); // 3 slides for larger screens
      }
    };

    handleResize(); // Set the initial value
    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const slides = [
    { image: "https://i.imgur.com/lnqkgmn.png", label: "New York" },
    { image: "https://i.imgur.com/wP0pV6a.png", label: "Hamptons" },
    { image: "https://i.imgur.com/ifDQdw1.png", label: "Boston" },
    { image: "https://i.imgur.com/LN0193p.png", label: "Chicago" },
    { image: "https://i.imgur.com/9TL2tm6.png", label: "San Francisco" },
  ];

  const slideText = [
    {
      title:"Plush interiors with leather seating",
      description:"Experience the ultimate in comfort and sophistication with our plush interiors, featuring premium leather seating that ensures a relaxing and elegant journey."
    },
    {title:"Highly trained and experienced drivers",
      description:"With Years of Expertise and Exceptional Skill, Our Chauffeurs Guarantee a Safe, Smooth, and Luxurious Journey Every Time"
    },
    {title:"24/7 Customer support team",
      description:"Experience Unmatched Convenience with Our 24/7 Customer Support Team, Ready to Assist You Anytime"
    },
    {title:"24/7 Dispatch Team",
      description:"Reliable and Luxurious Chauffeur Services Available Anytime, Day or Night"
    },
    {title:"Eco-Friendly Options",
      description:"We are Committed to reducing carbon footprint, We provide options for clients to contribute to environmental causes through rides"
    }
  ]

  const getSlideHeight = (index) => {
    return index === activeIndex ? "260px" : "210px";
  };
  const getSlideWidth = (index) => {
    return index === activeIndex ? "430px" : "350px";
  };
  return (
    <div className="h-full w-full">
      <div className="lg:min-h-screen h-full min-h-[50vh] mb-10">
        <div
          className="flex flex-col justify-evenly h-full lg:min-h-screen min-h-[50vh] bg-cover rounded-[20px] lg:mx-16 md:mx-8 mx-4 lg:p-8 p-4"
          style={{
            backgroundImage: `url("https://i.imgur.com/ogy83M5.png")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition:"center"
          }}
        >
          <div className="flex lg:flex-row flex-col justify-between w-full h-full min-h-[40vh]">
            <div className="flex lg:min-h-[75vh] h-full flex-col w-full">
              <Typography className="lg:text-left text-center text-5xl font-semibold mb-4 font-playfair text-primaryLight-50">
                Arriving In Style
              </Typography>
              <Typography className="hidden lg:block lg:text-left text-center text-base font-medium font-montserrat text-primaryLight-50">
                Go through our Extensive list of Premium Features
                <br />
                offered in our selective range of Cars
              </Typography>
            </div>
            <div className="flex lg:min-h-[75vh] h-full lg:justify-end justify-center items-center lg:items-start flex-col lg:max-w-[350px] w-full">
              <Typography className="hidden lg:block lg:text-left text-center max-w-[312px] text-xs font-normal mb-4 font-montserrat text-primaryLight-50">
                Escape the ordinary and step into a world of unparalleled
                chauffeured experiences. We're here to whisk you away in comfort
                and style, ensuring your journey is as unforgettable as your
                destination.
              </Typography>
              <a href="#features">
                <Button
                  variant="filled"
                  className="bg-[#FFC978] text-black hover:bg-[#ffcc33] shadow-none normal-case rounded-[4px]"
                >
                  Explore More
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-full flex items-center" id="features">
        <div className="lg:mx-16 md:mx-12 mx-4 lg:p-8 p-4 flex lg:flex-row flex-col justify-evenly w-full">
          <div className="flex flex-col gap-6 lg:max-w-[500px] max-w-full w-full lg:w-[45vw] mb-4 lg:mb-0">
            <Typography className="lg:text-left text-center text-3xl font-bold font-playfair text-primaryLight-50">
              <span className="font-bold text-5xl">Features</span>
            </Typography>
            <Typography className="text-sm text-justify font-normal font-montserrat text-primaryLight-50 lg:mr-[20px] mr-0">
              Experience unparalleled luxury and comfort with our premier
              chauffeur services. Our meticulously maintained fleet features
              premium vehicles equipped with plush leather seating, climate
              control, and advanced infotainment systems, ensuring every journey
              is as enjoyable as possible. Our highly trained and professional
              chauffeurs are dedicated to providing punctual, courteous, and
              discreet service, prioritizing your safety and satisfaction at all
              times.
            </Typography>
          </div>
          <div
            className="lg:w-[45vw] w-[80vw] flex flex-row gap-6 overflow-x-auto pb-2 [&::-webkit-scrollbar]:hidden"
          >
            <div className="relative w-[150px] h-[354px] rounded-tl-[16px] rounded-tr-[16px] rounded-br-[16px] rounded-bl-[16px]">
              <div className="absolute bottom-2 left-2 w-full flex justify-between">
                <Typography className="z-10 text-sm font-montserrat font-semibold rotate-180 [writing-mode:vertical-lr] px-2 py-4 rounded-xl bg-[#e9e6e4] text-[#251e1a] ">
                  Luxury Rides
                </Typography>
              </div>
              <img
                src="https://i.imgur.com/ANAgvK7.png"
                alt="fb1"
                className="w-full h-full rounded-[16px] object-cover"
              />
            </div>
            <div className="relative w-[150px] h-[354px] rounded-tl-[16px] rounded-tr-[16px] rounded-br-[16px] rounded-bl-[16px]">
              <div className="absolute bottom-2 left-2 w-full flex justify-between">
                <Typography className="z-10 text-sm font-montserrat font-semibold rotate-180 [writing-mode:vertical-lr] px-2 py-4 rounded-xl bg-[#e9e6e4] text-[#251e1a] ">
                  Event Packages
                </Typography>
              </div>
              <img
                src="https://i.imgur.com/Gw710Y2.png"
                alt="fb2"
                className="w-full h-full rounded-[16px] object-cover"
              />
            </div>
            <div className="relative w-[150px] h-[354px] rounded-tl-[16px] rounded-tr-[16px] rounded-br-[16px] rounded-bl-[16px]">
              <div className="absolute bottom-2 left-2 w-full flex justify-between">
                <Typography className="z-10 text-sm font-montserrat font-semibold rotate-180 [writing-mode:vertical-lr] px-2 py-4 rounded-xl bg-[#e9e6e4] text-[#251e1a] ">
                  Expert Drivers
                </Typography>
              </div>
              <img
                src="https://i.imgur.com/SLUIICc.png"
                alt="fb3"
                className="w-full h-full rounded-[16px] object-cover"
              />
            </div>
            <div className="relative w-[150px] h-[354px] rounded-tl-[16px] rounded-tr-[16px] rounded-br-[16px] rounded-bl-[16px]">
              <div className="absolute bottom-2 left-2 w-full flex justify-between">
                <Typography className="z-10 text-sm font-montserrat font-semibold rotate-180 [writing-mode:vertical-lr] px-2 py-4 rounded-xl bg-[#e9e6e4] text-[#251e1a] ">
                  24/ 7 Availability
                </Typography>
              </div>
              <img
                src="https://i.imgur.com/1DZCKYF.png"
                alt="fb4"
                className="w-full h-full rounded-[16px] object-cover"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full justify-center items-center h-full lg:px-16 md:px-12 px-4 pb-14 pt-16">
        <div className="w-full flex flex-col lg:justify-center justify-around gap-6 bg-[#2e2a28] items-center h-full lg:px-10 md:px-8 px-4 pb-6 pt-6 rounded-xl">
          <div className="w-full flex flex-row justify-between">
            {/* Custom navigation */}
            <div className="z-10 custom-prev cursor-pointer">
              <ArrowCircleLeft2 color="#d1cbc7" variant="Bulk" size="54" />
            </div>
            <div className="z-10 custom-next cursor-pointer">
              <ArrowCircleRight2 color="#d1cbc7" variant="Bulk" size="54" />
            </div>
          </div>
          <div className="flex flex-col w-full h-full items-start justify-between">
            <div className="w-full gap-4 relative">
              {/* Swiper component */}
              <Swiper
                onSwiper={setSwiperRef}
                slidesPerView={slidesPerView}
                centeredSlides={true} // Add this line to center the active slide
                slidesPerGroup={1}
                spaceBetween={30}
                pagination={{
                  el: ".swiper-custom-pagination",
                }}
                navigation={{
                  prevEl: ".custom-prev",
                  nextEl: ".custom-next",
                }}
                onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Pagination, Navigation, Autoplay]}
                className="mySwiper"
              >
                {slides.map((slide, index) => (
                  <SwiperSlide key={index} className="relative group">
                    <div className="flex items-end justify-center lg:w-[430px] w-full rounded-2xl h-[260px]">
                      <div
                        className="overflow-hidden rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300"
                        style={{
                          height: getSlideHeight(index),
                          width: getSlideWidth(index),
                        }}
                      >
                        <img
                          src={slide.image}
                          alt={slide.label}
                          className="w-full h-full object-cover rounded-2xl transition-transform duration-300 group-hover:scale-105"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              {/* <div className="swiper-custom-pagination" /> */}
            </div>
          </div>
          {/* Main Title and Description */}
          <div className="w-full text-center justify-start flex flex-col gap-4 min-h-[200px] lg:min-h-[180px]">
            <Typography className="text-4xl items-center font-bold font-playfair text-white">
              {slideText[activeIndex].title}
            </Typography>
            <Typography className="text-center text-base font-montserrat max-w-[440px] mx-auto text-white">
            {slideText[activeIndex].description}
            </Typography>
          </div>

        </div>
      </div>
      <div className="w-full h-full flex items-center">
        <ReviewBanner />
      </div>

      <div className="w-full h-full lg:px-16 md:px-12 px-4 pb-16">
        <div className="w-full h-full lg:px-16 md:px-12 px-4 py-12 bg-[#37302e] rounded-[20px]">
          <Typography className="font-playfair font-semibold lg:text-3xl md:text-2xl text-xl text-center w-full text-primaryLight-50 mb-6">
            “It is my pleasure to invite you to experience the exceptional
            quality, comfort, and professionalism that our clients have come to
            expect from us.”
          </Typography>
          <Typography className="font-montserrat font-medium text-base text-center w-full text-primaryLight-50">
            PRANIT SHARMA - CHAIRMAN & CEO{" "}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Features;
