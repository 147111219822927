import React from "react";
import { Typography, Input, Button } from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { BsUpload } from "react-icons/bs";

const ResumeForm = () => {
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    console.log("data", data);
  };

  return (
    <div className="flex justify-center items-center min-h-screen h-full">
      <div className=" bg-[#6b564a] bg-opacity-15 p-8 w-full lg:mx-16 md:mx-8 mx-6 rounded-[20px]">
        <Typography className="text-white text-3xl font-bold text-center mb-8 font-playfair">
          Upload your CV
        </Typography>
        <Typography className="text-gray-400 text-sm text-center mb-8">
          You can also send your resume and cover letter to{" "}
          <a href="mailto:careers@lyrde.com" className="text-[#E4CBA8]">
            careers@lyrde.com
          </a>
          . In your cover letter, please specify the position you are applying
          for and explain why you would be a great fit for our team.
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Input
              labelProps={{
                className: "hidden",
              }}
              label="Name"
              placeholder="Name"
              className="!bg-[#302721] text-primaryLight-300 border-none placeholder:text-primaryLight-50 placeholder:opacity-50"
              {...register("name")}
            />
            <Input
              labelProps={{
                className: "hidden",
              }}
              label="Role Applying for"
              placeholder="Role Applying for"
              className="!bg-[#302721] text-primaryLight-300 border-none placeholder:text-primaryLight-50 placeholder:opacity-50"
              {...register("role")}
            />
            <Input
              labelProps={{
                className: "hidden",
              }}
              label="Email"
              placeholder="Email"
              className="!bg-[#302721] text-primaryLight-300 border-none placeholder:text-primaryLight-50 placeholder:opacity-50"
              {...register("email")}
            />
            <Input
              labelProps={{
                className: "hidden",
              }}
              label="Years of Experience"
              placeholder="Years of Experience"
              className="!bg-[#302721] text-primaryLight-300 border-none placeholder:text-primaryLight-50 placeholder:opacity-50"
              type="number"
              {...register("experience")}
            />
            <Input
              labelProps={{
                className: "hidden",
              }}
              label="Contact Number"
              placeholder="Contact Number"
              className="!bg-[#302721] text-primaryLight-300 border-none placeholder:text-primaryLight-50 placeholder:opacity-50"
              {...register("contact")}
            />
            <div className="col-span-1">
              {/* <label className="block text-gray-400 mb-2">Upload CV</label> */}
              <div className="relative">
                <input
                  type="file"
                  id="cv-upload"
                  className="hidden"
                  {...register("cv")}
                />
                <label
                  htmlFor="cv-upload"
                  className="flex w-full justify-between items-center text-center py-2 px-4 rounded-md text-primaryLight-50 text-opacity-50 cursor-pointer bg-[#302721] hover:bg-[#3A3A3A] transition-colors">
                  upload CV <BsUpload />
                </label>
              </div>
            </div>
            <Input
              labelProps={{
                className: "hidden",
              }}
              label="Nationality"
              placeholder="Nationality"
              className="!bg-[#302721] text-primaryLight-300 border-none placeholder:text-primaryLight-50 placeholder:opacity-50"
              {...register("nationality")}
            />
          </div>
          <div className="flex w-full justify-center items-center">
            <Button
              className="mt-8 lg:w-1/3 md:w-1/3 w-full bg-[#E4CBA8] text-black py-2 rounded-md normal-case"
              type="submit">
              Continue
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResumeForm;
