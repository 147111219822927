import { Button, Typography } from "@material-tailwind/react";
import { Link, Sms } from "iconsax-react";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useForgotPasswordMutation } from "../../../redux/api/userApi";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const [forgotPassword] = useForgotPasswordMutation();

  const onSubmit = async (data) => {
    try {
      const res = await forgotPassword({
        email: data.email,
      });

      if (res.data.success) {
        navigate("/otp", { state: { email: data.email, forgot: true } });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center w-full bg-cover"
      style={{
        backgroundImage: `url("https://i.imgur.com/4GCorK6.png")`,
        backgroundRepeat: "no-repeat",
        height: `calc(100vh - 5rem)`,
      }}>
      <div className="h-[45%] flex flex-col items-center justify-center w-full">
        <Typography className="text-left text-5xl mb-4 font-bold font-playfair text-primaryLight-50">
          Welcome Back!
        </Typography>
        <Typography className="text-left text-lg font-semibold mb-20 font-montserrat text-primaryLight-50">
          Login to your account to book your favourite fleet
        </Typography>
      </div>

      <div className="flex justify-center h-[55%] w-full items-center bg-black bg-opacity-90 backdrop-blur-0 !backdrop:filter-none">
        <div className="w-full h-full max-w-[454px] px-6 pt-10 rounded-t-xl text-primaryLight-50">
          <Typography className="text-center text-3xl mb-4 font-bold font-playfair text-primaryLight-50">
            Enter Email ID
          </Typography>
          <Typography className="text-center text-sm font-semibold mb-6 font-montserrat text-primaryLight-50">
            Enter valid Email ID to recieve OTP
          </Typography>

          {/* OTP Input Fields */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="relative">
              <div className="bg-[#302721] rounded-lg flex flex-row justify-center items-center px-4 gap-4 mt-10 mb-2">
                <div className="border-r-[2px] border-[#6b564a] pr-2">
                  <Sms className="text-[#6b564a]" />
                </div>
                <input
                  type="email"
                  placeholder="Enter Email"
                  className="w-full p-4 bg-primaryLight-800 text-[#6b564a] rounded-md focus:ring-2 focus:ring-primary-500 outline-none"
                  {...register("email")}
                />
              </div>
            </div>

            <div className="text-right pb-4">
              <Link
                to="/login"
                // href="/forgot-password"
                className="text-[#6B564A] text-xs pb-1 border-b-2 border-[#6B564A] cursor-pointer">
                Already Have An Account ?
              </Link>
            </div>

            <Button
              type="submit"
              className="w-full py-3 text-center bg-[#ffc978] bg-opacity-60 text-[#251e1a] font-semibold rounded-lg hover:bg-yellow-700 transition">
              Continue
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
