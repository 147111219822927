import { Rating, Typography } from "@material-tailwind/react";
import React from "react";

const InteriorSlidder = ({ items }) => {
  return (
    <div className="w-full h-full bg-[#6b564a] rounded-[20px] px-4 py-5">
      <div className="w-full h-full flex flex-row justify-between items-end gap-2 mb-2">
        <div className="w-[38%] h-full ">
          <img src={items?.avatar} alt="avatar" className="w-full h-full" />
        </div>
        <div className="w-[58%] h-full">
          <Typography className="text-left text-sm mb-2 font-bold font-montserrat text-primaryLight-50">
            “{items?.comment}”
          </Typography>
          <Typography className="text-left text-xs mb-2 font-medium font-montserrat text-primaryLight-50">
            -{items?.name}
          </Typography>
          <div className="inline-flex items-center text-sm">
            <Rating value={items?.rating} className="w-[2px] h-[2px]" readonly />
    </div>
        </div>
      </div>
      <div className="w-full h-full">
        <Typography className="text-left text-sm mb-2 font-normal font-montserrat text-[#d1cbc7]">
          {items?.review}
        </Typography>
      </div>
    </div>
  );
};

export default InteriorSlidder;
