import { Typography } from "@material-tailwind/react";
import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import InteriorSlidder from "./InteriorSlidder";
import { Link } from "react-router-dom";

const ReviewBanner = () => {
  const testimonial = [
    {
      name: "David Pinto",
      review:
        "As frequent travelers, we have tried various chauffeur services, but none compare to the excellence provided here.",
      rating: 4,
      avatar: "https://i.imgur.com/91BlTtf.png",
      comment: "Clean Ride",
    },
    {
      name: "Basil Hailward",
      review:
        "As frequent travelers, we have tried various chauffeur services, but none compare to the excellence provided here. The ease of booking, the luxury of the vehicles, and the professionalism of the chauffeurs are unmatched. Their service has become an integral part of our travel plans",
      rating: 5,
      avatar: "https://i.imgur.com/QYmiSyU.png",
      comment: "Quick and Convinient",
    },
    {
      name: "Lisa D.",
      review:
        "As frequent travelers, we have tried various chauffeur services, but none compare to the excellence provided here.",
      rating: 5,
      avatar: "https://i.imgur.com/L6L65yA.png",
      comment: "Polite Drivers, Comfortable Ride",
    },
    {
      name: "Roger Hamilton",
      review:
        "Traveling with young children can be challenging, but their chauffeur service made it so much easier. ",
      rating: 4,
      avatar: "https://i.imgur.com/7MkdWtl.png",
      comment: "child-friendly amenities",
    },
  ];
  return (
    <div className="w-full flex lg:flex-row flex-col justify-center gap-6 items-end h-full px-[10vw] pb-16 pt-16">
      <div className="w-full justify-between h-full gap-6 flex flex-col">
        <div className="w-full h-full">
          <Typography className="text-left text-5xl font-bold mb-4 font-playfair text-primaryLight-50">
            Hear Them!
          </Typography>
          <Typography className="text-left text-sm mb-2 font-montserrat text-primaryLight-50">
            At LCS Chauffer Services, we provide a comprehensive range of
            premium chauffeur services designed to meet your every need.
          </Typography>
          <a
            href="https://www.linkedin.com/company/lryde-chauffeured-services/</div>"
            target="blank">
            <Typography className=" text-yellow-400 flex items-center cursor-pointer gap-2">
              Learn More <FaExternalLinkAlt />
            </Typography>
          </a>
        </div>
        <InteriorSlidder items={testimonial[0]} />

        {/* <img src="https://i.imgur.com/Lvmb3qr.png" alt="img1" /> */}
      </div>
      <div className="w-full justify-between gap-6 flex flex-col">
        <InteriorSlidder items={testimonial[1]} />
        {/* <img src="https://i.imgur.com/BPXYeJM.png" alt="img2" /> */}
      </div>
      <div className="w-full justify-between h-full gap-6 flex flex-col">
        {/* <img src="https://i.imgur.com/KT7Osva.png" alt="img3" /> */}
        <InteriorSlidder items={testimonial[2]} />
        <InteriorSlidder items={testimonial[3]} />
      </div>
    </div>
  );
};

export default ReviewBanner;
