import { Button, Typography } from "@material-tailwind/react";
import { ArrowRight } from "iconsax-react";
import React from "react";

const ReadBlog = ({ setReadBlog }) => {
  return (
    <>
      <div className="w-full flex justify-end items-right">
        <Button
          variant="filled"
          className="bg-[#FFC978] flex flex-row gap-1 justify-center items-center !w-[124px] text-black hover:bg-[#ffcc33] shadow-none normal-case rounded-[4px]"
          onClick={() => setReadBlog(false)}>
          Back
          <ArrowRight />
        </Button>
      </div>
      <div className="min-h-screen flex flex-col items-center w-full py-14">
        {/* Blog Content Section */}
        <div className="w-full max-w-4xl lg:p-8 md:p-6 p-6 bg-white shadow-lg rounded-xl lg:mx-8 md:mx-6 mx-4">
          {/* Header Section */}
          <div className="max-w-4xl w-full mb-12">
            <Typography className="lg:text-left lg:text-5xl md:text-3xl text-2xl text-center mb-4 font-bold font-playfair text-gray-800">
              Top 5 Day Trips From New York City for Labor Day
            </Typography>
            <Typography className="text-left text-sm mb-8 font-normal font-playfair text-gray-600">
              Published by Pranit Sharma, 18.08.24
            </Typography>
          </div>
          {/* Introduction */}
          <div className="space-y-4">
            <Typography className="font-normal lg:text-xl md:text-lg text-base text-gray-800">
              Labor Day is just around the corner, which means a well-deserved
              holiday! And what better way to spend it than by escaping the
              hustle and bustle of NYC? Whether you are carving for a beachside
              retreat, a scenic countryside adventure, or a cultural city
              experience, there are plenty of spots just a short drive away from
              NYC. But why drive yourself and deal with traffic or parking? Let
              Lryde Chauffeur Services (LCS) handle the driving while you relax
              in comfort and style. Our professional chauffeurs ensure that your
              getaway is smooth, allowing you to make the most of your day trip.
              Here are five great spots near NYC to explore this Labor Day
            </Typography>
            <Typography className="font-medium text-gray-700 leading-relaxed lg:text-xl md:text-lg text-base">
              Here are five great spots near NYC to explore this Labor Day
            </Typography>
          </div>

          {/* Regulatory Challenges */}
          <div className="space-y-4">
            <Typography className="font-bold lg:text-xl md:text-lg text-base text-gray-800">
              1. The Hamptons
            </Typography>
            <Typography className="font-medium text-gray-700 leading-relaxed lg:text-xl md:text-lg text-base">
              Distance from NYC: 2-3 hours <br />
              Attractions:
            </Typography>
            <ul className="list-disc list-inside text-gray-700 lg:text-xl md:text-lg text-base">
              <li>Gorgeous beaches</li>
              <li>Fine dining and local wineries</li>
              <li>Boutique shopping</li>
            </ul>
            <Typography className="font-medium text-gray-700 leading-relaxed lg:text-xl md:text-lg text-base">
              The Hamptons is a prime destination for those seeking a luxurious
              retreat close to New York City. From pristine beaches to
              pleasantly old-fashioned villages, the Hamptons offer something
              for everyone. With wine tastings at local vineyards, it is a
              perfect one day getaway for couples or groups of friends. Avoid
              the stress of parking and traffic by choosing Lryde Chauffeur
              Services (LCS). Let our expert drivers drive you away in comfort
              while you relax and enjoy the coastal scenery on your way.
            </Typography>
          </div>

          <div className="space-y-4">
            <Typography className="font-bold lg:text-xl md:text-lg text-base text-gray-800">
              2. Hudson Valley
            </Typography>
            <Typography className="font-medium text-gray-700 leading-relaxed lg:text-xl md:text-lg text-base">
              Distance from NYC: 1-2 hours
              <br />
              Attractions:
            </Typography>
            <ul className="list-disc list-inside text-gray-700 lg:text-xl md:text-lg text-base">
              <li>Scenic hiking trails</li>
              <li>Historic estates</li>
              <li>Farm-to-table dining</li>
            </ul>
            <Typography className="font-medium text-gray-700 leading-relaxed lg:text-xl md:text-lg text-base">
              The Hudson Valley is a scenic gem with breathtaking views of
              mountains and the Hudson River. With charming towns and plenty of
              hiking trails, it is perfect for a peaceful getaway. Stop by local
              farms for fresh produce or enjoy a quiet meal at a cozy
              restaurant. Let Lryde Chauffeur Services (LCS) take care of the
              driving while you relax and enjoy the peaceful surroundings.
            </Typography>
          </div>

          <div className="space-y-4">
            <Typography className="font-bold lg:text-xl md:text-lg text-base text-gray-800">
              3. Philadelphia, Pennsylvania
            </Typography>
            <Typography className="font-medium text-gray-700 leading-relaxed lg:text-xl md:text-lg text-base">
              Distance from NYC: 2 hours <br />
              Attractions:
            </Typography>
            <ul className="list-disc list-inside text-gray-700 lg:text-xl md:text-lg text-base">
              <li>Liberty Bell</li>
              <li>Independence Hall</li>
              <li>Philadelphia Museum of Art</li>
            </ul>
            <Typography className="font-medium text-gray-700 leading-relaxed lg:text-xl md:text-lg text-base">
              Philadelphia, also known as the "City of Brotherly Love," offers
              an ideal mix of history, culture, and a dynamic food scene. This
              makes it a perfect Labor Day weekend escape. Located just two
              hours from New York City, Philadelphia is home to some of
              America's most iconic historical landmarks, as well as modern
              attractions, ensuring there is something for everyone. Skip the
              crowded trains and confusing city parking. With LCS, your trip to
              Philadelphia will be seamless and comfortable. This gives you more
              time to explore this historical city in style and luxury.
            </Typography>
          </div>
          <div className="space-y-4">
            <Typography className="font-bold lg:text-xl md:text-lg text-base text-gray-800">
              4. Cold Spring
            </Typography>
            <Typography className="font-medium text-gray-700 leading-relaxed lg:text-xl md:text-lg text-base">
              Distance from NYC: 3 hours <br />
              Attractions:
            </Typography>
            <ul className="list-disc list-inside text-gray-700 lg:text-xl md:text-lg text-base">
              <li>Riverside views</li>
              <li>Hiking trails</li>
              <li>Antique shopping</li>
            </ul>
            <Typography className="font-medium text-gray-700 leading-relaxed lg:text-xl md:text-lg text-base">
              The charming town of Cold Spring offers scenic views and beautiful
              hiking trails. Whether you are exploring antique shops or enjoying
              a riverside stroll, it is the perfect spot for a peaceful day
              away. Take the scenic route to Cold Spring with a professional
              chauffeur from LCS. Enjoy the peaceful countryside without
              worrying about navigation or parking—just relax and take in the
              beauty around you.
            </Typography>
          </div>
          <div className="space-y-4">
            <Typography className="font-bold lg:text-xl md:text-lg text-base text-gray-800">
              5. Montauk
            </Typography>
            <Typography className="font-medium text-gray-700 leading-relaxed lg:text-xl md:text-lg text-base">
              Distance from NYC: 3 hours <br />
              Attractions:
            </Typography>
            <ul className="list-disc list-inside text-gray-700 lg:text-xl md:text-lg text-base">
              <li>Breathtaking beaches</li>
              <li>Montauk Point Lighthouse</li>
              <li>Sunset views at Montauk Harbor</li>
            </ul>
            <Typography className="font-medium text-gray-700 leading-relaxed lg:text-xl md:text-lg text-base">
              At the eastern tip of Long Island, Montauk offers stunning
              beaches, great seafood, and iconic views from the Montauk Point
              Lighthouse. Spend the day lounging on the sand or catching a
              gorgeous sunset over the harbor. A day in Montauk should be all
              about relaxation. Let LCS take care of the driving so you can soak
              in the sun and enjoy the coastal views on your way to this serene
              beach town.
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReadBlog;
