import { Button, Typography } from "@material-tailwind/react";
import { ArrowRight } from "iconsax-react";
import React from "react";
import { useGetBlogByIdQuery } from "../../../../redux/api/admin/adminApi";

const ReadBlog = ({ blogId, setReadBlog }) => {
  console.log("Id", blogId);

  const { data, isLoading, error } = useGetBlogByIdQuery(blogId); // Fetch the specific blog using the blogId

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading blog</div>;

  return (
    <>
      <div className="w-full flex justify-end items-right">
        <Button
          variant="filled"
          className="bg-[#FFC978] flex flex-row gap-1 justify-center items-center !w-[124px] text-black hover:bg-[#ffcc33] shadow-none normal-case rounded-[4px]"
          onClick={() => setReadBlog(false)}
        >
          Back
          <ArrowRight />
        </Button>
      </div>
      <div className="min-h-screen flex flex-col items-center w-full py-14">
        <div className="w-full max-w-4xl lg:p-8 md:p-6 p-6 bg-white shadow-lg rounded-xl lg:mx-8 md:mx-6 mx-4">
          <div className="max-w-4xl w-full mb-12">
            <Typography className="lg:text-left lg:text-5xl md:text-3xl text-2xl text-center mb-4 font-bold font-playfair text-gray-800">
              {data?.blog?.title || "Loading..."} {/* Dynamic title */}
            </Typography>
            <Typography className="text-left text-sm mb-8 font-normal font-playfair text-gray-600">
              Published by Pranit Sharma, {data?.blog?.createdAt.split("T")[0] || "Unknown Date"}
            </Typography>
          </div>
          {/* Blog Content Section */}
          <div className="space-y-4">
            <div 
              className="font-normal lg:text-xl md:text-lg text-base text-gray-800"
              dangerouslySetInnerHTML={{ __html: data?.blog?.description || "Loading..." }} // Render HTML safely
            />
          </div>

          {/* Additional sections */}
          {data?.blog?.sections?.map((section, index) => (
            <div key={index} className="space-y-4">
              <Typography className="font-bold lg:text-xl md:text-lg text-base text-gray-800">
                {section.title}
              </Typography>
              <div 
                className="font-medium text-gray-700 leading-relaxed lg:text-xl md:text-lg text-base"
                dangerouslySetInnerHTML={{ __html: section.content }} // Render HTML safely
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ReadBlog;
