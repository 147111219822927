import { Typography } from "@material-tailwind/react";
import { Edit } from "iconsax-react";
import React from "react";
import FleetSelectionCard from "../../../../common/components/FleetSelectionCard";
import { useGetRequestQuery } from "../../../../redux/api/userApi";

const ChooseFleet = ({ handleNext, payload, setData }) => {
  console.log(payload);

  const token = localStorage.getItem("token");
  const { data: requestData } = useGetRequestQuery({ payload, token });
  console.log("Request: ", requestData);

  const staticRides = [
    {
      name: "Business Sedan",
      wifi: "Free Wifi Onboard",
      compl: "Complimentary Beverages",
      type: "Business Sedan",
      lugage: "3",
      seats: "3",
      fare: requestData?.price?.business_sedan.toFixed(2),
      carImg: "https://i.imgur.com/3yRrTin.png",
    },
    {
      name: "SUV",
      wifi: "Free Wifi Onboard",
      compl: "Complimentary Beverages",
      type: "SUV",
      lugage: "3",
      seats: "3",
      fare: requestData?.price?.business_suv.toFixed(2),
      carImg: "https://i.imgur.com/u7TOUxD.png",
    },
    {
      name: "First Class",
      wifi: "Free Wifi Onboard",
      compl: "Complimentary Beverages",
      type: "First Class",
      lugage: "3",
      seats: "3",
      fare: requestData?.price?.first_class.toFixed(2),
      carImg: "https://i.imgur.com/YzmzxSy.png",
    },
  ];
  const time = requestData?.request?.time;
  console.log(time);

  const timeObject = `${time?.hour} : ${time?.minute} ${time?.period}`;

  const rideTypesMap = {
    airportNYC: "Airport NY",
    airportONYC: "Airport ONY",
    pointToPoint: "Point to Point",
    hourly: "Hourly",
    groupsAndEvents: "Groups and Events",
    private: "Private",
    cityTour: "City Tour",
  };

  const displayRideType = rideTypesMap[requestData?.request?.rideType] || requestData?.request?.rideType;

  return (
    <div className=" flex lg:flex-row flex-col-reverse w-full gap-6 mb-6">
      <div className="flex flex-col items-center rounded-[20px] border-[1px] p-6 py-10 border-primaryLight-300 lg:max-w-[250px] w-full">
        <Typography className="text-left w-[90%] flex flex-row items-center gap-4 text-2xl mb-6 font-bold font-montserrat text-primaryLight-200">
          Summary 
        </Typography>
        <div className="border-b-[4px] border-dotted border-primaryLight-300 flex w-[90%] mb-4"></div>
        <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50 text-opacity-50">
          <span className="lg:pl-[3px] pl-[4vw] font-medium">Pickup</span>
        </Typography>
        <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50">
          <span className="lg:pl-[3px] pl-[4vw] font-medium">
            {requestData?.request?.pickup?.address}
          </span>
        </Typography>
        <div className="border-b-[4px] border-dotted border-primaryLight-300 flex w-[90%] my-4"></div>
        <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50 text-opacity-50">
          <span className="lg:pl-[3px] pl-[4vw] font-medium">Drop Off</span>
        </Typography>
        <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50">
          <span className="lg:pl-[3px] pl-[4vw] font-medium">
            {requestData?.request?.dropof?.address}
          </span>
        </Typography>
        <div className="border-b-[4px] border-dotted border-primaryLight-300 flex w-[90%] my-4"></div>
        <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50 text-opacity-50">
          <span className="lg:pl-[3px] pl-[4vw] font-medium">Pickup Date & Time</span>
        </Typography>
        <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50">
          <span className="lg:pl-[3px] pl-[4vw] font-medium">
            {requestData?.request?.fromDate?.split("T")[0]} & {timeObject}
          </span>
        </Typography>
        <div className="border-b-[4px] border-dotted border-primaryLight-300 flex w-[90%] my-4"></div>
        <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50 text-opacity-50">
          <span className="lg:pl-[3px] pl-[4vw] font-medium">Type of ride</span>
        </Typography>
        <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50">
          <span className="lg:pl-[3px] pl-[4vw] font-medium">
            {displayRideType}
          </span>
        </Typography>
        
        {/* Note Box */}
        <div className="mt-4 p-4 w-[90%] border border-yellow-700  rounded-lg bg-opacity-10 bg-primaryLight-50">
          <Typography className="text-sm  font-montserrat text-primaryLight-50">
            Note: Call this number to get special discount<br/><span className="font-bold">(332) 223-7779</span>
          </Typography>
        </div>
      </div>

      <div className="flex flex-col items-center rounded-[20px] border-[1px] p-6 justify-between border-primaryLight-300 w-full">
        <div className="w-full flex items-start">
          <Typography className="text-left text-2xl mb-2 font-bold font-playfair text-primaryLight-200">
            Scheduled Rides
          </Typography>
        </div>
        <div
          className="w-full h-full overflow-y-auto max-h-[420px] [&::-webkit-scrollbar]:w-1.5 [&::-webkit-scrollbar-track]:bg-[#6b564a] [&::-webkit-scrollbar-track]:bg-opacity-10 [&::-webkit-scrollbar-thumb]:rounded-full
                                         [&::-webkit-scrollbar-thumb]:bg-[#6b564a]">
          {staticRides?.map((items, index) => {
            return (
              <div
                key={index}
                className="border-t-4 border-dotted border-primaryLight-300 flex w-[90%] mt-4">
                <FleetSelectionCard
                  token={token}
                  requestId={payload}
                  items={items}
                  handleNext={handleNext}
                  setData={setData}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ChooseFleet;
