import { Button, Navbar } from "@material-tailwind/react";
import logo from "../../assets/images/lcslogo.svg";
function Header() {
  return (
    <>
      <Navbar className="sticky top-0 h-max max-w-full rounded-none px-4 py-2 lg:px-8 lg:py-4 bg-transparent bg border-0 z-50">
        <div className="flex items-center justify-between text-blue-gray-900 px-12">
          <div className="mr-4 cursor-pointer w-[91px] h-[48px] py-1.5 font-medium">
            <img src={logo} alt="logo" className="w-full h-full" />
          </div>

          <div className="flex w-full justify-end items-center">
            <div className="lg:flex items-center gap-x-6 cursor-pointer">
              <Button
                variant="filled"
                className="bg-[#FFC978] !w-[150px] text-black hover:bg-[#ffcc33] shadow-none normal-case rounded-[4px] font-montserrat">
                <a href="https://lrydecs.com/">Reserve Now</a>
              </Button>
            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
}

export default Header;
