import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Typography,
} from "@material-tailwind/react";
import { ArrowCircleDown2 } from "iconsax-react";
import React, { useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";

function Icon({ id, open }) {
  return (
    <ArrowCircleDown2
      color="#F0EEED"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    />
  );
}

const Faqs = () => {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <div className="w-full h-full lg:px-16 md:px-8 px-4 pb-16 pt-16">
      <div className="w-full flex lg:flex-row flex-col justify-center gap-8 items-start h-full">
        <div className="lg:w-[43%] w-full justify-between h-full gap-6 flex flex-col">
          <img
            src="https://i.imgur.com/yzvVFJ1.png"
            alt="faqImg"
            className="w-full h-full"
          />
        </div>
        <div className="lg:w-[50%] w-full justify-between h-full gap-6 flex flex-col">
          <div className="w-full h-full">
            <Typography className="text-left text-5xl font-bold mb-4 font-playfair text-primaryLight-50">
              FAQ
            </Typography>
            <Typography className="text-left text-sm mb-2 font-montserrat text-primaryLight-50">
              We’re here to resolve all your queries!
            </Typography>
            {/* <Typography className=" text-yellow-400 flex items-center cursor-pointer gap-2">
          Learn More <FaExternalLinkAlt />
        </Typography> */}

            <div className="h-full lg:w-[90%] w-full">
              <div className="w-full h-full mb-4">
                <Accordion
                  open={open === 1}
                  icon={<Icon id={1} open={open} />}
                  className={`border-[1px] rounded-xl border-[#F0EEED] px-4 ${
                    open === 1 ? "bg-[#A78774]" : " "
                  }}`}>
                  <AccordionHeader
                    onClick={() => handleOpen(1)}
                    className="text-sm border-b-0 mb-0 font-bold font-montserrat text-[#F0EEED] hover:text-[#F0EEED]">
                    How do I book a ride?
                  </AccordionHeader>
                  <AccordionBody className="text-xs font-medium font-montserrat pt-0 text-[#D1CBC7] hover:text-[#D1CBC7]">
                    Booking a ride is easy! You can book directly through our
                    website, mobile app, or by calling our 24/7 customer service
                    line. Simply provide your pick-up and drop-off locations,
                    select your vehicle, and confirm your booking.
                  </AccordionBody>
                </Accordion>
              </div>
              <div className="w-full h-full mb-4">
                <Accordion
                  open={open === 2}
                  icon={<Icon id={2} open={open} />}
                  className={`border-[1px] rounded-xl border-[#F0EEED] px-4 ${
                    open === 2 ? "bg-[#A78774]" : " "
                  }}`}>
                  <AccordionHeader
                    onClick={() => handleOpen(2)}
                    className="text-sm border-b-0 mb-0 font-bold font-montserrat text-[#F0EEED] hover:text-[#F0EEED]">
                    What payment methods do you accept?
                  </AccordionHeader>
                  <AccordionBody className="text-xs font-medium font-montserrat pt-0 text-[#D1CBC7] hover:text-[#D1CBC7]">
                    We&apos;re not always in the position that we want to be at.
                    We&apos;re constantly growing. We&apos;re constantly making
                    mistakes. We&apos;re constantly trying to express ourselves
                    and actualize our dreams.
                  </AccordionBody>
                </Accordion>
              </div>
              <div className="w-full h-full mb-4">
                <Accordion
                  open={open === 3}
                  icon={<Icon id={3} open={open} />}
                  className={`border-[1px] rounded-xl border-[#F0EEED] px-4 ${
                    open === 3 ? "bg-[#A78774]" : " "
                  }}`}>
                  <AccordionHeader
                    onClick={() => handleOpen(3)}
                    className="text-sm border-b-0 mb-0 font-bold font-montserrat text-[#F0EEED] hover:text-[#F0EEED]">
                    What happens if my ride is delayed?
                  </AccordionHeader>
                  <AccordionBody className="text-xs font-medium font-montserrat pt-0 text-[#D1CBC7] hover:text-[#D1CBC7]">
                    We&apos;re not always in the position that we want to be at.
                    We&apos;re constantly growing. We&apos;re constantly making
                    mistakes. We&apos;re constantly trying to express ourselves
                    and actualize our dreams.
                  </AccordionBody>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full flex flex-col items-center mt-8">
        <Typography className="text-center text-base mb-2 font-medium font-montserrat text-primaryLight-50">
          Still have doubts? Contact us and ask away all your queries!
        </Typography>
        <a href="#footer">
        <Button
          variant="filled"
          // color="yellow"
          size="sm"
          className="flex items-center justify-around gap-3 min-w-[175px] text-base rounded-md font-normal normal-case bg-[#FFC978] text-primaryLight-900 border-primaryLight-900 tracking-normal pl-6">
          Ask Your Queries{" "}
          <FaExternalLinkAlt strokeWidth={2.5} className="h-3.5 w-3.5" />
        </Button>
        </a>
      </div>
    </div>
  );
};

export default Faqs;