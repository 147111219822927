import { Tab, Tabs, TabsHeader, Typography } from "@material-tailwind/react";
import {
  ArrowCircleLeft2,
  ArrowCircleRight2,
  Profile2User,
  ShoppingBag,
} from "iconsax-react";
import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./style.css";
import { useGetFleetsQuery } from "../../../../redux/api/fleetApi";

const TypesOfVehicles = () => {
  const { data } = useGetFleetsQuery();
  const [swiperRef, setSwiperRef] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("All"); // For active tab
  const [slidesPerView, setSlidesPerView] = useState(3); // Default for larger screens

  // Set slidesPerView based on window size
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setSlidesPerView(1); // Smaller screens
      } else {
        setSlidesPerView(3); // Larger screens
      }
    };

    handleResize(); // Initial value
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const tabData = [
    { label: "All", value: "All" },
    { label: "Business Sedan", value: "sedan" },
    { label: "Business SUV", value: "suv" },
    { label: "First Class", value: "firstClass" },
  ];

  // Filter the data based on the active tab
  const filteredFleets = activeTab === "All"
    ? data?.fleets || [] // Show all fleets if 'All' is selected
    : data?.fleets?.filter((fleet) => fleet.typeOfFleet === activeTab) || [];

  const getSlideHeight = (index) => {
    return index === activeIndex ? "250px" : "210px";
  };

  const getSlideWidth = (index) => {
    return index === activeIndex ? "400px" : "337px";
  };

  return (
    <div className="w-full justify-center items-center h-full lg:px-14 md:px-8 px-4 pb-14 pt-16" id="openfleet">
      <div className="w-full flex flex-col justify-center gap-6 bg-[#37302e] items-center h-full lg:px-14 md:px-8 px-4 pb-14 pt-6 rounded-xl">
        <div className="w-full h-full items-center gap-6 flex lg:flex-row flex-col">
          <Typography className="text-left text-5xl items-center font-bold font-playfair text-primaryLight-50">
            Vehicles
          </Typography>
          <Typography className="text-left text-sm font-montserrat max-w-[440px] text-primaryLight-50">
            Our services now extend their reach across two states, bringing our offerings closer to you wherever you may be.
          </Typography>
          <div className="w-full flex justify-evenly">
            {/* Custom navigation */}
            <div className="flex w-full items-center justify-end space-y-2 custom-navigation">
              <div className="flex flex-row justify-between gap-4">
                <div className="custom-prev cursor-pointer text-white">
                  <ArrowCircleLeft2 color="#d1cbc7" size="54" />
                </div>
                <div className="custom-next cursor-pointer text-white">
                  <ArrowCircleRight2 color="#d1cbc7" size="54" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:w-[70%] w-full">
          <div className="flex flex-row justify-center gap-3">
            <div className="w-full">
              <Tabs value={activeTab} className="bg-transparent">
                <TabsHeader
                  className="bg-transparent"
                  indicatorProps={{
                    className: "bg-[#56453b]",
                  }}>
                  {tabData.map(({ label, value }, index) => (
                    <Tab
                      key={value}
                      value={value}
                      onClick={() => setActiveTab(value)} // Change the tab on click
                      className={`!text-[#f0eeed] font-normal !h-[60px] lg:h-full ${
                        index !== tabData.length - 1
                          ? "border-r border-white"
                          : ""
                      }`}>
                      {label}
                    </Tab>
                  ))}
                </TabsHeader>
              </Tabs>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full h-full items-start justify-between">
          <div className="w-full gap-4">
            <Swiper
              onSwiper={setSwiperRef}
              slidesPerView={slidesPerView}
              slidesPerGroup={1}
              spaceBetween={30}
              pagination={{
                el: ".swiper-custom-pagination",
              }}
              navigation={{
                prevEl: ".custom-prev",
                nextEl: ".custom-next",
              }}
              onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper">
              {filteredFleets.map((slide, index) => (
                <SwiperSlide key={index} className="relative group">
                  <div className="flex items-end justify-center lg:max-w-[410px] max-w-full w-full h-[260px]">
                    <div
                      className="overflow-hidden rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300 relative"
                      style={{
                        height: getSlideHeight(index),
                        width: getSlideWidth(index),
                      }}>
                      {/* Name and Price at the top */}
                      <div className="absolute top-0 left-0 w-full flex justify-between p-4 bg-gradient-to-t from-transparent to-black text-white">
                        <div className="text-sm">{slide.name}</div>
                        {/* <div className="text-sm font-bold">${slide.price}</div> */}
                      </div>

                      {/* Main Image */}
                      <img
                        src={slide.image}
                        alt={slide.name}
                        className="w-[8-px] h-full object-contain transition-transform duration-300 group-hover:scale-105"
                      />

                      {/* Icons at the bottom */}
                      <div className="absolute bottom-4 left-4 flex items-center space-x-4">
                        <div className="flex items-center justify-center w-16 h-8 bg-white rounded-2xl text-black">
                          <Profile2User size="16" className="mr-2" />
                          {slide.seats}
                        </div>
                        <div className="flex items-center justify-center w-16 h-8 bg-white bg-opacity-5 rounded-2xl text-white">
                          <ShoppingBag size="16" className="mr-2" />
                          {slide.luggage}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-custom-pagination" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypesOfVehicles;
