import { Button, Typography } from "@material-tailwind/react";
import React from "react";
import banner2 from "../../../../assets/images/careersb2.svg";
import ResumeForm from "./ResumeForm";

const Careers = () => {
  return (
    <div className="min-h-screen w-full h-full">
      {/* <div className="w-full h-full">
        <img
          src="https://i.imgur.com/1XdR0Ic.png"
          alt="banner-3"
          className="w-full min-h-[180px] h-full px-16 pb-16"
        />
      </div> */}

      <div className="lg:min-h-screen h-full min-h-[50vh] mb-10">
        <div
          className="flex flex-col justify-evenly h-full lg:min-h-screen min-h-[50vh] bg-cover rounded-[20px] lg:mx-16 md:mx-8 mx-4 lg:p-8 p-4"
          style={{
            backgroundImage: `url("https://i.imgur.com/JnwCGHe.png")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}>
          <div className="flex lg:flex-row flex-col justify-between w-full h-full min-h-[40vh]">
            <div className="flex lg:min-h-[75vh] h-full flex-col w-full">
              <Typography className="lg:text-left text-center text-5xl font-semibold mb-4 font-playfair text-primaryLight-50">
                Join Our Team
              </Typography>
              <Typography className="hidden lg:block lg:text-left text-center text-base font-medium font-montserrat text-primaryLight-50">
                and Drive the Future of Luxury Transportation
              </Typography>
            </div>
            <div className="flex lg:min-h-[75vh] h-full lg:justify-end justify-center items-center lg:items-start flex-col lg:max-w-[350px] w-full">
              <Typography className="hidden lg:block lg:text-left text-center max-w-[312px] text-xs font-normal mb-4 font-montserrat text-primaryLight-50">
                At Lryde Chauffeur Services, we believe in delivering
                unparalleled customer service and creating exceptional
                experiences for our clients. If you have a commitment to
                excellence and a desire to be part of a dynamic and innovative
                company, we invite you to explore our current career
                opportunities.
              </Typography>
              <a href="#features">
                <Button
                  variant="filled"
                  className="bg-[#FFC978] text-black hover:bg-[#ffcc33] shadow-none normal-case rounded-[4px]">
                  Apply Now
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-full">
        <img
          src={banner2}
          alt="banner-3"
          className="w-full min-h-[180px] h-full px-16 pb-16"
        />
      </div>
      <div className="w-full h-full">
        <ResumeForm />
      </div>
      <div className="w-full h-full px-16 pb-16">
        <Typography className="text-center font-playfair font-semibold text-2xl text-primaryLight-50 mb-6">
          We look forward to hearing from you and exploring how you can
          contribute to the
          <br />
          success of Lryde Chauffeur Services.
        </Typography>
      </div>
    </div>
  );
};

export default Careers;
