import { Button, Typography } from "@material-tailwind/react";
import React, { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./style.css";
import { GoLinkExternal } from "react-icons/go";
import { useNavigate } from "react-router";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";
import {
  useCreateBlogMutation,
  useGetDraftQuery,
  useSaveAsDraftMutation,
} from "../../../../redux/api/admin/adminApi";
import axios from "axios";

const CreateBlog = ({ blogId }) => {
  const token = localStorage.getItem("token");
  const [error, setError] = useState("");

  // Fetch drafted inputs
  const { data: getDraftedInputs } = useGetDraftQuery({ token, blogId });
  console.log(getDraftedInputs);

  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [images, setImages] = useState([null, null, null]);
  const [imageURLs, setImageURLs] = useState([
    "https://docs.material-tailwind.com/img/face-2.jpg",
    "https://docs.material-tailwind.com/img/face-2.jpg",
    "https://docs.material-tailwind.com/img/face-2.jpg",
  ]);

  const [createBlog] = useCreateBlogMutation();
  const [saveAsDraft] = useSaveAsDraftMutation();

  // Effect to populate fields if drafted inputs exist
  useEffect(() => {
    if (getDraftedInputs) {
      setTitle(getDraftedInputs?.blogs?.title || "");
      setContent(getDraftedInputs?.blogs?.description || "");
      setImages(getDraftedInputs?.blogs?.images || [null, null, null]);
      setImageURLs(
        getDraftedInputs?.blogs?.images?.map(
          (img, index) => img || imageURLs[index]
        )
      );
    }
  }, [getDraftedInputs]);

  const uploadImageToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "Blog_Upload"); // Replace with your upload preset
    formData.append("cloud_name", "da54j1nbe"); // Replace with your Cloudinary cloud name

    const response = await axios.post(
      `https://api.cloudinary.com/v1_1/da54j1nbe/image/upload`, // Replace with your Cloudinary URL
      formData
    );

    return response.data.secure_url; // Return the secure URL of the uploaded image
  };

  const handleImageChange = async (index, file) => {
    if (file) {
      const uploadedImageUrl = await uploadImageToCloudinary(file);
      const newImages = [...images];
      const newImageURLs = [...imageURLs];

      newImages[index] = uploadedImageUrl; // Store the Cloudinary URL
      newImageURLs[index] = URL.createObjectURL(file); // Preview image URL

      setImages(newImages);
      setImageURLs(newImageURLs);
    }
  };

  const handleUpload = async () => {
    const body = {
      title,
      description: content,
      images,
    };
    const { data, error } = await createBlog({ token, body });
    if (error) {
      setError(error.data.message);
    }
    if (data) {
      navigate("/blogs");
    }
  };

  const handleDraft = async () => {
    const body = {
      title,
      description: content,
      images,
    };
    const { data, error } = await saveAsDraft({ token, body, blogId });
    if (error) {
      setError(error.data.message);
    }
    if (data) {
      navigate("/draft-blog");
    }
  };

  return (
    <div className="min-h-screen lg:mx-16 md:mx-12 mx-4 flex flex-col items-center mb-10">
      <div className="flex flex-col w-full mt-14">
        <div className="w-full flex lg:flex-row md:flex-row sm:flex-col flex-col justify-between items-center mb-10">
          <Typography className="text-4xl font-[800] font-playfair text-white mb-6">
            Upload Blog
          </Typography>
          <div className="flex justify-between gap-4">
            <Button
              variant="filled"
              className="flex justify-center gap-2 items-center bg-[#FFC978] text-[#000000] normal-case text-xl font-medium ring-0 focus:ring-0"
              onClick={() => navigate("/blogs")}>
              View All Blogs <GoLinkExternal />
            </Button>
            <Button
              variant="outlined"
              className="flex justify-center gap-2 items-center border-[#FFC978] text-[#FFC978] normal-case text-xl font-medium ring-0 focus:ring-0"
              onClick={() => navigate("/draft-blog")}>
              View Drafts <GoLinkExternal />
            </Button>
          </div>
        </div>
      </div>
      <div className="w-full">
        <Typography className="font-bold lg:text-xl md:text-lg text-base font-playfair mb-6 text-white">
          Add Title
        </Typography>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Blog Title"
          className="w-full p-2 mb-6 border rounded-lg text-white bg-[#6B564A57] placeholder:text-[#ffffff]"
        />
        <Typography className="font-bold lg:text-xl md:text-lg text-base font-playfair mb-6 text-white">
          Add Content
        </Typography>
        <div className="w-full flex justify-center mb-6">
          <div className="mb-6 w-full border rounded-lg border-none">
            <ReactQuill
              theme="snow"
              value={content}
              onChange={setContent}
              className="custom-quill bg-[#6B564A57] bg-opacity-35 text-[#ffffff] placeholder:text-[#ffffff]"
              placeholder="Tell your story..."
              style={{
                minHeight: "200px",
              }}
            />
          </div>
        </div>
        {/* Upload Photos Section */}
        <div className="w-full mt-10 gap-4 flex flex-col sm:flex-col md:flex-row lg:flex-row justify-between">
          {images.map((image, index) => (
            <div
              key={index}
              className="flex-1 border-[#6B564A] border-dashed border-[4px] rounded-lg text-center text-white h-[126px]">
              <label className="cursor-pointer">
                <input
                  type="file"
                  className="hidden"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => handleImageChange(index, e.target.files[0])}
                />
                {image ? (
                  <img
                    src={imageURLs[index]}
                    alt={`Image ${index + 1} Preview`}
                    className="object-cover h-full w-full"
                  />
                ) : (
                  <div className="w-full h-full flex justify-center items-center">
                    Upload Photo {index + 1}
                  </div>
                )}
              </label>
            </div>
          ))}
        </div>
        <p className="text-red-500 text-sm mt-4 mb-4">{error}</p>
        {!blogId ? (
          <Button
            variant="filled"
            className="mt-6 bg-[#FFC978] text-[#000000] normal-case text-xl font-medium"
            onClick={handleUpload}>
            Upload Blog
          </Button>
        ) : (
          ""
        )}
        <Button
          variant="outlined"
          className="mt-6 border-[#FFC978] text-[#fff] normal-case text-xl font-medium ml-2"
          onClick={handleDraft}>
          {!blogId ? "Save As Draft" : "Publish"}
        </Button>
      </div>
    </div>
  );
};

export default CreateBlog;
