// src/components/BookingHeatMap.js
import React from 'react';
import ApexCharts from 'react-apexcharts';
import { Typography } from '@material-tailwind/react';
import labeling from '../../../../assets/images/label.png';

const BookingHeatMap = () => {
  const series = [
    {
      name: '00:00',
      data: [10, 5, 15, 20, 10, 8, 12],
    },
    {
      name: '02:00',
      data: [10, 5, 15, 20, 10, 8, 12],
    },
    {
      name: '04:00',
      data: [20, 10, 8, 15, 12, 5, 7],
    },
    {
      name: '06:00',
      data: [20, 10, 8, 15, 12, 5, 7],
    },
    {
      name: '08:00',
      data: [30, 20, 15, 10, 5, 8, 12],
    },
    {
      name: '10:00',
      data: [30, 20, 15, 10, 5, 8, 12],
    },
    {
      name: '12:00',
      data: [25, 15, 10, 8, 12, 5, 10],
    },
    {
      name: '14:00',
      data: [25, 15, 10, 8, 12, 5, 10],
    },
    {
      name: '16:00',
      data: [15, 10, 20, 30, 12, 10, 15],
    },
    {
      name: '18:00',
      data: [15, 10, 20, 30, 12, 10, 15],
    },
    {
      name: '20:00',
      data: [10, 5, 15, 20, 10, 8, 12],
    },
    {
      name: '22:00',
      data: [10, 5, 15, 20, 10, 8, 12],
    },
  ];

  const options = {
    chart: {
      type: 'heatmap',
      height: 350,
      background: '#302721', // Dark brown background to match the theme
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 10,
              color: '#402D2C', // Dark brown for low values
              name: 'Low',
            },
            {
              from: 11,
              to: 20,
              color: '#D6B9A8', // Lighter brown for medium values
              name: 'Medium',
            },
            {
              from: 21,
              to: 30,
              color: '#B59278', // Light brown for high values
              name: 'High',
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      labels: {
        style: {
          fontWeight: '500',
          colors: '#CFCFCF', // Light brown for x-axis labels
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontWeight: '500',
          colors: '#CFCFCF', // Light brown for y-axis labels
        },
      },
      categories: ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00'], // Time in 24-hour format
    },
    tooltip: {
      theme: 'dark', // Dark tooltip theme
    },
    grid: {
      borderColor: 'rgba(215, 204, 200, 0.3)', // Light brown grid lines
    },
  };

  return (
    <div className="w-full bg-primaryLight-800 rounded-[20px]">
      <div className="w-full flex rounded-tl-[20px] rounded-tr-[20px] p-4 pb-7 bg-primaryLight-800 border-b-[1px] border-[#f0eeed]">
          <Typography className="text-primaryLight-50 text-xl font-bold font-playfair">
          Booking by Time of Day
          </Typography>
      </div>
      <ApexCharts options={options} series={series} type="heatmap" height={350} />
      <div className="w-full flex flex-row justify-between rounded-bl-[20px] rounded-br-[20px] p-4 bg-primaryLight-800 border-t-[1px] border-[#f0eeed]">
        <img src={labeling} alt="label" className='w-full h-full' />
      </div>
    </div>
  );
};

export default BookingHeatMap;

