import React from "react";
import Marquee from "react-fast-marquee";
import logo1 from "../../assets/Logos/image 2.png";
import logo2 from "../../assets/Logos/5f34be2128421c75a1ec4cfa056ad9e9 1.png";
import logo3 from "../../assets/Logos/pngegg (3) 1.png";
import logo4 from "../../assets/Logos/pngwing.com (10) 1.png";
import logo5 from "../../assets/Logos/pngwing.com (7) 1.png";
import logo6 from "../../assets/Logos/pngwing.com (8) 1.png";
import logo7 from "../../assets/Logos/pngwing.com (9) 1.png";

const Brands = () => {
  return (
    <div className="max-h-[175px] w-full text-white mt-14">
      <h1
        className="font-bold text-4xl text-center"
        style={{ fontFamily: "Playfair Display, serif" }}>
        Brands You Trust
      </h1>
      <Marquee speed={100} className="mt-10" autoFill={true}>
        <img src={logo1} width={100} height={100} alt="logo" className="mx-8" />
        <img src={logo2} width={100} height={100} alt="logo" className="mx-8" />
        <img src={logo3} width={100} height={100} alt="logo" className="mx-8" />
        <img src={logo4} width={100} height={100} alt="logo" className="mx-8" />
        <img src={logo5} width={100} height={100} alt="logo" className="mx-8" />
        <img src={logo6} width={100} height={100} alt="logo" className="mx-8" />
        <img src={logo7} width={100} height={100} alt="logo" className="mx-8" />
      </Marquee>
    </div>
  );
};

export default Brands;
