import "leaflet-defaulticon-compatibility";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import "./App.css";
import Router from "./routes";
import { checkTokenExpiry } from "./utils/checkTokenExpiry";
import Catelog from "./containers/Catelog";
import { useLocation } from "react-router";
import Loader from "./common/components/Loader/Loader";

function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  // Check token expiry on component mount
  useEffect(() => {
    const isTokenExpired = checkTokenExpiry();
    if (isTokenExpired) {
      console.log("Token expired. Please login again");
    }
  }, []);

  // Listen for the window load event to ensure all assets are loaded
  useEffect(() => {
    const handleLoad = () => {
      setLoading(false); // Disable loader after all resources are loaded
    };

    window.addEventListener("load", handleLoad);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  // If current location is `/catalogue`, render Catelog
  if (location.pathname === "/catalogue") {
    return <Catelog />;
  }

  // Show loader while loading is true
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Router />
    </>
  );
}

export default App;
