import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const urls = ["https://lcs-reboot-go97.onrender.com/api/fleets", "http://localhost:6969/api/fleets"];
// const urls = "http://localhost:6969/api/auth"
export const fleetAPI = createApi({
  reducerPath: "fleetApi",
  baseQuery: fetchBaseQuery({
    baseUrl: urls[0],
  }),
  endpoints: (builder) => ({
    getFleets: builder.query({
      query: (requestId) => ({
        url: `/`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetFleetsQuery } = fleetAPI;
