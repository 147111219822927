import { Button, Input } from "@material-tailwind/react";
import { ArrowDown, Car } from "iconsax-react";
import "leaflet-defaulticon-compatibility";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react"; // Added useEffect import
import pickdrop from "../../../../assets/images/pickdrop.svg";
import { useRequestRideMutation } from "../../../../redux/api/userApi";
import DateTimeComponent from "./dateAndTime";
import LocationInput from "./LocationInput";
import Map from "./Map";
import { useLocation } from "react-router";

const RideDetails = ({ handleNext, setPayload }) => {
  const location = useLocation();
  const requestId = location.state?.requestId;
  console.log("State", requestId);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [time, setTime] = useState({ hour: "11", minute: "38", period: "AM" });
  const inputRef = useRef(null);

  const [typeOfRide, setTypeOfRide] = useState("");
  const [filteredTypeOfRides, setFilteredTypeOfRides] = useState([]);

  const [showTypesOfRidesAutoComplete, setShowTypesOfRidesAutoComplete] =
    useState(false);

  const [pickupLocation, setPickupLocation] = useState(null);
  const [dropLocation, setDropLocation] = useState(null);

  const [requestRide] = useRequestRideMutation();
  const token = localStorage.getItem("token");

  // Map ride types to display name
  const rideTypesMap = {
    airportNYC: "Airport NY",
    airportONYC: "Airport ONY",
    pointToPoint: "Point to Point",
    hourly: "Hourly",
    groupsAndEvents: "Groups and Events",
    private: "Private",
    cityTour: "City Tour",
  };

  const typeOfRides = Object.keys(rideTypesMap); // Use keys for the dropdown

  // Effect to handle requestId
  useEffect(() => {
    if (requestId && token) {
      setPayload(requestId);
      handleNext();
    }
  }, [requestId]); // Add dependencies

  const handleSetLocation = (type, locationData) => {
    if (type === "pickup") {
      setPickupLocation(locationData);
      console.log("Pickup Details", locationData);
    }
    if (type === "drop") {
      setDropLocation(locationData);
      console.log("Dropoff Details", locationData);
    }
  };

  const handleTypeOfRidesChange = (e) => {
    const value = e.target.value;
    setTypeOfRide(value);
    setFilteredTypeOfRides(
      typeOfRides.filter((ride) =>
        rideTypesMap[ride].toLowerCase().includes(value.toLowerCase())
      )
    );
    setShowTypesOfRidesAutoComplete(true);
  };

  const handleTypesOfRideSelect = (ride) => {
    setTypeOfRide(rideTypesMap[ride]); // Set the display name
    setFilteredTypeOfRides([]); // Clear filtered rides
    setShowTypesOfRidesAutoComplete(false);
  };

  const handleSubmit = async () => {
    const data = {
      pickup: {
        address: pickupLocation.address,
        lon: pickupLocation.lng,
        lat: pickupLocation.lat,
      },
      dropof: {
        address: dropLocation.address,
        lon: dropLocation.lng,
        lat: dropLocation.lat,
      },
      fromDate: startDate,
      toDate: endDate,
      time,
      rideType: Object.keys(rideTypesMap).find(key => rideTypesMap[key] === typeOfRide), // Get the value for the backend
    };
    console.log("Form Data", data);
    try {
      const res = await requestRide({ data, token });
      if (res.data.success) {
        setPayload(res.data.requestId);
        handleNext();
      }
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <div className="flex lg:flex-row flex-col-reverse justify-center lg:space-x-4 space-x-0 w-full h-full">
      <div className="lg:p-4 px-0 py-4 space-y-4 lg:w-1/2 w-full">
        {/* Pickup and Dropoff */}
        <div
          className="w-full flex flex-row rounded-lg bg-[#302721]"
          ref={inputRef}>
          <div className="w-[56px] h-full flex py-8 pl-3 justify-center items-center align-middle">
            <img
              src={pickdrop}
              alt="pickdrop"
              className="w-full h-full max-h-[63px]"
            />
          </div>
          <div className="p-4 w-full rounded-lg bg-[#302721] border-[#302721] text-[#6b564a]">
            <div className="relative ">
              <LocationInput onSetLocation={handleSetLocation} />
            </div>

            <div className="relative"></div>
          </div>
        </div>

        {/* Date & Time Selector */}
        <div>
          <DateTimeComponent
            startDate={startDate}
            setStartDate={setStartDate}
            time={time}
            endDate={endDate}
            setEndDate={setEndDate}
            setTime={setTime}
          />
        </div>

        {/* Ride Type Selector */}
        <div className="relative mb-4">
          <div
            className="bg-[#302721] rounded-lg flex flex-row justify-center items-center py-2 px-4 gap-4"
            onClick={() => setShowTypesOfRidesAutoComplete(true)} // Show dropdown on click
          >
            <div className="border-r-[2px] border-[#6b564a] pr-2">
              <Car className="text-[#6b564a]" />
            </div>
            <Input
              label="Type of Ride"
              placeholder="Type of Ride"
              value={typeOfRide}
              readOnly // Make it read-only to avoid direct editing
              className="border-none focus:ring-0 focus:border-none outline-none pl-2 placeholder:opacity-50  placeholder:text-[#fedac5]  bg-transparent !text-[#fedac5] peer w-full"
              labelProps={{ className: "hidden" }}
            />
            <ArrowDown className="text-[#6b564a] ml-2" />
          </div>

          {showTypesOfRidesAutoComplete && (
            <ul className="absolute bg-[#302721] mt-1 w-full rounded-md shadow-lg z-10 text-[#6b564a]">
              {filteredTypeOfRides.length > 0
                ? filteredTypeOfRides.map((ride, index) => (
                    <li
                      key={index}
                      onClick={() => handleTypesOfRideSelect(ride)}
                      className="p-2 cursor-pointer hover:bg-[#4d3c26]">
                      {rideTypesMap[ride]} {/* Show formatted name */}
                    </li>
                  ))
                : typeOfRides.map((ride, index) => (
                    <li
                      key={index}
                      onClick={() => handleTypesOfRideSelect(ride)}
                      className="p-2 cursor-pointer hover:bg-[#4d3c26]">
                      {rideTypesMap[ride]} {/* Show formatted name */}
                    </li>
                  ))}
            </ul>
          )}
        </div>

        {/* Submit Button */}
        <div className="flex items-center justify-between">
          <Button
            className="w-full py-3 text-center bg-[#ffc978] bg-opacity-60 text-[#251e1a] font-semibold rounded-lg hover:bg-yellow-700 transition"
            onClick={handleSubmit}>
            Continue
          </Button>
        </div>
      </div>

      {/* Map */}
      <div className="lg:p-4 px-0 py-4 space-y-4 lg:w-1/2 w-full flex justify-center items-center lg:h-full h-[50vh] rounded-[12px]">
        <Map pickupLocation={pickupLocation} dropLocation={dropLocation} />
      </div>
    </div>
  );
};

export default RideDetails;

