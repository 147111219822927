import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Map = ({ pickupLocation, dropLocation }) => {
    console.log("pickup");
    
  const mapContainer = useRef(null);
  const map = useRef(null);
  const pickupMarker = useRef(null);
  const dropMarker = useRef(null);

  useEffect(() => {
    if (map.current) return; // If map is already initialized, skip initialization

    // Initialize the map
    map.current = new mapboxgl.Map({
      container: mapContainer.current, // Use the map container ref
      style: "mapbox://styles/mapbox/streets-v11", // Mapbox style
      center: [-98.5833, 39.8283], // Initial center coordinates (US)
      zoom: 4, // Initial zoom level
    });
    console.log("Map initialized");
  }, []);

  useEffect(() => {
    if (pickupLocation) {
      console.log("Pickup Coordinates", pickupLocation);
      if (pickupMarker.current) {
        pickupMarker.current.remove(); // Remove the existing marker if any
      }

      // Create a new marker for the pickup location
      pickupMarker.current = new mapboxgl.Marker({ color: "green" })
        .setLngLat([pickupLocation.lng, pickupLocation.lat])
        .addTo(map.current);

      // Fly the map to the new location
      map.current.flyTo({
        center: [pickupLocation.lng, pickupLocation.lat],
        zoom: 12,
      });
    }
    if (dropLocation) {
      console.log("Drop Location Coordinates:", dropLocation);
      if (dropMarker.current) {
        dropMarker.current.remove();
      }

      dropMarker.current = new mapboxgl.Marker({ color: "red" })
        .setLngLat([dropLocation.lng, dropLocation.lat])
        .addTo(map.current);

      map.current.flyTo({
        center: [dropLocation.lng, dropLocation.lat],
        zoom: 12
      })
    }
  }, [pickupLocation, dropLocation]); // Re-run this effect when pickupLocation changes

  return (
    <div
      ref={mapContainer} // This is the map container div
      className="w-full flex justify-center items-center rounded-[12px] lg:h-[400px] h-full"
    />
  );
};

export default Map;
