import { Button, Typography } from "@material-tailwind/react";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import img1 from "../../../../assets/images/eventsb2i1.svg";
import img2 from "../../../../assets/images/eventsb2i2.svg";
import img3 from "../../../../assets/images/eventsb2i3.svg";

const Events = () => {
  return (
    <div className="min-h-screen w-full lg:px-16 md:px-8 px-4 pb-16">
      {/* Carousel Part */}
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        autoPlay={true}
        interval={2000}
        showStatus={false}
        className="w-full h-full pb-16">
        
        <div>
          <div
            className="flex flex-col justify-evenly h-full bg-cover rounded-[20px] lg:mx-16 md:mx-8 mx-4 lg:p-8 p-4"
            style={{
              backgroundImage: `url("https://i.imgur.com/7e5LT9n.png")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}>
            <div className="flex lg:flex-row flex-col justify-between w-full h-full min-h-[40vh]">
              <div className="flex lg:min-h-[75vh] h-full flex-col w-full">
                <Typography className="lg:text-left text-center text-4xl font-semibold mb-4 font-playfair text-[#40342C]">
                  Special <span className="font-bold text-5xl">Rides</span>
                </Typography>
                <Typography className="lg:text-left text-center text-4xl font-semibold mb-4 font-playfair text-[#40342C]">
                  for Special <span className="font-bold text-5xl">Events</span>
                </Typography>
              </div>
              <div className="flex lg:min-h-[75vh] h-full lg:justify-end justify-center items-center lg:items-start flex-col lg:max-w-[350px] w-full">
                <Typography className="hidden lg:block lg:text-left text-center max-w-[312px] text-xs font-bold mb-1 mr-[30px] font-montserrat text-[#251E1A]">
                  Go through our Extensive list of Premium Features offered in our selective range of Cars
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="flex flex-col justify-evenly h-full bg-cover rounded-[20px] lg:mx-16 md:mx-8 mx-4 lg:p-8 p-4"
            style={{
              backgroundImage: `url("https://i.imgur.com/j2wCWgO.png")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}>
            <div className="flex lg:flex-row flex-col justify-between w-full h-full min-h-[40vh]">
              <div className="flex lg:min-h-[75vh] h-full flex-col w-full">
                <Typography className="lg:text-left text-center text-4xl font-semibold mb-4 font-playfair text-white">
                  Special <span className="font-bold text-5xl">Rides</span>
                </Typography>
                <Typography className="lg:text-left text-center text-4xl font-semibold mb-4 font-playfair text-white">
                  for Special <span className="font-bold text-5xl">Events</span>
                </Typography>
              </div>
              <div className="flex lg:min-h-[75vh] h-full lg:justify-end justify-center items-center lg:items-start flex-col lg:max-w-[350px] w-full">
                <Typography className="hidden lg:block lg:text-left text-center max-w-[312px] text-xs font-bold mb-1 mr-[30px] font-montserrat text-white">
                  Go through our Extensive list of Premium Features offered in our selective range of Cars
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Carousel>

      <div className="w-full h-full mb-16">
        <Typography className="text-center font-playfair font-semibold text-3xl text-primaryLight-50 mb-6">
          Elevate Your Event with Lryde's Premier
          <br />
          Chauffeur Services
        </Typography>
        <div className="w-full h-full flex lg:flex-row flex-col justify-center items-center gap-4">
          <div className="max-w-[324px] max-h-[391px] w-full h-full">
            <img src={img1} alt="img1" className="w-full h-full" />
          </div>
          <div className="max-w-[324px] max-h-[391px] w-full h-full">
            <img src={img2} alt="img2" className="w-full h-full" />
          </div>
          <div className="max-w-[324px] max-h-[391px] w-full h-full">
            <img src={img3} alt="img3" className="w-full h-full" />
          </div>
        </div>
      </div>

      {/* Second Section */}
      <div className="w-full h-full bg-[#37302E] rounded-[20px] flex lg:flex-row flex-col gap-8 p-8 mt-6">
        <div className="w-full flex flex-col justify-between lg:items-left items:center">
          <Typography className="lg:text-left text-center lg:max-w-[487px] max-w-full font-playfair font-semibold text-3xl text-primaryLight-50 mb-6">
            Custom Event Packages,
            <br /> Tailored to Your Needs
          </Typography>
          <Typography className="lg:text-left text-center lg:max-w-[487px] max-w-full font-montserrat lg:font-medium font-normal lg:text-lg text-base text-primaryLight-50 mb-6">
            Every event is unique, and so are your transportation needs. At
            Lryde, we offer customised packages to ensure that our services
            perfectly match your event requirements. Contact us to discuss your
            specific needs and let us create a bespoke transportation plan just
            for you.
          </Typography>
          <div className="flex w-full h-full items-center lg:justify-start justify-center">
            <a href="#footer">
              <Button
                variant="filled"
                className="bg-[#FFC978] !w-[124px] text-black hover:bg-[#ffcc33] shadow-none normal-case rounded-[4px]"
                onClick={() =>
                  (window.location.href = "mailto:Executiveteam@lrydecs.com")
                }>
                Contact Us
              </Button>
            </a>
          </div>
        </div>
        <div className="w-full">
          <img
            src="https://i.imgur.com/9Vwx7kn.png"
            alt="banner3"
            className="w-full lg:max-w-[574px] max-w-full max-h-[383px] h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default Events;
