import { Typography } from "@material-tailwind/react";
import React from "react";
import ScheduledCard from "../../../../common/components/ScheduledCard";
import RIdeDetailsTable from "./RIdeDetailsTable";
import {
  useGetRideDetailsQuery,
  useScheduledRidesQuery,
} from "../../../../redux/api/admin/adminApi";

const RideDetails = () => {
  const token = localStorage.getItem("token");

  const { data: scheduledData } = useScheduledRidesQuery(token);
  const { data: rideDetailsData } = useGetRideDetailsQuery(token);
  const revenueData = [
    {
      name: "Total Rides",
      revenue: rideDetailsData?.total,
    },
    {
      name: "Ride Requests Today",
      revenue: rideDetailsData?.rideRequest,
    },
    {
      name: "Rides Scheduled",
      revenue: scheduledData?.bookedRides?.length,
    },
  ];

  const scheduledRides = scheduledData?.bookedRides?.map((ride) => ({
    carType: ride?.request?.vehicle?.rideType,
    ride: ride?.request?.rideType,
    pickUp: ride?.request?.pickup?.address,
    dropOff: ride?.request?.dropof?.address,
    date: ride?.request?.fromDate?.split("T")[0],
    fare: ride?.request?.price,
    carImg: ride?.request?.vehicle?.carImg,
  }));

  return (
    <div className="min-h-screen py-16 px-12">
      <Typography className="text-left text-5xl mb-12 font-bold font-playfair text-primaryLight-50">
        Ride Details
      </Typography>

      <div className="flex w-full gap-6 mb-6">
        <div className="flex flex-col items-start w-1/3">
          <div className="flex w-full flex-col justify-between gap-6">
            {revenueData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col items-center rounded-[20px] border-[1px] py-4 justify-between border-primaryLight-300 bg-primaryLight-600 w-full bg-opacity-[34%]">
                  <Typography className="text-left text-2xl mb-6 font-bold font-playfair text-primaryLight-200">
                    {item?.name}
                  </Typography>
                  <div className="border-b-[1px] border-primaryLight-300 flex w-[90%] mb-4"></div>
                  <Typography className="text-left text-6xl mb-6 font-normal font-playfair text-primaryLight-200">
                    {item?.revenue}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col items-center rounded-[20px] border-[1px] p-6 border-primaryLight-300 w-2/3">
          <div className="w-full flex items-start">
            <Typography className="text-left text-2xl mb-2 font-bold font-playfair text-primaryLight-200">
              Scheduled Rides
            </Typography>
          </div>
          <div
            className="w-full h-full overflow-y-auto max-h-[500px] [&::-webkit-scrollbar]:w-1.5 [&::-webkit-scrollbar-track]:bg-[#F3F3F3] [&::-webkit-scrollbar-thumb]:rounded-full
                                         [&::-webkit-scrollbar-thumb]:bg-textLight-300 dark:[&::-webkit-scrollbar-track]:bg-[#212121] dark:[&::-webkit-scrollbar-thumb]:bg-[#F3F3F3]">
            {scheduledRides?.map((items, index) => {
              return (
                <div
                  key={index}
                  className="border-t-[1px] border-primaryLight-300 flex w-[90%] mt-4">
                  <ScheduledCard items={items} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="mb-8">
        <RIdeDetailsTable />
      </div>
    </div>
  );
};

export default RideDetails;
