import { Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import bookingImage from "../../../../assets/images/bookingbanner.svg";
import ChooseFleet from "./ChooseFleet";
import PaymentCheckout from "./PaymentCheckout";
import PersonalDetails from "./PersonalDetails";
import RideDetails from "./RideDetails";

const BookingRide = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [payload, setPayload] = useState(null);
  const [data, setData] = useState(null);
  const [client_secret, setClient_Secret] = useState(null);

  const steps = [
    { label: "Ride Details" },
    { label: "Choose Fleet" },
    { label: "Personal Details" },
    { label: "Payment" },
  ];

  const handleNext = () => {
    setActiveStep((cur) => (cur < 3 ? cur + 1 : cur));
  };

  const handlePrev = () => {
    setActiveStep((cur) => (cur > 0 ? cur - 1 : cur));
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <RideDetails handleNext={handleNext} setPayload={setPayload} />;
      case 1:
        return (
          <ChooseFleet
            handleNext={handleNext}
            payload={payload}
            setData={setData}
          />
        );
      case 2:
        return (
          <PersonalDetails
            handleNext={handleNext}
            payload={payload}
            data={data}
            setClient_Secret={setClient_Secret}
          />
        );
      case 3:
        return (
          <PaymentCheckout
            handleNext={handleNext}
            setActiveStep={setActiveStep}
            payload={payload}
            client_secret={client_secret}
          />
        );
      default:
        return <RideDetails handleNext={handleNext} />;
    }
  };

  return (
    <div className="min-h-screen lg:mx-16 md:mx-12 mx-4 flex flex-col items-center">
      {/* Banner Section */}
      <div
        className="flex flex-col items-center justify-center h-[193px] rounded-3xl w-full bg-cover p-8"
        style={{
          backgroundImage: `url(${bookingImage})`,
          backgroundRepeat: "no-repeat",
        }}>
        <Typography className="text-5xl font-[800] font-playfair text-primaryLight-50 shadow-xl mb-1">
          BookingRide
        </Typography>
        <Typography className="text-base font-medium font-montserrat text-primaryLight-50 shadow-xl">
          Select your favourite fleet and ride in style
        </Typography>
      </div>

      {/* Stepper Section */}
      <div className="w-full mt-10 px-4 py-6 bg-[#302721] rounded-xl">
        <div className="flex justify-between items-center relative">
          {steps.map((step, index) => (
            <div key={index} className="relative flex flex-col items-center">
              <div
                className={`w-10 h-10 flex items-center justify-center rounded-full  ${
                  activeStep >= index
                    ? "bg-[#ffc978] text-[#6B564A]"
                    : "bg-[#B59278] text-[#D1CBC7]"
                }`}
                onClick={() => setActiveStep(index)}>
                {index + 1}
              </div>
              <Typography
                className={`mt-2 text-sm ${
                  activeStep >= index ? "text-[#ffc978]" : "text-[#B59278]"
                }`}>
                {step.label}
              </Typography>

              {index !== steps.length - 1 && (
                <div
                  className={`absolute top-5 left-10 lg:w-[28vw] w-full sm:w-[20vw] md:w-[24vw] border-t-4 border-dotted ${
                    activeStep >= index
                      ? "border-[#ffc978]"
                      : "border-[#B59278]"
                  }`}></div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Content Section */}
      <div className="w-full h-full lg:p-10 py-10">{renderStepContent(activeStep)}</div>

      {/* Navigation Buttons */}
      {/* <div className="mt-10 flex justify-between w-full">
        <Button onClick={handlePrev} disabled={activeStep === 0}>
          Prev
        </Button>
        <Button onClick={handleNext} disabled={activeStep === steps.length - 1}>
          Next
        </Button>
      </div> */}
    </div>
  );
};

export default BookingRide;
