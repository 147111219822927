import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const urls = [
  "https://lcs-reboot-go97.onrender.com/api/users",
  "http://localhost:6969/api/users",
];
// const urls = "http://localhost:6969/api/auth"
export const userAPI = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: urls[0],
  }),
  endpoints: (builder) => ({
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: "/forgot-password",
        method: "POST",
        body: email,
      }),
    }),
    verifyOTP: builder.mutation({
      query: (data) => ({
        url: "/verify-otp",
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: "/reset-password",
        method: "POST",
        body: data,
      }),
    }),
    requestRide: builder.mutation({
      query: ({ data, token }) => ({
        url: "/request",
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    myProfile: builder.query({
      query: (token) => ({
        url: "/my-profile",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    addPhone: builder.mutation({
      query: ({ token, body }) => ({
        url: "/add-phone",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getRequest: builder.query({
      query: ({ payload, token }) => ({
        url: `/request?requestId=${payload}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getUserRequest: builder.query({
      query: (token) => ({
        url: `/user-request`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    selectVehicle: builder.mutation({
      query: ({ body, token }) => ({
        url: "/select-vehicle",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    addMessage: builder.mutation({
      query: ({ body, token }) => ({
        url: "/add-message",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const {
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyOTPMutation,
  useMyProfileQuery,
  useRequestRideMutation,
  useGetRequestQuery,
  useSelectVehicleMutation,
  useGetUserRequestQuery,
  useAddMessageMutation,
  useAddPhoneMutation,
} = userAPI;
