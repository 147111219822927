import React from "react";
import Car from "../../assets/back-body-car 1.png";

const NotFoundPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#533520] to-[#3d2317] flex flex-col items-center justify-center relative overflow-hidden">
      {/* 404 Text */}
      {/* <h1 className="text-[300px] font-bold text-transparent bg-clip-text bg-gradient-to-t from-[#a96c41] to-[#d89a72] tracking-tight absolute top-52 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-0">
        404
      </h1> */}

      {/* Car Image */}
      <div className="relative z-10">
        <img
          src={Car} // Car image
          alt="Car without wheels"
          className="w-[777px] h-[504.8px] mb-8"
        />
      </div>

      {/* Text */}
      <div className="relative z-10 bottom-20 text-center">
        <p className="text-white text-lg mb-2">
          Looks like the wheels have come off
        </p>
        <p className="text-white font-semibold text-xl mb-6">
          Let’s get you back on track!
        </p>
      </div>

      {/* Back to home button */}
      <button
        className="bg-[#FFC978] text-black font-semibold py-2 px-4 rounded-md hover:bg-yellow-600 transition duration-300 relative z-10 bottom-20"
        onClick={() => (window.location.href = "/")}>
        Back to home
      </button>
    </div>
  );
};

export default NotFoundPage;
