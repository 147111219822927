import React, { useState } from "react";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { useMakePaymentMutation } from "../../../../redux/api/paymentApi";
import ConfirmationModal from "../../../../common/components/ConfirmationModal/ConfirmationModal";
const stripePromise = loadStripe(
  "pk_live_51NQtt9IcSKVEBp6AnZpxFgJJV0XnXQgL88M4ALm19TwTQ36SBhFkOsViIjeFRLcunFG0RVEy4E71EWHqr8Gcfwf800DgqztOqe"
);

const CheckOutForm = ({ requestId }) => {
  const [showModal, setShowModal] = useState(false);
  const token = localStorage.getItem("token");
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProccessing] = useState(false);
  const [makePayment] = useMakePaymentMutation();
  const navigate = useNavigate();
  const submitHandler = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;
    setIsProccessing(true);

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: window.location.origin },
      redirect: "if_required",
    });

    if (error) {
      setIsProccessing(false);
      return console.log("Error ", error);
    }
    if (paymentIntent.status === "succeeded") {
      console.log("Booking your ride");
      const { data } = await makePayment({ body: { requestId }, token });
      if (data.success) {
        setShowModal(true);
      }
    }
    setIsProccessing(false);
  };
  return (
    <div>
      <form onSubmit={submitHandler}>
        <PaymentElement className="text-white" />

        <button
          className="btn w-full bg-brown-800 mt-4 h-[50px] rounded-md"
          type="submit"
          disabled={isProcessing}>
          {isProcessing ? "Processing" : "Pay"}
        </button>
      </form>
      {showModal && <ConfirmationModal setShowModal={setShowModal} />}
    </div>
  );
};

const PaymentCheckout = ({
  handleNext,
  setActiveStep,
  client_secret,
  payload,
}) => {
  console.log("Client", client_secret);
  console.log("Id", payload);

  return (
    <div>
      <Elements
        options={{
          clientSecret: client_secret,
        }}
        stripe={stripePromise}>
        <CheckOutForm requestId={payload} />
      </Elements>
    </div>
  );
};

export default PaymentCheckout;
