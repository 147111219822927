import React from "react";
import Banner from "./Banner";
import OurServices from "./OurServices";
import img3 from "../../../../assets/images/clientbanner3.svg";
import CurrentlyOperationalSwipper from "./CurrentlyOperationalSwipper";
import TypesOfVehicles from "./TypesOfVehicles";
import Faqs from "./Faqs";
import chauffer from "../../../../assets/images/vipchauffer.svg";
import { Button, Typography } from "@material-tailwind/react";
import { Calendar2 } from "iconsax-react";

const ClientLandingPage = () => {
  return (
    <div className="min-h-screen w-full h-full">
      <div className="w-full h-full">
        <Banner />
      </div>
      <div className="w-full h-full">
        <OurServices />
      </div>
      <div className="hidden lg:block w-full h-full">
        <img
          src={img3}
          alt="banner-3"
          className="w-full min-h-[180px] h-full lg:px-16 md:px-8 px-4 pb-16"
        />
      </div>
      <div className="lg:hidden block w-full h-full lg:px-16 md:px-8 px-4 pb-16">
        <img
          src="https://i.imgur.com/0H5ySTx.png"
          alt="banner-3"
          className="w-full min-h-[180px] max-h-[75vh] h-full object-contain bg-[#37302e] py-8 rounded-[32px]"
        />
      </div>
      <div className="w-full h-full">
        <CurrentlyOperationalSwipper />
      </div>
      <div className="w-full h-full" id="targetSection">
        <TypesOfVehicles />
      </div>
      <div className="w-full h-full">
        <Faqs />
      </div>
      <div className="w-full h-full">
        {/* <img
          src={chauffer}
          alt="chauffer"
          className="w-full min-h-[180px] h-full lg:px-16 md:px-8 px-4 pb-16"
        /> */}
        <div
          className="relative flex flex-col h-full bg-cover rounded-[20px] lg:mx-16 md:mx-8 mx-4 lg:p-8 p-4 mb-16"
          style={{
            backgroundImage: `url("https://i.imgur.com/gmeOE4Q.png")`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="flex flex-col lg:flex-row lg:justify-start justify-center w-full h-full">
            <div className="lg:w-1/2 w-full flex flex-col lg:justify-start justify-center items-center lg:items-start">
              <Typography className="lg:text-left text-center text-5xl font-semibold font-playfair text-primaryLight-50">
                Enjoy{" "}
                <span className="font-bold text-5xl text-[#ffc978] mb-4">
                  VIP Chauffeur
                </span>
              </Typography>
              <Typography className="lg:text-left text-center text-5xl font-semibold font-playfair text-primaryLight-50 mb-4">
                Services
              </Typography>

              <Typography className="lg:text-left text-center lg:max-w-[312px] max-w-[85%] text-xs font-normal mb-4 font-montserrat text-primaryLight-50">
                Indulge in unparalleled luxury with our VIP chauffeur services.
                From the moment you step into our premium vehicles, you'll
                experience the ultimate in comfort and sophistication.
              </Typography>

              <Button
                variant="filled"
                className="bg-[#FFC978] flex flex-row gap-2 justify-center text-sm font-montserrat font-medium items-center text-black hover:bg-[#ffcc33] shadow-none normal-case rounded-[4px] mb-4"
              >
                Reserve Now <Calendar2 size="18" />
              </Button>
            </div>
            <div className="hidden lg:block absolute -top-[5vh] -right-[3vw] w-1/2">
<img src="https://i.imgur.com/YOmpTA8.png" alt="car" />
            </div>
            <div className="flex lg:hidden w-full">
<img src="https://i.imgur.com/YOmpTA8.png" alt="car" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientLandingPage;
