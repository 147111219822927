export const checkTokenExpiry = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("/-/g", "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const { exp } = JSON.parse(jsonPayload);

    if (exp * 1000 < Date.now()) {
      localStorage.removeItem("token");
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error decoding token: ", error);
    localStorage.removeItem("token");
    return true;
  }
};
