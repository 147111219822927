import { Button, Drawer, Typography } from "@material-tailwind/react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { ArrowDown, Sms } from "iconsax-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { MdKey, MdPerson, MdPhone } from "react-icons/md";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import googleImg from "../../../assets/images/googleImg.svg";
import { auth } from "../../../firebase";
import {
  useLoginWithGoogleMutation,
  useSignupMutation,
} from "../../../redux/api/authApi";
import "./style.css";

const SignUp = () => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit } = useForm();
  const [signup] = useSignupMutation();
  const [loginWithGoogle] = useLoginWithGoogleMutation();
  // Function to toggle the drawer
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await signup({
        name: data.name,
        email: data.email,
        password: data.password,
        role: "user",
        confirmPass: data.confirmPass,
        phone: data.phone,
      });
      console.log("res", res);

      if (res.data.success) {
        setLoading(false);
        navigate("/otp", { state: { email: data.email } });
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const googleSignUp = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const { user } = await signInWithPopup(auth, provider);
      const { data } = await loginWithGoogle({
        _id: user?.uid,
        name: user?.displayName,
        email: user?.email,
        phone: user?.phoneNumber,
        role: "user",
      });

      if (data.success) {
        console.log(data.token);
        localStorage.setItem("token", data.token);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center w-full bg-cover"
      style={{
        backgroundImage: `url("https://i.imgur.com/4GCorK6.png")`,
        backgroundRepeat: "no-repeat",
        height: `calc(100vh - 5rem)`, // Corrected this line
      }}>
      <Typography className="text-left text-5xl mb-4 font-bold font-playfair text-primaryLight-50">
        Welcome to LCS !
      </Typography>
      <Typography className="text-left text-lg font-semibold mb-20 font-montserrat text-primaryLight-50">
        Login to your account to book your favourite fleet
      </Typography>
      <div className="flex flex-row w-full justify-center py-3">
        <Button
          variant="text"
          className="rounded-full py-6 bg-white bg-opacity-10 backdrop-blur-lg backdrop-filter"
          onClick={toggleDrawer}>
          <div
            id="scroll-down-arrow"
            className="flex flex-col w-full justify-center align-middle">
            <ArrowDown size="24" className="text-primaryLight-50" />
          </div>
        </Button>
      </div>

      {/* Drawer for the login form */}
      <Drawer
        open={isDrawerOpen}
        onClose={toggleDrawer}
        placement="bottom"
        className="h-[70%] flex justify-center items-center bg-black bg-opacity-90">
        {isDrawerOpen && (
          <div className="w-full max-w-md px-6 pt-2 pb-20 bg-black  rounded-t-xl bg-opacity-80 text-primaryLight-50">
            <Typography className="text-xl font-semibold w-full text-center mb-6 font-montserrat text-[#FFC978]">
              Signup
            </Typography>
            <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="relative">
                <div className="bg-[#302721] rounded-lg flex flex-row justify-center items-center px-4 gap-4 mb-6">
                  <div className="border-r-[2px] border-[#6b564a] pr-2">
                    <MdPerson className="text-[#6b564a]" />
                  </div>
                  <input
                    type="text"
                    placeholder="Enter Full Name"
                    className="w-full p-4 bg-primaryLight-800 text-[#6b564a] rounded-md focus:ring-2 focus:ring-primary-500 outline-none"
                    {...register("name")}
                  />
                </div>
              </div>
              <div className="relative">
                <div className="bg-[#302721] rounded-lg flex flex-row justify-center items-center px-4 gap-4 mb-6">
                  <div className="border-r-[2px] border-[#6b564a] pr-2">
                    <Sms className="text-[#6b564a]" />
                  </div>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    className="w-full p-4 bg-primaryLight-800 text-[#6b564a] rounded-md focus:ring-2 focus:ring-primary-500 outline-none"
                    {...register("email")}
                  />
                </div>
              </div>
              <div className="relative">
                <div className="bg-[#302721] rounded-lg flex flex-row justify-center items-center px-4 gap-4 mb-6">
                  <div className="border-r-[2px] border-[#6b564a] pr-2">
                    <MdKey className="text-[#6b564a] text-2xl" />
                  </div>
                  <input
                    type="password"
                    placeholder="Enter Password"
                    className="w-full p-4 bg-primaryLight-800 text-[#6b564a] rounded-md focus:ring-2 focus:ring-primary-500 outline-none"
                    {...register("password")}
                  />
                </div>
              </div>
              <div className="relative">
                <div className="bg-[#302721] rounded-lg flex flex-row justify-center items-center px-4 gap-4 mb-6">
                  <div className="border-r-[2px] border-[#6b564a] pr-2">
                    <MdKey className="text-[#6b564a] text-2xl" />
                  </div>
                  <input
                    type="password"
                    placeholder="Re-Enter Password"
                    className="w-full p-4 bg-primaryLight-800 text-[#6b564a] rounded-md focus:ring-2 focus:ring-primary-500 outline-none"
                    {...register("confirmPass")}
                  />
                </div>
              </div>
              <div className="relative">
                <div className="bg-[#302721] rounded-lg flex flex-row justify-center items-center px-4 gap-4 mb-6">
                  <div className="border-r-[2px] border-[#6b564a] pr-2">
                    <MdPhone className="text-[#6b564a] text-2xl" />
                  </div>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    className="w-full p-4 bg-primaryLight-800 text-[#6b564a] rounded-md focus:ring-2 focus:ring-primary-500 outline-none"
                    {...register("phone")}
                  />
                </div>
              </div>
              <div className="text-right pb-2">
                <Link
                  to="/login"
                  className="text-[#6B564A] text-xs pb-1 border-b-2 border-[#6B564A] cursor-pointer">
                  Already Have An Account ?
                </Link>
              </div>
              <Button
                className="w-full py-3 text-center bg-[#ffc978] bg-opacity-60 text-[#251e1a] font-semibold rounded-lg hover:bg-yellow-700 transition"
                type="submit" disabled={loading}>
                {loading ? "Signing Up..." : "Continue"}
              </Button>
              <div className="w-full flex justify-center items-center py-2">
                <div className="border-b-2 w-full border-[#6B564A]"></div>
                <Typography className="text-center text-[#6B564A] w-full text-sm">
                  or continue with
                </Typography>
                <div className="border-b-2 w-full border-[#6B564A]"></div>
              </div>
              <div className="w-full flex justify-center items-center py-2 pb-80">
                <img
                  src={googleImg}
                  alt="googleicon"
                  className="w-[51px] h-[51px] cursor-pointer"
                  onClick={googleSignUp}
                />
              </div>
            </form>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default SignUp;
