import React from "react";
import footerLogo from "../../assets/images/footerlogo.svg";
import { Button } from "@material-tailwind/react";

const Footer = () => {
  return (
    <div className="bg-transparent  lg:px-16 md:px-12 px-4 pb-8" id="footer">
      <div className="bg-[#D1CBC7] rounded-[20px] p-10 flex flex-col md:flex-row justify-between items-center md:items-start">
        {/* Left Section: Logo and Address */}
        <div className="flex flex-col items-center md:items-start">
          <div className="max-w-[203px] max-h-[96px]">
            <img src={footerLogo} alt="footer logo" className="w-full h-full" />
          </div>

          {/* Form Section */}
          <form className="mt-4 w-full flex flex-col space-y-2">
            <div className="flex flex-col md:flex-row md:space-x-2">
              <input
                type="text"
                placeholder="Enter your name"
                className="w-full px-4 py-2 rounded-md focus:outline-none text-black"
              />
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full px-4 py-2 mt-2 md:mt-0 rounded-md focus:outline-none text-black"
              />
            </div>
            <div className="flex flex-col md:flex-row md:space-x-2">
              <input
                type="text"
                placeholder="Enter your message"
                className="w-full px-4 py-2 rounded-md focus:outline-none text-black"
              />
              <Button
                className="bg-[#40342C] px-4 py-2 mt-2 md:mt-0 rounded-[4px] normal-case text-xs w-full md:w-auto"
              >
                Get started
              </Button>
            </div>
          </form>

          <address className="mt-4 text-sm text-center text-[#504138] font-montserrat md:text-left">
            123 Park Avenue, Suite 456, New York, NY 10022
          </address>
        </div>

        {/* Middle Section: Links */}
        <div className="flex justify-around md:justify-start space-x-12">
          <div className="text-center md:text-left">
            <h3 className="font-semibold font-playfair text-[#504138] text-lg mb-2">
              Cities
            </h3>
            <ul className="space-y-1 font-montserrat text-xs text-[#504138]">
              <li className="mb-2 cursor-pointer">New York</li>
              <li className="mb-2 cursor-pointer">Hamptons</li>
              <li className="mb-2 cursor-pointer">Boston</li>
              <li className="mb-2 cursor-pointer">Chicago</li>
              <li className="mb-2 cursor-pointer">San Francisco</li>
            </ul>
          </div>
          <div className="text-center md:text-left">
            <h3 className="font-semibold font-playfair text-[#504138] text-lg mb-2">
              Company
            </h3>
            <ul className="space-y-1 font-montserrat text-xs text-[#504138]">
              <li className="mb-2 cursor-pointer">Privacy Policy</li>
              <li className="mb-2 cursor-pointer">Terms & Conditions</li>
              <li className="mb-2 cursor-pointer">Legal Notice</li>
              <li className="mb-2 cursor-pointer">Accessibility</li>
            </ul>
          </div>
          <div className="text-center md:text-left">
            <h3 className="font-semibold font-playfair text-[#504138] text-lg mb-2">
              Services
            </h3>
            <ul className="space-y-1 font-montserrat text-xs text-[#504138]">
              <li className="mb-2 cursor-pointer">Airport Transfer</li>
              <li className="mb-2 cursor-pointer">Hourly Charter Transfer</li>
              <li className="mb-2 cursor-pointer">Groups and Events Transfer</li>
              <li className="mb-2 cursor-pointer">Private Transfer</li>
              <li className="mb-2 cursor-pointer">City Tours Transfer</li>
              <li className="mb-2 cursor-pointer">Point to Point Transfer</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="text-center text-[#E9E6E4] text-sm mt-8">
        All Rights Reserved Lryde Chauffeur Services 2024
      </div>
    </div>
  );
};

export default Footer;
