import { Button, Drawer, Typography } from "@material-tailwind/react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { ArrowDown, Sms } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdKey } from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import googleImg from "../../../assets/images/googleImg.svg";
import { auth } from "../../../firebase";
import {
  useLoginMutation,
  useLoginWithGoogleMutation,
} from "../../../redux/api/authApi";
import "./style.css";
import { useRequestRideMutation } from "../../../redux/api/userApi";

const Login = () => {
  const { register, handleSubmit } = useForm();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [login] = useLoginMutation();
  const [loginWithGoogle] = useLoginWithGoogleMutation();
  const [requestRide] = useRequestRideMutation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDrawerOpen(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  // Function to toggle the drawer
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const fetchCoordinates = async (address) => {
    const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN; // Replace with your Mapbox token
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${mapboxToken}`
    );

    if (!response.ok) {
      throw new Error("Error fetching coordinates");
    }

    const data = await response.json();
    // Return the first result's coordinates
    return data.features.length > 0
      ? data.features[0].geometry.coordinates
      : null;
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await login({
        email: data.email,
        password: data.password,
      });
      if (res.error) {
        console.log(res.error);

        setError(res.error.data.message);
        setLoading(false);
      }

      if (res.data.success) {
        localStorage.setItem("token", res.data.token);
        setLoading(false);

        let check = false;
        const pickupAddress = localStorage.getItem("pickupAddress");
        const dropoffAddress = localStorage.getItem("dropoffAddress");
        const pickupDate = localStorage.getItem("date");
        const hour = localStorage.getItem("hour");
        const minute = localStorage.getItem("minute");
        const period = localStorage.getItem("period");
        const time = {
          hour: hour,
          minute: minute,
          period: period,
        };
        const rideType = localStorage.getItem("selectedRide");

        if (pickupAddress && dropoffAddress && rideType) {
          check = true;
        }

        if (check) {
          try {
            const pickupCoords = await fetchCoordinates(pickupAddress);
            const dropoffCoords = await fetchCoordinates(dropoffAddress);
            const data = {
              pickup: {
                address: pickupAddress,
                lon: pickupCoords ? pickupCoords[0] : null,
                lat: pickupCoords ? pickupCoords[1] : null,
              },
              dropof: {
                address: dropoffAddress,
                lon: dropoffCoords ? dropoffCoords[0] : null,
                lat: dropoffCoords ? dropoffCoords[1] : null,
              },
              fromDate: pickupDate,
              time: time,
              rideType,
            };
            const token = localStorage.getItem("token");
            const res = await requestRide({ data, token });
            if (res.data.success) {
              localStorage.removeItem("pickupAddress");
              localStorage.removeItem("dropoffAddress");
              localStorage.removeItem("date");
              localStorage.removeItem("hour");
              localStorage.removeItem("minute");
              localStorage.removeItem("period");
              navigate("/ride-booking", {
                state: {
                  requestId: res.data.requestId,
                },
              });
            }
          } catch (error) {
            console.log(error);
            setLoading(false);
          }
        } else {
          navigate(from);
          setLoading(false);
        }
      } else {
        console.log("Error");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const googleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const { user } = await signInWithPopup(auth, provider);
      const { data } = await loginWithGoogle({
        _id: user?.uid,
        name: user?.displayName,
        email: user?.email,
        phone: user?.phoneNumber,
        role: "user",
      });
      if (data.success) {
        localStorage.setItem("token", data.token);

        let check = false;
        const pickupAddress = localStorage.getItem("pickupAddress");
        const dropoffAddress = localStorage.getItem("dropoffAddress");
        const pickupDate = localStorage.getItem("date");
        const hour = localStorage.getItem("hour");
        const minute = localStorage.getItem("minute");
        const period = localStorage.getItem("period");
        const time = {
          hour: hour,
          minute: minute,
          period: period,
        };
        const rideType = localStorage.getItem("selectedRide");

        if (pickupAddress && dropoffAddress && rideType) {
          check = true;
        }
        if (check) {
          try {
            const pickupCoords = await fetchCoordinates(pickupAddress);
            const dropoffCoords = await fetchCoordinates(dropoffAddress);
            const data = {
              pickup: {
                address: pickupAddress,
                lon: pickupCoords ? pickupCoords[0] : null,
                lat: pickupCoords ? pickupCoords[1] : null,
              },
              dropof: {
                address: dropoffAddress,
                lon: dropoffCoords ? dropoffCoords[0] : null,
                lat: dropoffCoords ? dropoffCoords[1] : null,
              },
              fromDate: pickupDate,
              time: time,
              rideType,
            };
            const token = localStorage.getItem("token");
            const res = await requestRide({ data, token });
            if (res.data.success) {
              localStorage.removeItem("pickupAddress");
              localStorage.removeItem("dropoffAddress");
              localStorage.removeItem("date");
              localStorage.removeItem("hour");
              localStorage.removeItem("minute");
              localStorage.removeItem("period");
              navigate("/ride-booking", {
                state: {
                  requestId: res.data.requestId,
                },
              });
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          navigate(from);
        }
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="flex h-screen flex-col items-center justify-center w-full bg-cover"
      style={{
        backgroundImage: `url("https://i.imgur.com/4GCorK6.png")`,
        backgroundRepeat: "no-repeat",
        // height: `calc(100vh - 5rem)`, // Corrected this line
      }}>
      <Typography className="text-left text-5xl mb-4 font-bold font-playfair text-primaryLight-50">
        Welcome Back !
      </Typography>
      <Typography className="text-left text-lg font-semibold mb-20 font-montserrat text-primaryLight-50">
        Login to your account to book your favourite fleet
      </Typography>
      <div className="flex flex-row w-full justify-center py-3">
        <Button
          variant="text"
          className="rounded-full py-6 bg-white bg-opacity-10 backdrop-blur-lg backdrop-filter"
          onClick={toggleDrawer}>
          <div
            id="scroll-down-arrow"
            className="flex flex-col w-full justify-center align-middle">
            <ArrowDown size="24" className="text-primaryLight-50" />
          </div>
        </Button>
      </div>

      {/* Drawer for the login form */}
      <Drawer
        open={isDrawerOpen}
        onClose={toggleDrawer}
        placement="bottom"
        className="h-[70%] flex justify-center items-center bg-black bg-opacity-90">
        {isDrawerOpen && (
          <div className="w-full max-w-md px-6 pt-2 pb-20 bg-black  rounded-t-xl bg-opacity-80 text-primaryLight-50">
            <Typography className="text-xl font-semibold w-full text-center mb-6 font-montserrat text-[#FFC978]">
              Login
            </Typography>
            <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="relative">
                <div className="bg-[#302721] rounded-lg flex flex-row justify-center items-center px-4 gap-4 mb-6">
                  <div className="border-r-[2px] border-[#6b564a] pr-2">
                    <Sms className="text-[#6b564a]" />
                  </div>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    className="w-full p-4 bg-primaryLight-800 text-[#6b564a] rounded-md focus:ring-2 focus:ring-primary-500 outline-none"
                    {...register("email")}
                  />
                </div>
              </div>
              <div className="relative">
                <div className="bg-[#302721] rounded-lg flex flex-row justify-center items-center px-4 gap-4 mb-6">
                  <div className="border-r-[2px] border-[#6b564a] pr-2">
                    <MdKey className="text-[#6b564a] text-2xl" />
                  </div>
                  <input
                    type="password"
                    placeholder="Enter Password"
                    className="w-full p-4 bg-primaryLight-800 text-[#6b564a] rounded-md focus:ring-2 focus:ring-primary-500 outline-none"
                    {...register("password")}
                  />
                </div>
              </div>
              <p className="text-red-500 font-bold text-sm mt-2 mb-2">{error}</p>
              <div className="flex flex-row justify-between w-full pb-2">
                <div>
                  <Link
                    to="/register"
                    // href="/register"
                    className="text-[#6B564A] text-xs pb-1 border-b-2 border-[#6B564A] cursor-pointer"
                    // onClick={() => navigate("/register")}
                  >
                    Create An Account
                  </Link>
                </div>
                <div>
                  <Link
                    // href="/forgot-password"
                    to="/forgot-password"
                    className="text-[#6B564A] text-xs pb-1 border-b-2 border-[#6B564A] cursor-pointer">
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <Button
                type="submit"
                className="w-full py-3 text-center bg-[#ffc978] bg-opacity-60 text-[#251e1a] font-semibold rounded-lg hover:bg-yellow-700 transition"
                disabled={loading}>
                {loading ? "Logging...." : "Continue"}
              </Button>
              <div className="w-full flex justify-center items-center py-2">
                <div className="border-b-2 w-full border-[#6B564A]"></div>
                <Typography className="text-center text-[#6B564A] w-full text-sm">
                  or continue with
                </Typography>
                <div className="border-b-2 w-full border-[#6B564A]"></div>
              </div>
              <div className="w-full flex justify-center items-center py-2 pb-80">
                <img
                  src={googleImg}
                  alt="googleicon"
                  className="w-[51px] h-[51px] cursor-pointer"
                  onClick={googleLogin}
                />
              </div>
            </form>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Login;
